function processTable(curnotes, curtbl, ccc){
	if(!curnotes)curnotes=[];
	let captionnotes = {};
	for(let i=0;i<curnotes.length;i++){
		let curnote = curnotes[i];
		if(!curnote.filterATCCode&&!curnote.filterCountSKD&&!curnote.filterFreq&&!curnote.filterName&&!curnote.filterPage&&!curnote.filterSDD&&!curnote.filterUnit){
			// заголовок
			captionnotes[curnote.columnName]=curnote;
		}
	}
	let tbl_caption=[];
	for(let i=0;i<ccc.length;i++){
    let n = captionnotes[ccc[i]];
    if(n)
      n.num = curNum++;
		tbl_caption.push({value:ccc[i],note:n});
	}
	let tbl=[];
	for(let i=0;i<curtbl.length;i++){
		let currow = curtbl[i];
		let rownotes = {};
		for(let j=0;j<curnotes.length;j++){
			let curnote = curnotes[j];
			if(!curnote.filterATCCode&&!curnote.filterCountSKD&&!curnote.filterFreq&&!curnote.filterName&&!curnote.filterPage&&!curnote.filterSDD&&!curnote.filterUnit){
				// заголовок
			}else{
				if( (!curnote.filterATCCode || curnote.filterATCCode == currow.atcName || curnote.filterATCCode == currow.atcGroupCode || curnote.filterATCCode == currow.code) &&
					(!curnote.filterFreq || curnote.filterFreq == currow.midRate || curnote.filterFreq == currow.medicineMidRate) &&
					(!curnote.filterSDD || curnote.filterSDD == currow.sdd) &&
					(!curnote.filterCountSKD || curnote.filterCountSKD == currow.skd || curnote.filterCountSKD == currow.midCount || curnote.filterCountSKD == currow.midMult) &&
					(!curnote.filterName || curnote.filterName == currow.name) &&
					(!curnote.filterPage || curnote.filterPage == currow.pageId) &&
					(!curnote.filterUnit || curnote.filterUnit == currow.unit)){
					// прошли проверку
					rownotes[curnote.columnName]=curnote;
          curnote.num = curNum++;
				}
			}
		}
		let tbl_row=[];
		for(let i=0;i<ccc.length;i++){
			tbl_row.push({value:currow[ccc[i]],note:rownotes[ccc[i]]});
		}
		tbl.push(tbl_row);
	}
	return {caption:tbl_caption, data:tbl};
}
var curNum = 0;

function convert(std){
  curNum = 1;
	let s = {};
	let n={};
	if(std.notes){
		for(let nn in std.notes){
      if(std.notes[nn].typeCode == 2)
        std.notes[nn].section = "0";
			if(!n[std.notes[nn].section])n[std.notes[nn].section]=[];
			n[std.notes[nn].section].push(std.notes[nn]);
		}
	}
	s.notes=n[0];
  if(!s.notes)
    s.notes=n[""];
  if(s.notes)
  for (let i = 0; i < s.notes.length; i++) {
    const element = s.notes[i];
    element.num = i+1;
  }
  else s.notes=[];

	let notespassport = n['passportData']||[];
	let passport = std['passportData'];
	let passport2={};
  //passport = OrderPassport(passport);
  let pord = ["stageName","stdId","name","ageGroup","gender","medicalCareTypeName","medicalCareConditionName","madicalCareFormName","diseasePhase","disease","complication","midTreatmentDuratation","diagCode","statusName","orderNumber","orderDate","clinicalRecommendationName","clinId","СlinrecApprovementYear"];
	for(let i in passport){
		let p={value:passport[i]};
		for(let j=0;j<notespassport.length;j++){
			if(notespassport[j].columnName == i){
				p.note = notespassport[j];
        //p.note.num = curNum++;
				break;
			}
		}
		passport2[i] = p;
	}
  for (let i = 0; i < pord.length; i++) {
    const pp = passport2[pord[i]];
    if(pp && pp.note)
      pp.note.num = curNum++;
  }
	s.passportData = passport2;

	s.bloodComponent = processTable(n['bloodComponent'], std['bloodComponent'], ['name','midRate','sdd','skd','unit']);
	s.diet1 = processTable(n['diet1'], std['diet1'], ['name','midRate','midCount']);
	s.diet2 = processTable(n['diet2'], std['diet2'], ['name','midRate','sdd','skd','unit']);
	s.medicine = processTable(n['medicine'], std['medicine'], ['atcGroupCode','atcName','code','name','medicineMidRate','sdd','skd','unit']);
	s.products = processTable(n['products'], std['products'], ['code','name','midRate','midCount']);
	s.service = processTable(n['service'], std['service'], ['code','name','midRate','midMult','pageId']);
	let servgrouped = {};
	for(let i=0;i<s.service.data.length;i++){
		let ser = s.service.data[i];
		if(!servgrouped[ser[4].value])servgrouped[ser[4].value]=[];
		servgrouped[ser[4].value].push(ser);
	}
	s.service.data = servgrouped;

	s.revisionDate = std.revisionDate;

	return s;
}

export default convert;
