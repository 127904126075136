<template>
   <div class="vue-horizontal-timeline" :style="setTimelineStyles">
     <section class="timeline">
       <ol>
         <li
           v-for="(item, i) in items"
           :key="i"
           :style="setLineColor"
           :class="{'add-step-green': item.Status === status}"
           class="add-step"
         >
         <div
             class="date"
           >
             <span
               class="content"
               >
               {{ new Date(item.ModifiedOn).toLocaleDateString() }}
             </span>
           </div>
           <div
             class="time"
           >
             <span
               class="content"
               >
               {{ item.UserGroupRole}}
             </span>
             <span
               class="content"
               >
                {{ item.UserFio}}
             </span>
             <span
               class="content"
               >
                {{ item.Status}}
             </span>
           </div>
         </li>
       </ol>
     </section>
   </div>
 </template>

 <script>
 export default {
   name: 'VueHorizontalTimeline',
   props: {
     items: {
       type: Array,
       required: true
     },
     itemSelected: {
       type: Object,
       default: () => ({})
     },
     itemUniqueKey: {
       type: String,
       default: ''
     },
     titleAttr: {
       type: String,
       default: 'title'
     },
     titleCentered: {
       type: Boolean,
       default: false
     },
     titleClass: {
       type: String,
       default: ''
     },
     status: {
       type: String,
       default: ''
     },
     contentAttr: {
       type: String,
       default: 'content'
     },
     contentCentered: {
       type: Boolean,
       default: false
     },
     contentClass: {
       type: String,
       default: ''
     },
     contentSubstr: {
       type: Number,
       default: 250
     },
     minWidth: {
       type: String,
       default: '200px'
     },
     minHeight: {
       type: String,
       default: ''
     },
     timelinePadding: {
       type: String,
       default: ''
     },
     timelineBackground: {
       type: String,
       default: ''
     },
     lineColor: {
       type: String,
       default: '#03A9F4'
     },
     clickable: {
       type: [String, Boolean],
       default: true
     }
   },
   emits: ['click', 'update:itemSelected'],
   computed: {
     setTimelineStyles () {
       const { timelineBackground, timelinePadding } = this
       const styleObj = {}

       if (timelinePadding) {
         styleObj.padding = timelinePadding
       }

       if (timelineBackground) {
         styleObj.background = timelineBackground
       }

       return styleObj
     },
     setLineColor () {
       const { lineColor } = this

       return lineColor ? `background: ${lineColor}` : ''
     },
   },
   methods: {
   },
   created(){
    console.log(this.items);
   }
 }
 </script>

 <style src="./style.min.css" scoped />