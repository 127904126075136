<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title class="text-h5">Выберите сертификат для подписи документа</v-card-title>
      <v-card-text>
        <ul>
          <li v-for="(item, index) in certificates" :key="index" @click="select(index)" style="cursor: pointer;">
            ✅ {{ item.fio }}; {{ item.company }}
          </li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="white"
          class="ma-2 red--text"
          @click="close()"
        >
          Отменить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as certificateJS from './certificate.js';

export default {
  name: 'CertificatesDialog',
  props: {
    signature: Object
  },
  data: () => ({
    dialog: false,
    certificates: []
  }),
  methods: {
    open() {
      let certificateId = this.signature !== null ? this.signature.certificateId : null;

      this.certificates = this.$root.$refs.A.getCerts()
        .filter(x => x.id === certificateId)
        .map(x => {
            return {
              cert: x,
              fio: certificateJS.parseFio(x.text),
              company: certificateJS.parseCompany(x.text)
            };
        });

      if (this.certificates.length > 0) {
        this.dialog = true;
      } else {
        this.$emit('message', {
          text: `На вашем компьютере не обнаружен требуемый сертификат №${certificateId}. Обратитесь к администратору.`,
          type: 'warning',
          timeout: -1
        });

        this.close();
      }
    },
    select(index) {
      this.dialog = false;
      this.$root.$refs.A.chooseCert(this.certificates[index].cert);
      this.$emit('selected');
    },
    sign(files) {
      let context = this;

      Promise.all(files.map(file => new Promise(function(resolve, reject) {
        context.$root.$refs.A.sign_file_async(file.object)
          .then(signature => {
            file.signature = signature;
            resolve();
          })
          .catch(error => reject(error));
      })))
      .then(() => {
        this.$emit('signed', files);
      })
      .catch(error => {
        console.debug('error ' + error);

        this.$emit('message', {
          text: 'Ошибка при подписании документа',
          type: 'error',
          timeout: -1
        });
      });
    },
    close() {
      this.dialog = false;
      this.$emit('close');
    }
  }
}
</script>
