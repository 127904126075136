<template>
       <div class="page">
         <div class="d-flex justify-space-between">
         <h2 class="main-title">{{title}}</h2>
      </div>
         <v-data-table
            class="table table-white table-search"
            :items="listSpecialityGvs"
            :loading="loadingTable"
            no-results-text="Данных нет"
            no-data-text="Данных нет"
            loading-text="Данные загружаются..."
            :footer-props="{
            'pageText': '{0}-{1} из {2}',
            'items-per-page-text':'Количество строк на странице'
        }"
        >

            <template v-slot:header>
              <tr>
                <th>
                  <span style="color: #003C69; font-size: 12px;">ФИО</span>
                  <v-text-field style="background: #B6DFF0 url(/img/search.28af04cd.svg) no-repeat 7px 50%; padding-left: 25px; margin-top: 0px; padding-top: 0px;" v-model="search" hide-details>
                  </v-text-field>
                </th>
                <th>
                    <span style="color: #003C69; font-size: 12px;">Специальность</span>
                    <!-- <v-text-field
                        style="background: #B6DFF0 url(/img/search.28af04cd.svg) no-repeat 7px 50%; padding-left: 30px; margin-top: 0px; padding-top: 0px;padding-left: 5px"
                        v-model="search1"
                        hide-details
                    /> -->
                    <v-autocomplete
                        style="background: #B6DFF0; margin-top: 0px; padding-top: 0px;width: 99.8%;"
                        :items="listSpeciality"
                        v-model="search1"
                        @change="update('profileIds', search1, 2)"
                        clearable
                        item-text="name"
                        item-value="id"
                        multiple
                        hide-details
                    />
                </th>
              </tr>
            </template>
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                     <div class="d-flex justify-space-between">
                        <span style="width: 90%;">
                           {{ item.fullName }}
                        </span>
                     </div>

                    </td>
                    <td>
                     <div class="d-flex justify-space-between">
                        <span style="width: 85%;">
                          {{ item.profiles.map((m)=> m.name).join(', ') }}
                        </span>

                        <div v-if="isAdmin">
                           <v-btn small icon color="primary" >
                                <img svg-inline @click="editeItems(item)" :src="require('@/assets/icon/Pencil.svg')" />
                            </v-btn>
                        </div>
                     </div>

                    </td>
                </tr>
            </template>
            <v-progress-linear :active="loadingTable" :indeterminate="loadingTable" absolute bottom color="primary accent-4" ></v-progress-linear>
            </v-data-table>
            <v-dialog v-model="dialog" max-width="600px" persistent>
        <div class="d-flex justify-end">
        </div>
          <v-card>
                  <div style=" padding: 20px 0 0 30px; display: flex; ">
                    <h2 class="header" style="width: 85%;">{{titleDialog}} специальностей</h2>
                    <v-btn icon style="margin: 2px 0 0 5px" class="close" @click="close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                           <path d="M6 5L18.7279 17.7279" stroke="#1976d2" stroke-width="2" stroke-linecap="round"/>
                           <path d="M6 18L18.7279 5.27208" stroke="#1976d2" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                     </v-btn>
                  </div>

              <v-card-text class="pb-0">
                  <v-container>
                      <v-form v-model="valid" ref="form" lazy-validation>
                          <v-row>
                              <v-col cols="12" sm="12" md="12">
                                  <h3 class="title-font">ФИО сотрудника ГВС *</h3>
                                  <v-text-field class="title-font" :rules="[rules.required]" dense outlined v-model="obj.fullName" />
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                  <h3 class="title-font">Специальность *</h3>
                                  <v-autocomplete :items="listSpeciality" item-text="name" item-value="id"  multiple class="title-font" :rules="[rules.requiredMultiple]" dense outlined v-model="obj.profiles" />
                              </v-col>
                          </v-row>
                      </v-form>
                  </v-container>
              </v-card-text>

              <v-card-actions class="pb-5 mr-4 d-flex justify-end">
                <button  class="ma-2 btn-add" outlined  @click="close">Отменить</button>
                  <button class="btn" :loading="saveLoading" @click="save(obj)">Сохранить</button>
              </v-card-actions>
          </v-card>
      </v-dialog>
    </div>
 </template>

 <script>
import {mapGetters, mapActions, mapMutations} from 'vuex'

export default {
    name: 'Manual',
    watch:{
        search(val){
            this.update('fullName',val, 1);
        }
    },
    data () {
       return {
         title: 'Специальности ГВС',
         headers: [
          {text: 'ФИО сотрудника ГВС', value: 'fullName', search: true, align: "center"},
           {text: 'Специальность', value: 'speciality', search: true, align: "center" },
         ],
         pagination: {
            sortBy: 'name',
            itemsPerPage: 'Rjkbxctn'
        },
        page: 1,
        snackbar: false,
        snackbar_text: '',
        snackbarColor: '',
        itemsPerPage: 10,
        listSpecialityGvs: [],
        listSpeciality: [],
        saveLoading: false,
        loadingTable: false,
        obj:{
         fullName: '',
         profiles: []
        },
        params: {
                filters: [],
                sortOption: {
                    fieldName: 'fullName',
                    sortType: 2
                },
                pageSize: 10,
                currentPage: 0,
                useANDoperator: false,
                columns: []
            },
            search: '',
            search1: [],
            dialog: false,
            titleDialog : '',
            valid: '',
            rules: {
               required: (v, text) => !!v || (text ? text : 'Обязательно для заполнения.'),
               requiredMultiple: (v) => v.length > 0 || 'Обязательно для заполнения.',
            }
       }
     },
     computed : {
       ...mapGetters({
           getСatalog: 'catalog/getСatalog',
           apiUrl: 'apiUrl'
       }),
       isAdmin() {
        return JSON.parse(localStorage.getItem('isAdmin'))
       }
     },
     methods: {
       ...mapActions({
         catalog: 'catalog/catalog',
       }),
       ...mapMutations({
         setSearch: 'catalog/setSearch'
       }),
       loadSpecialityGvs(filters){
            this.$http.post(`${this.apiUrl}Users/GetWithProfiles`,{
                columns: this.params.columns,
                sortOption: this.params.sortOption,
                currentPage: this.params.currentPage,
                filters: filters,
                pageSize: this.params.pageSize,
                useANDoperator: this.params.useANDoperator
            })
            .then((res) => {
                this.listSpecialityGvs = res.data.data
                this.loadingTable = false;

            })
       },
       update(name, value, type) {
            if(this.params.filters && this.params.filters.length === 0 ){
                this.params.filters.push( {
                    fieldName: name,
                    filterType: type === 2 ? 9 : 2 ,
                    filterValueType: 1,
                    value1: type === 1 ? value : '',
                    value2: '',
                    values:  type === 2 ? value : []
                });
            }

            if(this.params.filters.length > 0 ){
                if (this.params.filters.some((e)=> e.fieldName === name)) {
                    for (let i = 0; i < this.params.filters.length; i += 1) {
                        if(name === this.params.filters[i].fieldName){
                            if (type === 2) {
                                if(value && value.length > 0){
                                    this.params.filters[i].values = value;
                                }
                                else{
                                    this.params.filters.splice(i, 1);
                                }
                            }
                            if (type === 1 ) {
                                if(value){
                                    console.log(value);
                                    this.params.filters[i].value1 = value;
                                }
                                else{
                                    console.log(2);
                                    this.params.filters.splice(i, 1);
                                }
                            }
                            break;
                        }
                    }
                } else {
                    this.params.filters.push( {
                        fieldName: name,
                        filterType:  type === 1 ? 2 : 9 ,
                        filterValueType: 1,
                        value1: type === 1 ? value : '',
                        value2: '',
                        values:  type === 2 ? value : []
                    });
                }

            }

            this.loadSpecialityGvs(this.params.filters);
        },
       close() {
          this.$refs.form.resetValidation();
          this.edite = false;
          this.obj = { name: '' };
          this.saveLoading = false;
          this.dialog = false;
      },
       addItems() {
          this.titleDialog = 'Добавление'
          this.dialog = true
      },
       editeItems(item){
          this.edite = true;
          this.titleDialog = 'Редактирование'
          this.obj = Object.assign({}, item);
          this.dialog = true
      },
      save(obj) {
          if(this.$refs.form.validate()) {
              this.saveLoading = true;
                  this.$http.put(`${this.apiUrl}Users/UpdateProfiles`, {
                      id: obj.id,
                      profileIds: obj.profiles,
                  })
                  .then((res) => {
                      for (let i = 0; i < this.listSpeciality.length; i++) {
                          if(this.listSpeciality[i].id === res.data.id) {
                              Object.assign(this.listSpeciality[i], res.data);
                          }
                      }
                      this.close();
                      this.loadSpecialityGvs([])
                  })
                  .catch((error) => {
                    if(error){
                        this.snackBarFunc(true, 'Запись с таким именем уже существует', 'warning')
                        }
                    })

              }
      },
       sortArray(array, name) {
      return array.sort((a, b) => {
        if (a[name] < b[name]) {
          return -1;
        }

        if (a[name] > b[name]) {
          return 1;
        }

        return 0;
      })
    },
    snackBarFunc(flag, text, color) {
        this.snackbar = flag
        this.snackbar_text = text
        this.snackbarColor = color
    },
    get(){
        this.$http.post(`${this.apiUrl}Profile/GetAll`,{
        columns: this.params.columns,
        sortOption: {
            fieldName: 'name',
            sortType: 2
        },
        currentPage: this.params.currentPage,
        filters: [],
        pageSize: 99999,
        useANDoperator: this.params.useANDoperator
         })
         .then((res) => {
            this.listSpeciality = res.data.data
            this.loadingTable = false;
         })
    }
     },
     created(){
        this.loadSpecialityGvs([])
        this.get()
     }

   }
 </script>
 <style lang="scss" scoped>
 .table th {
  padding : 0!important;
 }
.btn {
    padding: 6px 12px;
        border-radius: 4px;
        background: #3E5F71;
        color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
  }
  .svg {
      position: absolute;
      top: 30px;
      right: 10px;
    }
    .btn-add {
        padding: 6px 12px;
        border-radius: 4px;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        white-space: nowrap;
        border: 2px solid #3E5F71;
        color: #3E5F71 !important;
}
 </style>
