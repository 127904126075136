'use strict';
var Colors = {
  ERROR: {value:'red'},     // неисправимая ошибка
  FAIL: {value:'red'},      // ошибка с решением
  INFO: {value:'yellow'},   // информация для пользователя
  UPDATE: {value:'yellow'}, // уведомление о новой версии
  SUCCESS: {value:'green'}, // завершенное действие
  WAIT: {value:'grey'}      // ожидание загрузки
};

var extImg = Colors.FAIL;
var extTxt = "Расширение не загружено";

var plgImg = Colors.WAIT;
var plgTxt = "Плагин: ожидание загрузки расширения";

var cspImg = Colors.WAIT;
var cspTxt = "КриптоПро CSP: ожидание загрузки плагина";

var objImg = Colors.WAIT;
var objTxt = "Объекты плагина: ожидание загрузки провайдера";

function setImgSrcAttribute(id, color) {
  var elem = document.getElementById(id);
  var colorString = color.value ? color.value : 'red';
  if (elem) elem.className = "dot " + colorString;
}

function setInnerText(id, value, noescape) {
  var elem = document.getElementById(id);
  if (elem) elem.innerHTML = noescape === true ? value : escapeHtml(value);
}

function setHref(id, value) {
  var elem = document.getElementById(id);
  if (elem) elem.href = value;
}

function platformCheck() {
  if (navigator.userAgent.indexOf('Mac') !== -1) {
    return "macOS";
  } else if (navigator.userAgent.indexOf('Win') !== -1) {
    return "Windows";
  } else {
    return "Linux";
  }
}

function check_browser() {
  var ua= navigator.userAgent, tem, M= ua.match(/(opera|yabrowser|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if(/trident/i.test(M[1])){
      tem =  /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name:'IE', version:(tem[1] || '')};
  }
  if(M[1] === 'Chrome'){
    tem = ua.match(/\b(OPR|Edg|YaBrowser)\/(\d+)/);
    if (tem != null)
    return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] };
  }
  M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null)
  M.splice(1, 1, tem[1]);
  return {name:M[0],version:M[1]};
}
var browserSpecs = check_browser();

function setStateForExtension(img, txt) {
  setImgSrcAttribute("ExtensionEnabledImg", img);
  setInnerText("ExtensionEnabledTxt", txt);
  extImg = img;
  extTxt = txt;
  if (extImg === Colors.FAIL) {
    // ставим значение по умолчанию в зависимости от системы
    var extUrl = "https://docs.cryptopro.ru/cades/plugin/plugin-installation-windows";
    if (platformCheck() === "macOS") {
      extUrl = "https://docs.cryptopro.ru/cades/plugin/plugin-installation-macos";
    } else if (platformCheck() === "Linux") {
      extUrl = "https://docs.cryptopro.ru/cades/plugin/plugin-installation-unix";
    }
    // ставим нужную ссылку если узнали какой браузер
    if (browserSpecs.name === 'Chrome'){
      extUrl = "https://chrome.google.com/webstore/detail/cryptopro-extension-for-c/iifchhfnnmpdbibifmljnfjhpififfog";
    } else if (browserSpecs.name === 'YaBrowser' | browserSpecs.name === 'Opera') {
      extUrl = "https://addons.opera.com/en/extensions/details/cryptopro-extension-for-cades-browser-plug-in";
    } else if (browserSpecs.name === 'Firefox') {
      extUrl = "https://www.cryptopro.ru/sites/default/files/products/cades/extensions/firefox_cryptopro_extension_latest.xpi";
    }
    setInnerText("ExtensionSolution", "<a href='" + extUrl + "'>Загрузить</a>", true);
  } else {
    setInnerText("ExtensionSolution","");
  }
}

function setStateForPlugin(img, txt) {
  setImgSrcAttribute("PluginEnabledImg", img);
  setInnerText("PluginEnabledTxt", txt);
  plgImg = img;
  plgTxt = txt;
  if (plgImg === Colors.UPDATE) {
    setInnerText("PluginSolution", "<a href='https://cryptopro.ru/products/cades/plugin/get_2_0'>Обновить</a>", true);
  } else if (plgImg === Colors.FAIL) {
    setInnerText("PluginSolution", "<a href='https://cryptopro.ru/products/cades/plugin/get_2_0'>Загрузите плагин</a> и обновите страницу", true);
  } else {
    setInnerText("PluginSolution", "");
  }

}

function setStateForCSP(img, txt) {
  setImgSrcAttribute("CspEnabledImg", img);
  setInnerText("CspEnabledTxt", txt);
  cspImg = img;
  cspTxt = txt;
  if (cspImg === Colors.FAIL) {
    setInnerText("CspSolution", "<a href='https://cryptopro.ru/products/csp?csp=download'>Загрузите CSP</a> и обновите страницу", true);
  } else {
    setInnerText("CspSolution", "");
  }
}

function setStateForObjects(img, txt) {
  setImgSrcAttribute("ObjectsLoadedImg", img);
  setInnerText("ObjectsLoadedTxt", txt);
  objImg = img;
  objTxt = txt;
  if (objImg === Colors.FAIL) {
    setInnerText("ObjectsSolution", "<a href='javascript:window.location.reload();'>Обновите страницу</a> или <a href='https://support.cryptopro.ru/'>обратитесь в техподдержку</a>", true);
  } else {
    setInnerText("ObjectsSolution", "");
  }
}

function extensionLoadedCallback() {
  setStateForExtension(Colors.SUCCESS, "Расширение загружено");
  window.cadesplugin_extension_loaded = true;
}

function finalLoad() {
  setStateForExtension(extImg, extTxt);

  setStateForPlugin(plgImg, plgTxt);
  
  setStateForCSP(cspImg, cspTxt);
  
  setStateForObjects(objImg, objTxt);
  
  setInnerText("Platform", "Платформа: " + platformCheck());
  setInnerText("UserAgent", "UserAgent: " + navigator.userAgent);
};

window.cadesplugin_extension_loaded_callback = extensionLoadedCallback;
window.onload = finalLoad;



var isPluginEnabled = false;
var fileContent; // Переменная для хранения информации из файла, значение присваивается в cades_bes_file.html
var global_selectbox_container = new Array();
var global_selectbox_container_thumbprint = new Array();
var global_isFromCont = new Array();
var global_selectbox_counter = 0;
function getXmlHttp(){
    var xmlhttp;
    if (typeof XMLHttpRequest != 'undefined') {
        // IE7+
        xmlhttp = new XMLHttpRequest();
    } else {
        // IE < 7
        try {
            xmlhttp = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (E) {
                xmlhttp = false;
            }
        }
    }
    return xmlhttp;
}
function CertStatusEmoji(isValid, hasPrivateKey) {
    var _emoji = "";
    if (isValid) {
        _emoji = "\u2705";
    } else {
        _emoji = "\u274C";
    }
    //if (hasPrivateKey) {
    //    _emoji += "\uD83D\uDD11";
    //} else {
    //    _emoji += String.fromCodePoint(0x1F6AB);
    //}
    return _emoji;
}
var async_code_included = 0;
var async_Promise;
var async_resolve;

function escapeHtml(unsafe)
{
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

function include_async_code()
{
    if(async_code_included)
    {
        return async_Promise;
    }
    //var fileref = document.createElement('script');
    //fileref.setAttribute("type", "text/javascript");
    //fileref.setAttribute("src", "async_code.js?v=28675b11");
    //document.getElementsByTagName("head")[0].appendChild(fileref);
    async_Promise = new Promise(function(resolve, reject){
        async_resolve = resolve;
    });
    async_code_included = 1;
    return async_Promise;
}
include_async_code().then(function(){
    return null;
});

function cadesPluginUUID() {
    if (!localStorage.hasOwnProperty("cadesPluginUUID")) {
        var uuid = createUUID();
        localStorage.setItem("cadesPluginUUID", uuid);
        return uuid;
    } else {
        var uuid = localStorage.getItem("cadesPluginUUID");
        return uuid;
    }
  }
  
function createUUID() {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";
  
    return s.join("");
}
  
function getTelemetryData(pluginVersion, cspVersion) {
    var osName = platformCheck();
    var uuid = cadesPluginUUID();
    
    return {
        plugin: pluginVersion,
        csp: cspVersion,
        os: osName,
        uuid: uuid,
    };
}

export function Common_VerifyCadesBES(signature, data, flag)
{
    var canAsync = !!cadesplugin.CreateObjectAsync;
    if(canAsync)
    {
        return new Promise(function (resolve, reject) {
            include_async_code().then(function(){
                VerifyCadesBES_Async(signature, data, flag).then(resolve, reject);
            }, reject);
        });
    }else
    {
        return VerifyCadesBES_NPAPI(signature, data);
    }
}

export function Common_VerifyCadesBES_File(signature, file, flag)
{
    let sign = signature;
    var canAsync = !!cadesplugin.CreateObjectAsync;
    if(canAsync)
    {
        return new Promise(function (resolve, reject) {
            //debugger;
            include_async_code().then(function(){
                VerifyCadesBES_File2_Async(sign, file, flag).then(resolve, reject);
            }, reject);
        });
    }else
    {
        return VerifyCadesBES_NPAPI(sign, file);
    }
}

export function Common_VerifyCadesBES_Base64(signature, dataInBase64, flag)
{
    var canAsync = !!cadesplugin.CreateObjectAsync;
    if(canAsync)
    {
        return new Promise(function (resolve, reject) {
            include_async_code().then(function(){
                VerifyCadesBES_B64_Async(signature, dataInBase64, flag).then(resolve, reject);
            }, reject);
        });
    }else
    {
        return VerifyCadesBES_NPAPI(signature, dataInBase64);
    }
}

export function Common_SignCadesBES(cert, text, setDisplayData)
{
    var canAsync = !!cadesplugin.CreateObjectAsync;
    if(canAsync)
    {
        return new Promise(function (resolve, reject) {
            include_async_code().then(function(){
                SignCadesBES_Async(cert, text, setDisplayData).then(resolve, reject);
            }, reject);
        });
    }else
    {
        return SignCadesBES_NPAPI(cert, text, setDisplayData);
    }
}

export function Common_SignCadesBES_File(cert, file, flag) {
    //console.debug('Common_SignCadesBES_File', file);
    var canAsync = !!cadesplugin.CreateObjectAsync;
    if (canAsync) {
        return new Promise(function (resolve, reject) {
            include_async_code().then(function(){
                SignCadesBES_File_Async(cert, file, flag).then(resolve, reject);
            }, reject);
        });
    } else {
        return SignCadesBES_NPAPI_File(cert, file);
    }
}

function Common_CheckForPlugIn() {
    cadesplugin.set_log_level(cadesplugin.LOG_LEVEL_ERROR);
    var canAsync = !!cadesplugin.CreateObjectAsync;
    if(canAsync)
    {
        include_async_code().then(function(){
            return CheckForPlugIn_Async();
        });
    }else
    {
        return CheckForPlugIn_NPAPI();
    }
}

function GetCertificate_NPAPI(certListBoxId) {
    var e = document.getElementById(certListBoxId);
    var selectedCertID = e.selectedIndex;
    if (selectedCertID == -1) {
        alert("Select certificate");
        return;
    }
    return global_selectbox_container[selectedCertID];
}

function ClearCertInfo(field_prefix) {
    document.getElementById(field_prefix + "subject").innerHTML = "";
    document.getElementById(field_prefix + "issuer").innerHTML = "";
    document.getElementById(field_prefix + "from").innerHTML = "";
    document.getElementById(field_prefix + "till").innerHTML = "";
    document.getElementById(field_prefix + "provname").innerHTML = "";
    document.getElementById(field_prefix + "privateKeyLink").innerHTML = "";
    document.getElementById(field_prefix + "algorithm").innerHTML = "";
    document.getElementById(field_prefix + "status").innerHTML = "";
    document.getElementById(field_prefix + "location").innerHTML = "";
}

function FillCertInfo_NPAPI(certificate, certBoxId, isFromContainer)
{
    var BoxId;
    var field_prefix;
    if(typeof(certBoxId) == 'undefined' || certBoxId == "CertListBox")
    {
        BoxId = 'cert_info';
        field_prefix = '';
    }else if (certBoxId == "CertListBox1") {
        BoxId = 'cert_info1';
        field_prefix = 'cert_info1';
    } else if (certBoxId == "CertListBox2") {
        BoxId = 'cert_info2';
        field_prefix = 'cert_info2';
    } else {
        BoxId = certBoxId;
        field_prefix = certBoxId;
    }

    ClearCertInfo(field_prefix);

    var certObj = new CertificateObj(certificate);
    var Now = new Date();
    var ValidToDate = new Date(certificate.ValidToDate);
    var ValidFromDate = new Date(certificate.ValidFromDate);
    document.getElementById(BoxId).style.display = '';
    document.getElementById(field_prefix + "subject").innerHTML = "Владелец: <b>" + escapeHtml(certObj.GetCertName()) + "<b>";
    document.getElementById(field_prefix + "issuer").innerHTML = "Издатель: <b>" + escapeHtml(certObj.GetIssuer()) + "<b>";
    document.getElementById(field_prefix + "from").innerHTML = "Выдан: <b>" + escapeHtml(certObj.GetCertFromDate()) + " UTC<b>";
    document.getElementById(field_prefix + "till").innerHTML = "Действителен до: <b>" + escapeHtml(certObj.GetCertTillDate()) + " UTC<b>";
    var hasPrivateKey = certificate.HasPrivateKey();
    if (hasPrivateKey) {
        document.getElementById(field_prefix + "provname").innerHTML = "Криптопровайдер: <b>" + escapeHtml(certObj.GetPrivateKeyProviderName()) + "<b>";
        try {
            var privateKeyLink = certObj.GetPrivateKeyLink();
            document.getElementById(field_prefix + "privateKeyLink").innerHTML = "Ссылка на закрытый ключ: <b>" + escapeHtml(privateKeyLink) + "<b>";
        } catch (e) {
            document.getElementById(field_prefix + "privateKeyLink").innerHTML = "Ссылка на закрытый ключ: <b> Набор ключей не существует<b>";
        }
    } else {
        document.getElementById(field_prefix + "provname").innerHTML = "Криптопровайдер:<b>";
        document.getElementById(field_prefix + "privateKeyLink").innerHTML = "Ссылка на закрытый ключ:<b>";
    }

    document.getElementById(field_prefix + "algorithm").innerHTML = "Алгоритм ключа: <b>" + escapeHtml(certObj.GetPubKeyAlgorithm()) + "<b>";
    var certIsValid = false;
    if(Now < ValidFromDate) {
        document.getElementById(field_prefix + "status").innerHTML = "Статус: <b class=\"error\">Срок действия не наступил</b>";
    } else if( Now > ValidToDate){
        document.getElementById(field_prefix + "status").innerHTML = "Статус: <b class=\"error\">Срок действия истек</b>";
    } else if( !hasPrivateKey ){
        document.getElementById(field_prefix + "status").innerHTML = "Статус: <b class=\"error\">Нет привязки к закрытому ключу</b>";
    } else {
        //если попадется сертификат с неизвестным алгоритмом
        //тут будет исключение. В таком сертификате просто пропускаем такое поле
        try {
            certIsValid = certificate.IsValid().Result;
        } catch (e) {
            certIsValid = false;
        }
        if (certIsValid) {
            document.getElementById(field_prefix + "status").innerHTML = "Статус: <b> Действителен<b>";
        } else {
            document.getElementById(field_prefix + "status").innerHTML = "Статус: <b class=\"error\">Ошибка при проверке цепочки сертификатов. Возможно на компьютере не установлены сертификаты УЦ, выдавшего ваш сертификат</b>";
        }
    }
    if(isFromContainer)
    {
        if (certIsValid) {
            document.getElementById(field_prefix + "location").innerHTML = "Установлен в хранилище: <span><b class=\"warning\">Нет. При такой конфигурации не все приложения и порталы могут работать</b><br/><a style=\"cursor: pointer\" onclick=\"Common_InstallCertificate('"+ escapeHtml(certBoxId) +"');\">Установить</a></span>";
        } else {
            document.getElementById(field_prefix + "location").innerHTML = "Установлен в хранилище: <b>Нет</b>";
        }
    } else {
        document.getElementById(field_prefix + "location").innerHTML = "Установлен в хранилище: <b>Да</b>";
    }
}

function MakeCadesBesSign_NPAPI(dataToSign, certObject, setDisplayData, isBase64) {
    var errormes = "";

    try {
        var oSigner = cadesplugin.CreateObject("CAdESCOM.CPSigner");
    } catch (err) {
        errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
        alert(errormes);
        throw errormes;
    }

    if (oSigner) {
        oSigner.Certificate = certObject;
    }
    else {
        errormes = "Failed to create CAdESCOM.CPSigner";
        alert(errormes);
        throw errormes;
    }

    try {
        var oSignedData = cadesplugin.CreateObject("CAdESCOM.CadesSignedData");
    } catch (err) {
        alert('Failed to create CAdESCOM.CadesSignedData: ' + err.number);
        return;
    }

    var CADES_BES = 1;
    var Signature;

    if (dataToSign) {
        oSignedData.ContentEncoding = 1; //CADESCOM_BASE64_TO_BINARY
        // Данные на подпись ввели
        if (typeof (isBase64) == 'undefined') {
            oSignedData.Content = Base64.encode(dataToSign);
        } else {
            oSignedData.Content = dataToSign;
        }
    }

    if (typeof (setDisplayData) != 'undefined') {
        //Set display data flag flag for devices like Rutoken PinPad
        oSignedData.DisplayData = 1;
    }
    oSigner.Options = 1; //CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
    try {
        Signature = oSignedData.SignCades(oSigner, CADES_BES);
    }
    catch (err) {
        errormes = "Не удалось создать подпись из-за ошибки: " + cadesplugin.getLastError(err);
        alert(cadesplugin.getLastError(err));
        throw errormes;
    }
    return Signature;
}

function MakeCadesEnhanced_NPAPI(dataToSign, tspService, certObject, sign_type) {
    var errormes = "";

    try {
        var oSigner = cadesplugin.CreateObject("CAdESCOM.CPSigner");
    } catch (err) {
        errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
        alert(errormes);
        throw errormes;
    }

    if (oSigner) {
        oSigner.Certificate = certObject;
    }
    else {
        errormes = "Failed to create CAdESCOM.CPSigner";
        alert(errormes);
        throw errormes;
    }

    try {
        var oSignedData = cadesplugin.CreateObject("CAdESCOM.CadesSignedData");
    } catch (err) {
        alert('Failed to create CAdESCOM.CadesSignedData: ' + cadesplugin.getLastError(err));
        return;
    }

    var Signature;

    if (dataToSign) {
        // Данные на подпись ввели
        oSignedData.Content = dataToSign;
    }
    oSigner.Options = 1; //CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
    oSigner.TSAAddress = tspService;
    try {
        Signature = oSignedData.SignCades(oSigner, sign_type);
    }
    catch (err) {
        errormes = "Не удалось создать подпись из-за ошибки: " + cadesplugin.getLastError(err);
        alert(errormes);
        throw errormes;
    }
    return Signature;
}

function MakeXMLSign_NPAPI(dataToSign, certObject, signatureType) {
    try {
        var oSigner = cadesplugin.CreateObject("CAdESCOM.CPSigner");
    } catch (err) {
        errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
        alert(errormes);
        throw errormes;
    }

    if (oSigner) {
        oSigner.Certificate = certObject;
    }
    else {
        errormes = "Failed to create CAdESCOM.CPSigner";
        alert(errormes);
        throw errormes;
    }

    var signMethod = "";
    var digestMethod = "";

    var pubKey = certObject.PublicKey();
    var algo = pubKey.Algorithm;
    var algoOid = algo.Value;
    if (algoOid == "1.2.643.7.1.1.1.1") {   // алгоритм подписи ГОСТ Р 34.10-2012 с ключом 256 бит
        signMethod = "urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-256";
        digestMethod = "urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-256";
    }
    else if (algoOid == "1.2.643.7.1.1.1.2") {   // алгоритм подписи ГОСТ Р 34.10-2012 с ключом 512 бит
        signMethod = "urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-512";
        digestMethod = "urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-512";
    }
    else if (algoOid == "1.2.643.2.2.19") {  // алгоритм ГОСТ Р 34.10-2001
        signMethod = "urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102001-gostr3411";
        digestMethod = "urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr3411";
    }
    else {
        errormes = "Данная демо страница поддерживает XML подпись сертификатами с алгоритмом ГОСТ Р 34.10-2012, ГОСТ Р 34.10-2001";
        throw errormes;
    }
    
    var CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED = 0|signatureType;
    if (signatureType > cadesplugin.CADESCOM_XADES_BES) {
        var tspService = document.getElementById("TSPServiceTxtBox").value;
        oSigner.TSAAddress = tspService;
    }
    
    try {
        var oSignedXML = cadesplugin.CreateObject("CAdESCOM.SignedXML");
    } catch (err) {
        alert('Failed to create CAdESCOM.SignedXML: ' + cadesplugin.getLastError(err));
        return;
    }

    oSignedXML.Content = dataToSign;
    oSignedXML.SignatureType = CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED;
    oSignedXML.SignatureMethod = signMethod;
    oSignedXML.DigestMethod = digestMethod;


    var sSignedMessage = "";
    try {
        sSignedMessage = oSignedXML.Sign(oSigner);
    }
    catch (err) {
        errormes = "Не удалось создать подпись из-за ошибки: " + cadesplugin.getLastError(err);
        alert(errormes);
        throw errormes;
    }

    return sSignedMessage;
}

function GetSignatureTitleElement()
{
    var elementSignatureTitle = null;
    var x = document.getElementsByName("SignatureTitle");

    if(x.length == 0)
    {
        elementSignatureTitle = document.getElementById("SignatureTxtBox").parentNode.previousSibling;

        if(elementSignatureTitle.nodeName == "P")
        {
            return elementSignatureTitle;
        }
    }
    else
    {
        elementSignatureTitle = x[0];
    }

    return elementSignatureTitle;
}

function SignCadesBES_NPAPI(certListBoxId, data, setDisplayData) {
    var certificate = GetCertificate_NPAPI(certListBoxId);
    var dataToSign = document.getElementById("DataToSignTxtBox").value;
    if(typeof(data) != 'undefined')
    {
        dataToSign = data;
    }
    var x = GetSignatureTitleElement();
    try
    {
        var signature = MakeCadesBesSign_NPAPI(dataToSign, certificate, setDisplayData);
        document.getElementById("SignatureTxtBox").innerHTML = escapeHtml(signature);
        if(x!=null)
        {
            x.innerHTML = "Подпись сформирована успешно:";
        }
    }
    catch(err)
    {
        if(x!=null)
        {
            x.innerHTML = "Возникла ошибка:";
        }
        document.getElementById("SignatureTxtBox").innerHTML = escapeHtml(err);
    }
}

function SignCadesBES_NPAPI_File(certListBoxId) {
    var certificate = GetCertificate_NPAPI(certListBoxId);
    var dataToSign = fileContent;
    var x = GetSignatureTitleElement();
    try {
        var StartTime = Date.now();
        var setDisplayData;
        var signature = MakeCadesBesSign_NPAPI(dataToSign, certificate, setDisplayData, 1);
        var EndTime = Date.now();
        document.getElementsByName('TimeTitle')[0].innerHTML = "Время выполнения: " + (EndTime - StartTime) + " мс";
        document.getElementById("SignatureTxtBox").innerHTML = escapeHtml(signature);
        if (x != null) {
            x.innerHTML = "Подпись сформирована успешно:";
        }
    }
    catch (err) {
        if (x != null) {
            x.innerHTML = "Возникла ошибка:";
        }
        document.getElementById("SignatureTxtBox").innerHTML = escapeHtml(err);
    }
}

function SignCadesEnhanced_NPAPI(certListBoxId, sign_type) {
    var certificate = GetCertificate_NPAPI(certListBoxId);
    var dataToSign = document.getElementById("DataToSignTxtBox").value;
    var tspService = document.getElementById("TSPServiceTxtBox").value ;
    var x = GetSignatureTitleElement();
    try
    {
        var signature = MakeCadesEnhanced_NPAPI(dataToSign, tspService, certificate, sign_type);
        document.getElementById("SignatureTxtBox").innerHTML = escapeHtml(signature);
        if(x!=null)
        {
            x.innerHTML = "Подпись сформирована успешно:";
        }
    }
    catch(err)
    {
        if(x!=null)
        {
            x.innerHTML = "Возникла ошибка:";
        }
        document.getElementById("SignatureTxtBox").innerHTML = escapeHtml(err);
    }
}

function MakeVersionString(oVer)
{
    var strVer;
    if(typeof(oVer)=="string")
        return oVer;
    else
        return oVer.MajorVersion + "." + oVer.MinorVersion + "." + oVer.BuildVersion;
}

function CheckForPlugIn_NPAPI() {
    function VersionCompare_NPAPI(StringVersion, CurrentVersion)
    {
        // on error occurred suppose that current is actual
        var isActualVersion = true;
        if(typeof(CurrentVersion) === "string")
            return isActualVersion;

        var arr = StringVersion.split('.');
        var NewVersion = {
            MajorVersion: parseInt(arr[0]), 
            MinorVersion: parseInt(arr[1]), 
            BuildVersion: parseInt(arr[2])
        };
        if(NewVersion.MajorVersion > CurrentVersion.MajorVersion) {
            isActualVersion = false;
        } else if(NewVersion.MinorVersion > CurrentVersion.MinorVersion) {
            isActualVersion = false;
        } else if(NewVersion.BuildVersion > CurrentVersion.BuildVersion) {
            isActualVersion = false;
        }

        return isActualVersion;
    }

    function GetCSPVersion_NPAPI() {
        try {
           var oAbout = cadesplugin.CreateObject("CAdESCOM.About");
        } catch (err) {
            alert('Failed to create CAdESCOM.About: ' + cadesplugin.getLastError(err));
            return;
        }
        var ver = oAbout.CSPVersion("", 80);
        setStateForCSP(Colors.SUCCESS, "Криптопровайдер загружен");
        return ver.MajorVersion + "." + ver.MinorVersion + "." + ver.BuildVersion;
    }

    function GetCSPName_NPAPI() {
        var sCSPName = "";
        try {
            var oAbout = cadesplugin.CreateObject("CAdESCOM.About");
            sCSPName = oAbout.CSPName(80);

        } catch (err) {
        }
        return sCSPName;
    }

    function ShowCSPVersion_NPAPI(CurrentPluginVersion)
    {
        if(typeof(CurrentPluginVersion) != "string")
        {
            document.getElementById('CSPVersionTxt').innerHTML = escapeHtml("Версия криптопровайдера: " + GetCSPVersion_NPAPI());
        }
        var sCSPName = GetCSPName_NPAPI();
        if(sCSPName!="")
        {
            document.getElementById('CSPNameTxt').innerHTML = escapeHtml("Криптопровайдер: " + sCSPName);
        }
    }

    function CheckUpdateServer(CurrentPluginVersion, CurrentCSPVersion) {
        var StringPluginVersion = MakeVersionString(CurrentPluginVersion);
        var telemetryData = getTelemetryData(StringPluginVersion, CurrentCSPVersion);
        var paramsArray = [];
        var params = "?";
        for (var property in telemetryData) {
            paramsArray.push(property + "=" + telemetryData[property].toLowerCase());
        }
        params += paramsArray.join('&');
        try {
            var xmlhttp = getXmlHttp();
            xmlhttp.onreadystatechange = function() {
                if (xmlhttp.readyState == 4) {
                    if(xmlhttp.status == 200) {
                        var jsonResponse = JSON.parse(xmlhttp.responseText);
                        var versions = jsonResponse.versions;
                        for (var i = 0; i < versions.length; i++) {
                            var PluginBaseVersion = versions[i].version;
                            if (isPluginWorked) { // плагин работает, объекты создаются
                                if (!VersionCompare_NPAPI(PluginBaseVersion, CurrentPluginVersion)) {
                                    setStateForPlugin(Colors.UPDATE, "Плагин загружен, но есть более свежая версия");
                                }
                            }
                        }
                    }
                }
            }
            xmlhttp.open("GET", "https://api.cryptopro.ru/v1/cades/getState" + params, true);
            xmlhttp.send(null);
        }
        catch (exception) {
            // check version failed, nothing to do
        }
    }

    var isPluginLoaded = false;
    var isPluginWorked = false;
    var isActualVersion = false;
    setStateForExtension(Colors.SUCCESS, "Расширение не требуется");
    setStateForPlugin(Colors.INFO, "Плагин загружается");
    setStateForCSP(Colors.INFO, "КриптоПро CSP не загружен");
    try {
        var oAbout = cadesplugin.CreateObject("CAdESCOM.About");
        isPluginLoaded = true;
        isPluginEnabled = true;
        isPluginWorked = true;

        // Это значение будет проверяться сервером при загрузке демо-страницы
        var CurrentPluginVersion = oAbout.PluginVersion;
        if( typeof(CurrentPluginVersion) === "undefined") {
            CurrentPluginVersion = oAbout.Version;
        }
        setStateForPlugin(Colors.SUCCESS, "Плагин загружен");
        ShowCSPVersion_NPAPI(CurrentPluginVersion);
        CheckUpdateServer(CurrentPluginVersion, GetCSPVersion_NPAPI());
    }
    catch (err) {
        // Объект создать не удалось, проверим, установлен ли
        // вообще плагин. Такая возможность есть не во всех браузерах
        var mimetype = navigator.mimeTypes["application/x-cades"];
        if (mimetype) {
            isPluginLoaded = true;
            var plugin = mimetype.enabledPlugin;
            if (plugin) {
                isPluginEnabled = true;
            }
        }
    }
    if (!isPluginWorked) { // плагин не работает, объекты не создаются
        if (isPluginLoaded) { // плагин загружен
            if (!isPluginEnabled) { // плагин загружен, но отключен
                setStateForPlugin(Colors.ERROR, "Плагин загружен, но отключен в настройках браузера");
            }
            else { // плагин загружен и включен, но объекты не создаются
                setStateForPlugin(Colors.ERROR, "Плагин загружен, но не удается создать объекты. Проверьте настройки браузера");
            }
        }
        else { // плагин не загружен
            setStateForPlugin(Colors.ERROR, "Плагин не загружен");
        }
        return;
    }
    setStateForObjects(Colors.INFO, "Идет перечисление объектов плагина");
    setTimeout(function() {
        FillCertList_NPAPI('CertListBox');
    }, 1);
}

function CertificateObj(certObj)
{
    this.cert = certObj;
    this.certFromDate = new Date(this.cert.ValidFromDate);
    this.certTillDate = new Date(this.cert.ValidToDate);
}

CertificateObj.prototype.check = function(digit)
{
    return (digit<10) ? "0"+digit : digit;
}

CertificateObj.prototype.checkQuotes = function(str)
{
    var result = 0, i = 0;
    for(i;i<str.length;i++)if(str[i]==='"')
        result++;
    return !(result%2);
}

CertificateObj.prototype.extract = function(from, what)
{
    var certName = "";

    var begin = from.indexOf(what);

    if(begin>=0)
    {
        var end = from.indexOf(', ', begin);
        while(end > 0) {
            if (this.checkQuotes(from.substr(begin, end-begin)))
                break;
            end = from.indexOf(', ', end + 1);
        }
        certName = (end < 0) ? from.substr(begin) : from.substr(begin, end - begin);
    }

    return certName;
}

CertificateObj.prototype.DateTimePutTogether = function(certDate)
{
    return this.check(certDate.getUTCDate())+"."+this.check(certDate.getUTCMonth()+1)+"."+certDate.getFullYear() + " " +
                 this.check(certDate.getUTCHours()) + ":" + this.check(certDate.getUTCMinutes()) + ":" + this.check(certDate.getUTCSeconds());
}

CertificateObj.prototype.GetCertString = function()
{
    return this.extract(this.cert.SubjectName,'CN=') + "; Выдан: " + this.GetCertFromDate() + "; id: ";// + this.cert.GetCertInfoString() ;
}

CertificateObj.prototype.GetCertFromDate = function()
{
    return this.DateTimePutTogether(this.certFromDate);
}

CertificateObj.prototype.GetCertTillDate = function()
{
    return this.DateTimePutTogether(this.certTillDate);
}

CertificateObj.prototype.GetPubKeyAlgorithm = function()
{
    return this.cert.PublicKey().Algorithm.FriendlyName;
}

CertificateObj.prototype.GetCertName = function()
{
    return this.extract(this.cert.SubjectName, 'CN=');
}

CertificateObj.prototype.GetIssuer = function()
{
    return this.extract(this.cert.IssuerName, 'CN=');
}

CertificateObj.prototype.GetPrivateKeyProviderName = function()
{
    return this.cert.PrivateKey.ProviderName;
}

CertificateObj.prototype.GetPrivateKeyLink = function () {
    return this.cert.PrivateKey.UniqueContainerName;
}

function GetFirstCert_NPAPI() {
    try {
        var oStore = cadesplugin.CreateObject("CAdESCOM.Store");
        oStore.Open();
    }
    catch (e) {
        alert("Certificate not found");
        return;
    }

    var dateObj = new Date();
    var certCnt;

    try {
        certCnt = oStore.Certificates.Count;
        if(certCnt==0)
            throw "Certificate not found";
    }
    catch (ex) {
        oStore.Close();
        document.getElementById("boxdiv").style.display = 'flex';
        return;
    }

    if(certCnt) {
        try {
            for (var i = 1; i <= certCnt; i++) {
                var cert = oStore.Certificates.Item(i);
                if(dateObj<cert.ValidToDate && cert.HasPrivateKey() && cert.IsValid().Result){
                    return cert;
                }
            }
        }
        catch (ex) {
            alert("Ошибка при перечислении сертификатов: " + cadesplugin.getLastError(ex));
            return;
        }
    }
}

function onCertificateSelected(event) {
    var selectedCertID = event.target.selectedIndex;
    var certificate = global_selectbox_container[selectedCertID];
    FillCertInfo_NPAPI(certificate, event.target.boxId, global_isFromCont[selectedCertID]);
}


function FillCertList_NPAPI(lstId, lstId2) {
    setStateForObjects(Colors.INFO, "Идет перечисление объектов плагина");
    var MyStoreExists = true;
    try {
        var oStore = cadesplugin.CreateObject("CAdESCOM.Store");
        if (!oStore) {
            alert("Create store failed");
            setStateForObjects(Colors.FAIL, "Ошибка при перечислении объектов плагина");
            return;
        }

        oStore.Open();
    }
    catch (ex) {
        MyStoreExists = false;
    }

    var lst = document.getElementById(lstId);
    if(!lst)
    {
        setStateForObjects(Colors.FAIL, "Ошибка при перечислении объектов плагина");
        return;
    }
    lst.onchange = onCertificateSelected;
    lst.boxId = lstId;

    // второй список опционален
    var lst2 = document.getElementById(lstId2);
    if(lst2)
    {
        lst2.onchange = onCertificateSelected;
        lst2.boxId = lstId2;
    }


    if(MyStoreExists) {
        try {
            var certCnt = oStore.Certificates.Count;
        }
        catch (ex) {
            alert("Ошибка при получении Certificates или Count: " + cadesplugin.getLastError(ex));
            setStateForObjects(Colors.FAIL, "Ошибка при перечислении объектов плагина");
            return;
        }
        for (var i = 1; i <= certCnt; i++) {
            try {
                var cert = oStore.Certificates.Item(i);
            }
            catch (ex) {
                alert("Ошибка при перечислении сертификатов: " + cadesplugin.getLastError(ex));
                setStateForObjects(Colors.ERROR, "Ошибка при перечислении объектов плагина");
                return;
            }

            try {
                var foundIndex = global_selectbox_container_thumbprint.indexOf(cert.Thumbprint);
                if (foundIndex > -1) {
                    continue;
                }
                var oOpt = document.createElement("OPTION");
                try {
                    var certObj = new CertificateObj(cert, true);
                    oOpt.text = CertStatusEmoji(cert.ValidToDate > Date.now()) + certObj.GetCertString();
                }
                catch (ex) {
                    alert("Ошибка при получении свойства SubjectName: " + cadesplugin.getLastError(ex));
                }
                oOpt.value = global_selectbox_counter;
                lst.options.add(oOpt);
                if (lst2) {
                    var oOpt2 = document.createElement("OPTION");
                    oOpt2.text = oOpt.text;
                    oOpt2.value = oOpt.value;
                    lst2.options.add(oOpt2);
                }
                global_selectbox_container.push(cert);
                global_selectbox_container_thumbprint.push(cert.Thumbprint);
                global_isFromCont.push(false);
                global_selectbox_counter++;
            }
            catch (ex) {
                alert("Ошибка при получении свойства Thumbprint: " + cadesplugin.getLastError(ex));
            }
        }
        oStore.Close();
    }

    //В версии плагина 2.0.13292+ есть возможность получить сертификаты из 
    //закрытых ключей и не установленных в хранилище
    try {
        oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
        certCnt = oStore.Certificates.Count;
        for (var i = 1; i <= certCnt; i++) {
            try {
                var cert = oStore.Certificates.Item(i);
            }
            catch (ex) {
                alert("Ошибка при перечислении сертификатов: " + cadesplugin.getLastError(ex));
                setStateForObjects(Colors.FAIL, "Ошибка при перечислении объектов плагина");
                return;
            }

            try {
                var certThumbprint = cert.Thumbprint;
                var foundIndex = global_selectbox_container_thumbprint.indexOf(certThumbprint);
                if (foundIndex > -1) {
                    continue;
                }
                var certObj = new CertificateObj(cert);
                var oOpt = document.createElement("OPTION");
                oOpt.text = CertStatusEmoji(cert.ValidToDate > Date.now()) + certObj.GetCertString();
                oOpt.value = global_selectbox_counter;
                lst.options.add(oOpt);
                if (lst2) {
                    var oOpt2 = document.createElement("OPTION");
                    oOpt2.text = oOpt.text;
                    oOpt2.value = oOpt.value;
                    lst2.options.add(oOpt2);
                }
                global_selectbox_container.push(cert);
                global_selectbox_container_thumbprint.push(cert.Thumbprint);
                global_isFromCont.push(true);
                global_selectbox_counter++;
            }
            catch (ex) {
                alert("Ошибка при получении свойства Thumbprint: " + cadesplugin.getLastError(ex));
            }
        }
        oStore.Close();
    }
    catch (ex) {
    }
    if(global_selectbox_container.length == 0) {
        document.getElementById("boxdiv").style.display = 'flex';
    }
    setStateForObjects(Colors.SUCCESS, "Перечисление объектов плагина завершено");
}

export function isIE() {
    var retVal = (("Microsoft Internet Explorer" == navigator.appName) || // IE < 11
        navigator.userAgent.match(/Trident\/./i)); // IE 11
    return retVal;
}

function isEdge() {
    var retVal = navigator.userAgent.match(/Edge\/./i);
    return retVal;
}

function isYandex() {
    var retVal = navigator.userAgent.match(/YaBrowser\/./i);
    return retVal;
}

function ShowEdgeNotSupported() {
    setStateForPlugin(Colors.ERROR, "К сожалению, браузер Edge не поддерживается, обновитесь до Edge версии >= 79");
}

//-----------------------------------
var Base64 = {


    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",


    encode: function(input) {
            var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;

        input = Base64._utf8_encode(input);

        while (i < input.length) {

            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                    enc4 = 64;
            }

            output = output + this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) + this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);

        }

        return output;
    },


    decode: function(input) {
            var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;

        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

        while (i < input.length) {

            enc1 = this._keyStr.indexOf(input.charAt(i++));
            enc2 = this._keyStr.indexOf(input.charAt(i++));
            enc3 = this._keyStr.indexOf(input.charAt(i++));
            enc4 = this._keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output = output + String.fromCharCode(chr1);

            if (enc3 != 64) {
                    output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                    output = output + String.fromCharCode(chr3);
            }

        }

        output = Base64._utf8_decode(output);

        return output;

    },

    _utf8_encode: function(string) {
            string = string.replace(/\r\n/g, "\n");
        var utftext = "";

        for (var n = 0; n < string.length; n++) {

            var c = string.charCodeAt(n);

            if (c < 128) {
                    utftext += String.fromCharCode(c);
            }
            else if ((c > 127) && (c < 2048)) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        }

        return utftext;
    },

    _utf8_decode: function(utftext) {
        var string = "";
        var i = 0;
        var c, c2, c3;
        c = c2 = c3 = 0;

        while (i < utftext.length) {

            c = utftext.charCodeAt(i);

            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            }
            else if ((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i + 1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            }
            else {
                c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }

        }

        return string;
    }

}


            var canPromise = !!window.Promise;
            if(canPromise) {
                cadesplugin.then(function () {
                        // прикладной код
                        start();
                    },
                    function(error) {
                        // сообщение об ошибке
                        console.error('cadesplugin load error:', error);
                    }
                );
            } else {
                window.addEventListener("message", function (event){
                    if (event.data == "cadesplugin_loaded") {
                        // прикладной код
                        start();
                    } else if(event.data == "cadesplugin_load_error") {
                        // сообщение об ошибке
                        console.error('cadesplugin load error');
                    }
                    },
                false);
                window.postMessage("cadesplugin_echo_request", "*");
            }

            function start(){
                console.debug('cadesplugin loaded');
                console.debug('JSModuleVersion: ', cadesplugin.JSModuleVersion);
                cadesplugin.async_spawn(function *(args) {
                    var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                    var dataToSign = "dataToSign";
                    yield oSignedData.propset_Content(dataToSign);
                });
            }

            var chrome_ext_id = "iifchhfnnmpdbibifmljnfjhpififfog";
            var opera_ext_id = "epebfcehmdedogndhlcacafjaacknbcm";
            
            function isExtensionNeeded() {
              if (isIE()) return false;
              if (browserSpecs.name == 'Edge') { return true; }
              if (browserSpecs.name == 'Opera') { if (browserSpecs.version >= 33) { return true; } else { return false; } }
              if (browserSpecs.name == 'Firefox') { if (browserSpecs.version >= 52) { return true; } else { return false; } }
              if (browserSpecs.name == 'Chrome') { if (browserSpecs.version >= 42) { return true; } else { return false; } }
              if (browserSpecs.name == 'Safari') { if (browserSpecs.version >= 11) { return true; } else { return false; } }
              return true;
            }
            
            if (!isExtensionNeeded()) {
              window.cadesplugin_extension_loaded = true;
              setStateForExtension(Colors.SUCCESS, "Расширение не требуется");
            }
            
            if (isEdge()) {
              setStateForExtension(Colors.ERROR, "Расширение не загружено");
              ShowEdgeNotSupported();
            } else {
              if (canPromise) {
                cadesplugin.then(
                  function () {
                    Common_CheckForPlugIn();
                  },
                  function (error) {
                    if (window.cadesplugin_extension_loaded) {
                      setStateForPlugin(Colors.FAIL, error);
                    }
                    if (isYandex()) {
                      var fileref = document.createElement('script');
                      fileref.setAttribute("type", "text/javascript");
                      fileref.setAttribute("src", "chrome-extension://iifchhfnnmpdbibifmljnfjhpififfog/nmcades_plugin_api.js");
                      fileref.onload = function () {
                        try {
                          window.addEventListener('load', function () {
                            cadesplugin.get_extension_id(function (ext_id) {
                              if (ext_id === chrome_ext_id) {
                                setStateForExtension(Colors.UPDATE,
                                  "Для работы в Yandex Browser требуется расширение из Opera Store");
                                extUrl = "https://addons.opera.com/en/extensions/details/cryptopro-extension-for-cades-browser-plug-in";
                                setInnerText("ExtensionSolution", "<a href='" + extUrl + "'>Загрузить</a>", true);
                              }
                            });
                          })
                        }
                        catch (err) { }
                      };
                      document.getElementsByTagName("head")[0].appendChild(fileref);
                    }
                  }
                );
              }
            }
            


function CertificateAdjuster()
{
}


CertificateAdjuster.prototype.checkQuotes = function(str)
{
    var result = 0, i = 0;
    for(i;i<str.length;i++)if(str[i]==='"')
        result++;
    return !(result%2);
}

CertificateAdjuster.prototype.extract = function(from, what)
{
    var certName = "";

    var begin = from.indexOf(what);

    if(begin>=0)
    {
        var end = from.indexOf(', ', begin);
        while(end > 0) {
            if (this.checkQuotes(from.substr(begin, end-begin)))
                break;
            end = from.indexOf(', ', end + 1);
        }
        certName = (end < 0) ? from.substr(begin) : from.substr(begin, end - begin);
    }

    return certName;
}

CertificateAdjuster.prototype.Print2Digit = function(digit)
{
    return (digit<10) ? "0"+digit : digit;
}

CertificateAdjuster.prototype.GetCertDate = function(paramDate)
{
    var certDate = new Date(paramDate);
    return this.Print2Digit(certDate.getUTCDate())+"."+this.Print2Digit(certDate.getUTCMonth()+1)+"."+certDate.getFullYear() + " " +
             this.Print2Digit(certDate.getUTCHours()) + ":" + this.Print2Digit(certDate.getUTCMinutes()) + ":" + this.Print2Digit(certDate.getUTCSeconds());
}

CertificateAdjuster.prototype.GetCertName = function(certSubjectName)
{
    return this.extract(certSubjectName, 'CN=');
}

CertificateAdjuster.prototype.GetIssuer = function(certIssuerName)
{
    return this.extract(certIssuerName, 'CN=');
}

CertificateAdjuster.prototype.GetCertInfoString = function(certSubjectName, certFromDate, certIssuerName)
{
    return certSubjectName + "; Выдан: " + this.GetCertDate(certFromDate) + "; " + certIssuerName;//this.extract(certSubjectName,'CN=')
}

function CheckForPlugIn_Async() {
    function VersionCompare_Async(StringVersion, CurrentVersion)
    {
        // on error occurred suppose that current is actual
        var isActualVersion = true;

        if(typeof(CurrentVersion) === "string")
            return;

        var arr = StringVersion.split('.');
        var NewVersion = {
            MajorVersion: parseInt(arr[0]), 
            MinorVersion: parseInt(arr[1]), 
            BuildVersion: parseInt(arr[2])
        };
        cadesplugin.async_spawn(function *() {
            if(NewVersion.MajorVersion > (yield CurrentVersion.MajorVersion)) {
                isActualVersion = false;
            } else if(NewVersion.MinorVersion > (yield CurrentVersion.MinorVersion)) {
                isActualVersion = false;
            } else if(NewVersion.BuildVersion > (yield CurrentVersion.BuildVersion)) {
                isActualVersion = false;
            }

            if(!isActualVersion) {
                setStateForPlugin(Colors.UPDATE, "Плагин загружен, но есть более свежая версия.");
            }
            return;
        });
    }

    function ext_version_loaded_callback(ext_version) {
        document.getElementById('ExtVersionTxt').innerHTML = escapeHtml("Версия расширения: " + ext_version);
    }

    var extStore = "";
    function ext_id_loaded_callback(ext_id) {
        var OperaStoreExtId = "epebfcehmdedogndhlcacafjaacknbcm";
        var ChromeStoreExtId = "iifchhfnnmpdbibifmljnfjhpififfog";
        if (extStore !== "")
            extStore += ", ";
        if (ext_id === OperaStoreExtId)
            extStore += "Opera Store";
        else if (ext_id === ChromeStoreExtId)
            extStore += "Chrome Store";
        document.getElementById('ExtStoreTxt').innerHTML = escapeHtml("Магазин расширений: " + extStore);
    }

    var versionStruct = {csp: null, os: null, plugin: null, uuid: null};
    setStateForCSP(Colors.INFO, "КриптоПро CSP не загружен");
    cadesplugin.async_spawn(function *() {
        var oAbout = yield cadesplugin.CreateObjectAsync("CAdESCOM.About");
        cadesplugin.get_extension_version(ext_version_loaded_callback);
        cadesplugin.get_extension_id(ext_id_loaded_callback);
        var CurrentPluginVersion = yield oAbout.PluginVersion;
        versionStruct.plugin = yield CurrentPluginVersion.toString();
        //document.getElementById('PlugInVersionTxt').innerHTML = escapeHtml("Версия плагина: " + (versionStruct.plugin));
        setStateForPlugin(Colors.SUCCESS, "Плагин загружен");
        var ver = yield oAbout.CSPVersion("", 80);
        versionStruct.csp = (yield ver.MajorVersion) + "." + (yield ver.MinorVersion) + "." + (yield ver.BuildVersion);
        //document.getElementById('CSPVersionTxt').innerHTML = escapeHtml("Версия криптопровайдера: " + versionStruct.csp);
        try {
            var sCSPName = yield oAbout.CSPName(80);
            setStateForCSP(Colors.SUCCESS, "Криптопровайдер загружен");
            //document.getElementById('CSPNameTxt').innerHTML = escapeHtml("Криптопровайдер: " + sCSPName);
        }
        catch (err) { }
        // CheckUpdateServer(CurrentPluginVersion, versionStruct);
        if(location.pathname.indexOf("symalgo_sample.html")>=0){
            FillCertList_Async('CertListBox1', 'CertListBox2');
        }else {
            FillCertList_Async('CertListBox');
        }
    }); //cadesplugin.async_spawn
}

export var certsinfo = {
    status: 'none',
    certs: [],
    selected: {
        subject: 'none'
    },
    signature: {}
};

function FillCertList_Async(lstId) {
    cadesplugin.async_spawn(function *() {
        certsinfo.status = "Идет перечисление объектов плагина";
        var MyStoreExists = true;
        try {
            var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
            if (!oStore) {
                alert("Create store failed");
                certsinfo.status = "Ошибка при перечислении объектов плагина";
                return;
            }

            yield oStore.Open();
        }
        catch (ex) {
            MyStoreExists = false;
        }

        if (MyStoreExists) {
            try {
                var certs = yield oStore.Certificates;
                var certCnt = yield certs.Count;
            }
            catch (ex) {
                alert("Ошибка при получении Certificates или Count: " + cadesplugin.getLastError(ex));
                certsinfo.status = "Ошибка при перечислении объектов плагина";
                return;
            }
            for (var i = 1; i <= certCnt; i++) {
                try {
                    var cert = yield certs.Item(i);
                }
                catch (ex) {
                    alert("Ошибка при перечислении сертификатов: " + cadesplugin.getLastError(ex));
                    certsinfo.status = "Ошибка при перечислении объектов плагина";
                    return;
                }

                try {
                    var certThumbprint = yield cert.Thumbprint;
                    var foundIndex = global_selectbox_container_thumbprint.indexOf(certThumbprint);
                    if (foundIndex > -1) {
                        continue;
                    }
                    var ValidFromDate = new Date((yield cert.ValidFromDate));
                    var ValidToDate = new Date(yield cert.ValidToDate);
                    var hasPrivateKey = yield cert.HasPrivateKey();
                    var IsValid = ValidToDate > Date.now() && ValidFromDate < Date.now() && hasPrivateKey;
                    if(IsValid){
                        let certIsValid = false;
                        try {
                            let Validator = yield cert.IsValid();
                            certIsValid = yield Validator.Result;
                        } catch(e) {
                            certIsValid = false;
                        }
                        if(certIsValid)
                        {
                            var oPrivateKey = yield cert.PrivateKey;
                            var sProviderName = yield oPrivateKey.ProviderName;
                            if(sProviderName.indexOf('Crypto-Pro') === 0){
                                try {
                                    var emoji = CertStatusEmoji(IsValid);
                                    var oOpt = {cert};
                                    oOpt.text = emoji + new CertificateAdjuster().GetCertInfoString(yield cert.SubjectName, ValidFromDate, yield cert.IssuerName);
                                    oOpt.id = yield cert.SerialNumber;
                                }
                                catch (ex) {
                                    alert("Ошибка при получении свойства SubjectName: " + cadesplugin.getLastError(ex));
                                }
                                oOpt.value = global_selectbox_counter;
                                certsinfo.certs.push(oOpt);
                                global_selectbox_container.push(cert);
                                global_selectbox_container_thumbprint.push(certThumbprint);
                                global_isFromCont.push(false);
                                global_selectbox_counter++;
                            }
                        }
                    }
                }
                catch (ex) {
                    alert("Ошибка при получении свойства Thumbprint: " + cadesplugin.getLastError(ex));
                }
            }
            yield oStore.Close();
        }

        //В версии плагина 2.0.13292+ есть возможность получить сертификаты из 
        //закрытых ключей и не установленных в хранилище
        try {
            yield oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
            try {
                var certs = yield oStore.Certificates;
                var certCnt = yield certs.Count;
            }
            catch (ex) {
                alert("Ошибка при получении Certificates или Count: " + cadesplugin.getLastError(ex));
                certsinfo.status = "Ошибка при перечислении объектов плагина";
                return;
            }
            for (var i = 1; i <= certCnt; i++) {
                try {
                    var cert = yield certs.Item(i);
                }
                catch (ex) {
                    alert("Ошибка при перечислении сертификатов: " + cadesplugin.getLastError(ex));
                    certsinfo.status = "Ошибка при перечислении объектов плагина";
                    return;
                }

                try {
                    var certThumbprint = yield cert.Thumbprint;
                    var foundIndex = global_selectbox_container_thumbprint.indexOf(certThumbprint);
                    if (foundIndex > -1) {
                        continue;
                    }
                    var ValidFromDate = new Date((yield cert.ValidFromDate));
                    var ValidToDate = new Date(yield cert.ValidToDate);
                    var hasPrivateKey = yield cert.HasPrivateKey();
                    var IsValid = ValidToDate > Date.now() && ValidFromDate < Date.now() && hasPrivateKey;
                    if(IsValid){
                        let certIsValid = false;
                        try {
                            let Validator = yield cert.IsValid();
                            certIsValid = yield Validator.Result;
                        } catch(e) {
                            certIsValid = false;
                        }
                        if(certIsValid){
                            var oPrivateKey = yield cert.PrivateKey;
                            var sProviderName = yield oPrivateKey.ProviderName;
                            if(sProviderName.indexOf('Crypto-Pro') === 0){
                                var oOpt = {};
                                try {
                                    var emoji = CertStatusEmoji(IsValid);
                                    oOpt.text = emoji + new CertificateAdjuster().GetCertInfoString(yield cert.SubjectName, ValidFromDate, yield cert.IssuerName);
                                }
                                catch (ex) {
                                    alert("Ошибка при получении свойства SubjectName: " + cadesplugin.getLastError(ex));
                                }
                                oOpt.value = global_selectbox_counter;
                                certsinfo.certs.push(oOpt);
                                global_selectbox_container.push(cert);
                                global_selectbox_container_thumbprint.push(certThumbprint);
                                global_isFromCont.push(true);
                                global_selectbox_counter++;
                            }
                        }
                    }
                }
                catch (ex) {
                    alert("Ошибка при получении свойства Thumbprint: " + cadesplugin.getLastError(ex));
                }
            }
            yield oStore.Close();

        }
        catch (ex) {
        }
        if(global_selectbox_container.length == 0) {
            //document.getElementById("boxdiv").style.display = 'flex';
        }
        if(global_selectbox_container.length == 1) {
            //document.getElementById("DataToSign").style.display = 'flex';
            //document.getElementById("DataToSignItemBorder").style.display = 'flex';
            //document.getElementById("SignBtn").style.display = 'flex';
            //var lstel = document.getElementById("CertListBox");
            var selectedsertel = certsinfo.certs[0];
            var selectedCertID = 0;
            var certificate = global_selectbox_container[selectedCertID];
            FillCertInfo_Async(certificate, certsinfo, global_isFromCont[selectedCertID]);
            //lstel.selectedIndex = 0;
            // cadesplugin.async_spawn(function *(args) {
            //     var selectedCertID = 0;
            //     var certificate = global_selectbox_container[selectedCertID];
            //     FillCertInfo_Async(certificate, 'CertListBox', global_isFromCont[selectedCertID]);
            // }, selectedsertel);
        }
        setStateForObjects(Colors.SUCCESS, "Перечисление объектов плагина завершено");
    });//cadesplugin.async_spawn
}

function CreateSimpleSign_Async() {
    cadesplugin.async_spawn(function*(arg) {
        try {
            var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
            yield oStore.Open();
        } catch (err) {
            alert('Certificate not found');
            return;
        }
        var all_certs = yield oStore.Certificates;

        if ((yield all_certs.Count) == 0) {
            document.getElementById("boxdiv").style.display = 'flex';
            return;
        }

        var cert;
        var found = 0;
        for (var i = 1; i <= (yield all_certs.Count); i++) {
            try {
                cert = yield all_certs.Item(i);
            }
            catch (ex) {
                alert("Ошибка при перечислении сертификатов: " + cadesplugin.getLastError(ex));
                return;
            }

            var dateObj = new Date();
            try {
                var certDate = new Date((yield cert.ValidToDate));
                var Validator = yield cert.IsValid();
                var IsValid = yield Validator.Result;
                if(dateObj< certDate && (yield cert.HasPrivateKey()) && IsValid) {
                    found = 1;
                    break;
                }
                else {
                    continue;
                }
            }
            catch (ex) {
                alert("Ошибка при получении свойства SubjectName: " + cadesplugin.getLastError(ex));
            }
        }

        if (found == 0) {
            document.getElementById("boxdiv").style.display = 'flex';
            return;
        }

        var dataToSign = document.getElementById("DataToSignTxtBox").value;
        var SignatureFieldTitle = document.getElementsByName("SignatureTitle");
        var Signature;
        try
        {
            FillCertInfo_Async(cert);
            var errormes = "";
            try {
                var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
            } catch (err) {
                errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
                throw errormes;
            }
            if (oSigner) {
                yield oSigner.propset_Certificate(cert);
            }
            else {
                errormes = "Failed to create CAdESCOM.CPSigner";
                throw errormes;
            }

            var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
            var CADES_BES = 1;

            if (dataToSign) {
                // Данные на подпись ввели
                yield oSignedData.propset_Content(dataToSign);
            }
            yield oSigner.propset_Options(1); //CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
            try {
                Signature = yield oSignedData.SignCades(oSigner, CADES_BES);
            }
            catch (err) {
                errormes = "Не удалось создать подпись из-за ошибки: " + cadesplugin.getLastError(err);
                throw errormes;
            }
            document.getElementById("SignatureTxtBox").innerHTML = escapeHtml(Signature);
            SignatureFieldTitle[0].innerHTML = "Подпись сформирована успешно:";
        }
        catch(err)
        {
            SignatureFieldTitle[0].innerHTML = "Возникла ошибка:";
            document.getElementById("SignatureTxtBox").innerHTML = escapeHtml(err);
        }
    }); //cadesplugin.async_spawn
}

function VerifyCadesBES_Async(sSignedMessage, data, flag) {
    return new Promise(function (resolve, reject) {
        var dataInBase64 = "The value to sign";
        if(typeof(data) != 'undefined')
        {
            dataInBase64 = Base64.encode(data);
        }else {
            dataInBase64 = Base64.encode(dataInBase64);
        }
        //console.debug('VerifyCadesBES_Async');
        //console.debug('  data:', data);
        //console.debug('  dataInBase64:', dataInBase64);

        VerifyCadesBES_B64_Async(sSignedMessage, dataInBase64, flag).then(resolve, reject);
    });
}

function VerifyCadesBES_File_Async(sSignedMessage, file, flag) {
    return new Promise(function (resolve, reject) {
        cadesplugin.async_spawn(function*(arg) {
            //console.debug('file', file);
            var dataInBase64 = yield fileToBase64(file);
            //console.debug('dataInBase64', dataInBase64);
            let idx = dataInBase64.indexOf(',');
            dataInBase64 = dataInBase64.substring(idx+1);

            VerifyCadesBES_B64_Async(sSignedMessage, dataInBase64, flag).then(resolve, reject);
                
        }); //cadesplugin.async_spawn
    });
}

function VerifyCadesBES_File2_Async(sSignedMessage, file, flag) {
    let sign = sSignedMessage;
    return new Promise(function (resolve, reject) {
        cadesplugin.then(function () {
        //cadesplugin.async_spawn(function*(arg) {
            let sign2 = sign;
            // var req = new XMLHttpRequest();
            // req.open("GET", url, true);
            // req.responseType = "blob";
            // req.onload = event => {
            //     console.debug('file '+url+' downloaded')
            //     let blob = req.response;
            //     //console.debug('blob ', blob)
            //     let reader = new FileReader();
            //     reader.readAsDataURL(blob);
            //     reader.onload = () => {
            //         console.debug('blob from '+url+' readed')
            //         let dataInBase64 = reader.result;
            //         //console.debug('dataInBase64', dataInBase64);
            //         let idx = dataInBase64.indexOf(',');
            //         dataInBase64 = dataInBase64.substring(idx+1);

            //         VerifyCadesBES_B64_Async(sign2, dataInBase64, flag).then(resolve, reject);
            //     }
            //     reader.onerror = (err) => {
            //         console.debug('error:',err)
            //     }
            // };

            //req.send();

            let blob = new Blob([file]);

            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
                console.debug('blob from file readed')
                let dataInBase64 = reader.result;
                //console.debug('dataInBase64', dataInBase64);
                let idx = dataInBase64.indexOf(',');
                dataInBase64 = dataInBase64.substring(idx+1);

                //VerifyCadesBES_B64_Async(sign2, dataInBase64, flag).then(resolve, reject);
                let promises = [];
                for(let i=0;i<sign2.length;i++){
                    let idx = i;
                    promises[idx] = VerifyCadesBES_B64_Async(sign2[idx], dataInBase64, flag);
                }
                Promise.all(promises).then(resolve, reject);
            }
            reader.onerror = (err) => {
                console.debug('error:',err)
            }

            // //console.debug('file', file);
            // let b64 = Buffer.from(file, 'base64');
            // var dataInBase64 = yield file2ToBase64(b64);
            // //var dataInBase64 = Base64.encode(file);
            // console.debug('dataInBase64', dataInBase64);
            // let idx = dataInBase64.indexOf(',');
            // dataInBase64 = dataInBase64.substring(idx+1);

            // VerifyCadesBES_B64_Async(sSignedMessage, dataInBase64, flag).then(resolve, reject);
                
        //}); //cadesplugin.async_spawn
        });
    });
}

function VerifyCadesBES_B64_Async(sSignedMessage, dataInBase64, flag) {
    return new Promise(function (resolve, reject) {
        console.debug('VerifyCadesBES_B64_Async');
        //console.debug('  sSignedMessage:', sSignedMessage);
        //console.debug('  dataInBase64:', dataInBase64);

        cadesplugin.then(function () {
            cadesplugin.async_spawn(function* (args) {
                var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                try {
                    // Значение свойства ContentEncoding должно быть задано
                    // до заполнения свойства Content
                    yield oSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
                    yield oSignedData.propset_Content(dataInBase64);
                    yield oSignedData.VerifyCades(sSignedMessage, cadesplugin.CADESCOM_CADES_BES, flag);
                } 
                catch (err) {
                    var e = cadesplugin.getLastError(err);
                    //alert("Failed to verify signature. Error: " + e);
                    return args[1](e);
                }
                var oSigners = yield oSignedData.Signers;
                var oSigner = yield oSigners.Item(1);
                var signingTime = yield oSigner.SigningTime;
                var certificate = yield oSigner.Certificate;
                var certname = yield certificate.SubjectName;
                var certid = yield certificate.Id;
                var ValidToDate = new Date((yield certificate.ValidToDate));
                var ValidFromDate = new Date((yield certificate.ValidFromDate));
                var pubKey = yield certificate.PublicKey();
                var algo = yield pubKey.Algorithm;
                var fAlgoName = yield algo.FriendlyName;
                var id = yield certificate.SerialNumber;
                return args[0]({signer:certname, time: signingTime, certificate: {ValidToDate, ValidFromDate, fAlgoName, id}});
                //return args[0]();
            }, resolve, reject);
        });
    });
}

export function GetSignatureInfo_Async(sSignedMessage, flag) {
    console.debug('GetSignatureInfo_Async');
    //console.debug('sSignedMessage: ', sSignedMessage);
    return new Promise(function (resolve, reject) {
        cadesplugin.then(function () {
                cadesplugin.async_spawn(function* (args) {
                    //console.debug('GetSignatureInfo_Async spawn');
                    //console.debug('sSignedMessage: ', sSignedMessage);
                    var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                    try {
                        yield oSignedData.VerifyCades(sSignedMessage, cadesplugin.CADESCOM_CADES_BES, flag);
                    } 
                    catch (err) {
                        var e = cadesplugin.getLastError(err);
                        //alert("Failed to verify signature. Error: " + e);
                        return args[1](e);
                    }
                    var oSigners = yield oSignedData.Signers;
                    var oSigner = yield oSigners.Item(1);
                    var signingTime = yield oSigner.SigningTime;
                    var certificate = yield oSigner.Certificate;
                    var certname = yield certificate.SubjectName;
                    return args[0]({signer:certname, time: signingTime});
                }, resolve, reject);
            }
        );
    });
}

function GetHash_Async(dataInBase64){
    return new Promise(function (resolve, reject) {
        cadesplugin.async_spawn(function* (args) {
            // Создаем объект CAdESCOM.HashedData
            var oHashedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.HashedData");

            // Алгоритм хэширования нужно указать до того, как будут переданы данные
            yield oHashedData.propset_Algorithm(cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411);

            // Указываем кодировку данных
            // Кодировка должна быть указана до того, как будут переданы сами данные
            yield oHashedData.propset_DataEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);

            // Передаем данные
            yield oHashedData.Hash(dataInBase64);

            // Получаем хэш-значение
            var sHashValue = yield oHashedData.Value;
            // Это значение будет совпадать с вычисленным при помощи, например,
            // утилиты cryptcp от тех же исходных _бинарных_ данных.

            return args[0](sHashValue);
        }, resolve, reject);
    });
}

function ConvertDate(date) {
    switch (navigator.appName) {
        case "Microsoft Internet Explorer":
            return date.getVarDate();
        default:
            return date;
    }
}

export function run(oCertificate) {
    cadesplugin.async_spawn(function* (args) {
        
        // Предварительно вычисленное хэш-значение исходных данных
        // Хэш-значение должно быть представлено в виде строки шестнадцатеричных цифр,
        // группами по 2 цифры на байт, с пробелами или без пробелов.
        // Например, хэш-значение в таком формате возвращают объекты
        // CAPICOM.HashedData и CADESCOM.HashedData.
        var sHashValue = "92CD0CB36B10BFB88DEF198F80B7D2E667DBDA064D346405C25EEF77FFE375D7";

        // Алгоритм хэширования, при помощи которого было вычислено хэш-значение
        // Полный список поддерживаемых алгоритмов указан в перечислении CADESCOM_HASH_ALGORITHM
        var hashAlg = cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411; // ГОСТ Р 34.11-94

        // Создаем объект CAdESCOM.HashedData
        var oHashedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.HashedData");

        // Инициализируем объект заранее вычисленным хэш-значением
        // Алгоритм хэширования нужно указать до того, как будет передано хэш-значение
        yield oHashedData.propset_Algorithm(hashAlg);
        yield oHashedData.SetHashValue(sHashValue);

        // Создаем подписанное сообщение
        // Такая подпись должна проверяться в КриптоАРМ и cryptcp.exe
        // Создаем объект CAdESCOM.CPSigner
        var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
        yield oSigner.propset_Certificate(oCertificate);
        yield oSigner.propset_CheckCertificate(true);

        // Создаем объект CAdESCOM.CadesSignedData
        var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");

        var sSignedMessage = "";

        // Вычисляем значение подписи
        try {
            sSignedMessage = yield oSignedData.SignHash(oHashedData, oSigner, cadesplugin.CADESCOM_CADES_BES);
        } catch (err) {
            alert("Failed to create signature. Error: " + cadesplugin.getLastError(err));
            return;
        }

        document.getElementById("signature").innerHTML = sSignedMessage;

        // Создаем объект CAdESCOM.CadesSignedData
        var oSignedData2 = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");

        // Проверяем подпись
        try {
            yield oSignedData2.VerifyHash(oHashedData, sSignedMessage, cadesplugin.CADESCOM_CADES_BES);
            alert("Signature verified");
        } catch (err) {
            alert("Failed to verify signature. Error: " + cadesplugin.getLastError(err));
            return;
        }
    });
}

export function run2(oCertificate) {
    var data = "Test plain file content.";
    var dataInBase64 = "VGVzdCBwbGFpbiBmaWxlIGNvbnRlbnQu";
    //SignCadesBES_Async2(oCertificate, data, false).then(
    SignCadesBES_B64_Async(oCertificate, dataInBase64, true).then(
        function (signedMessage) {
            GetSignatureInfo_Async(signedMessage, false).then(
                function (certinfo) {
                    alert("Signature verified: " + certinfo.signer + ", " + certinfo.time);
                },
                function (err) {
                    console.error('17', err);
                });
        },
        function (err) {
            console.error('16', err);
        }
    );
}

export function run3(oCertificate) {
    var data = 'Test plain file content.';
    var dataInBase64 = "VGVzdCBwbGFpbiBmaWxlIGNvbnRlbnQu";
    SignCadesBES_B64_Async(oCertificate, dataInBase64, true).then(
        function (signedMessage) {
            //console.debug('Created: ', signedMessage)
            let a = 'MIIEsQYJKoZIhvcNAQcCoIIEojCCBJ4CAQExDjAMBggqhQMHAQECAwUAMCcGCSqGSIb3DQEHAaAa\r\nBBhUZXN0IHBsYWluIGZpbGUgY29udGVudC6gggJnMIICYzCCAc+gAwIBAgIIUD19NjiB5hIwCgYI\r\nKoUDBwEBAwMwHTELMAkGA1UEBhMCUlUxDjAMBgNVBAMTBWJhbmQ0MB4XDTIzMDYwODEyMDA0OFoX\r\nDTI0MDYwODEyMDA0OFowHTELMAkGA1UEBhMCUlUxDjAMBgNVBAMTBWJhbmQ0MIGqMCEGCCqFAwcB\r\nAQECMBUGCSqFAwcBAgECAQYIKoUDBwEBAgMDgYQABIGAmbTPBtTySKsyph82W6lEMA0kq3lLAW9E\r\n9S+MqaejTvU4fAD7INY6oC6H+XkGEH5ro4Q8zJaZkl4EtCUI9rbkmjcasKJlU30KEdxVdxvFll/F\r\nMypBKI3NXimLg/yczvgy7JcVAh4KLL/nT1DSxZh/OwNLi0vJ12j+eMtAjx9LKIKjgaYwgaMwDgYD\r\nVR0PAQH/BAQDAgLcMB0GA1UdJQQWMBQGCCsGAQUFBwMCBggrBgEFBQcDBDAdBgNVHQ4EFgQUCApC\r\nGxU7Mzk2vaDox6/F2g3eflMwQgYDVR0jBDswOYAUCApCGxU7Mzk2vaDox6/F2g3eflOhIaQfMB0x\r\nCzAJBgNVBAYTAlJVMQ4wDAYDVQQDEwViYW5kNDAPBgNVHRMECDAGAQH/AgEBMAoGCCqFAwcBAQMD\r\nA4GBAH54zQeKmLR2EOMB6wC/Qk/VfCHu2DrFtZhCyQeiQIkQlSrFpq1dM0IGvPzRohDHcHsJS60z\r\nSwYYWRzX3dt+1bCIzp4UmKwkMCWCUgFadi/etmtvvFEyAZCrjIvhlv8TBGKNrbPi7h6BYn94QlMy\r\nzEQTDpIdskrUEmGKcZPiGb8JMYIB8zCCAe8CAQEwKTAdMQswCQYDVQQGEwJSVTEOMAwGA1UEAxMF\r\nYmFuZDQCCFA9fTY4geYSMAwGCCqFAwcBAQIDBQCgggEgMBgGCSqGSIb3DQEJAzELBgkqhkiG9w0B\r\nBwEwHAYJKoZIhvcNAQkFMQ8XDTIzMDYyODEyMjU1NlowTwYJKoZIhvcNAQkEMUIEQIKsgD5ZoXHn\r\nsTqAogllR/4yiv1DgKLMwE2iK9qiGSUzWvOSKb1WlWqbOnMkH45+tLEkxs4Rxuen7//w0liNZfkw\r\ngZQGCyqGSIb3DQEJEAIvMYGEMIGBMH8wfTAKBggqhQMHAQECAwRAbnvBXc15fX8F6HxXg3fwhXpt\r\nzDrZOneoM64gRo4UMze+WFB4gUeHBH+Scd7y9RH8TK1vNqr2pFHFukIIDsFpnTAtMCGkHzAdMQsw\r\nCQYDVQQGEwJSVTEOMAwGA1UEAxMFYmFuZDQCCFA9fTY4geYSMAoGCCqFAwcBAQECBIGASoN4DoAi\r\nGH33astyf4LVrl6LFT0VcYA/COPx+6UZGcFeOjdMOHVu/EnVreGH7FamFdGSXAb0mfrV0OiNOYov\r\ny8tHxZ6F+xS1Q5rv8jFOqPGyM3clZAX4fA4goHEJaDRiQWBt+SdKaLCkwjU4/e/UUJnbBEiYC31E\r\nC/QxMW2hvXE='
            let b = 'MIIElQYJKoZIhvcNAQcCoIIEhjCCBIICAQExDjAMBggqhQMHAQECAwUAMAsGCSqGSIb3DQEHAaCCAmcwggJjMIIBz6ADAgECAghQPX02OIHmEjAKBggqhQMHAQEDAzAdMQswCQYDVQQGEwJSVTEOMAwGA1UEAxMFYmFuZDQwHhcNMjMwNjA4MTIwMDQ4WhcNMjQwNjA4MTIwMDQ4WjAdMQswCQYDVQQGEwJSVTEOMAwGA1UEAxMFYmFuZDQwgaowIQYIKoUDBwEBAQIwFQYJKoUDBwECAQIBBggqhQMHAQECAwOBhAAEgYCZtM8G1PJIqzKmHzZbqUQwDSSreUsBb0T1L4ypp6NO9Th8APsg1jqgLof5eQYQfmujhDzMlpmSXgS0JQj2tuSaNxqwomVTfQoR3FV3G8WWX8UzKkEojc1eKYuD/JzO+DLslxUCHgosv+dPUNLFmH87A0uLS8nXaP54y0CPH0sogqOBpjCBozAOBgNVHQ8BAf8EBAMCAtwwHQYDVR0lBBYwFAYIKwYBBQUHAwIGCCsGAQUFBwMEMB0GA1UdDgQWBBQICkIbFTszOTa9oOjHr8XaDd5+UzBCBgNVHSMEOzA5gBQICkIbFTszOTa9oOjHr8XaDd5+U6EhpB8wHTELMAkGA1UEBhMCUlUxDjAMBgNVBAMTBWJhbmQ0MA8GA1UdEwQIMAYBAf8CAQEwCgYIKoUDBwEBAwMDgYEAfnjNB4qYtHYQ4wHrAL9CT9V8Ie7YOsW1mELJB6JAiRCVKsWmrV0zQga8/NGiEMdwewlLrTNLBhhZHNfd237VsIjOnhSYrCQwJYJSAVp2L962a2+8UTIBkKuMi+GW/xMEYo2ts+LuHoFif3hCUzLMRBMOkh2yStQSYYpxk+IZvwkxggHzMIIB7wIBATApMB0xCzAJBgNVBAYTAlJVMQ4wDAYDVQQDEwViYW5kNAIIUD19NjiB5hIwDAYIKoUDBwEBAgMFAKCCASAwGAYJKoZIhvcNAQkDMQsGCSqGSIb3DQEHATAcBgkqhkiG9w0BCQUxDxcNMjMwNjI4MTIzNjU1WjBPBgkqhkiG9w0BCQQxQgRAgqyAPlmhceexOoCiCWVH/jKK/UOAoszATaIr2qIZJTNa85IpvVaVaps6cyQfjn60sSTGzhHG56fv//DSWI1l+TCBlAYLKoZIhvcNAQkQAi8xgYQwgYEwfzB9MAoGCCqFAwcBAQIDBEBue8FdzXl9fwXofFeDd/CFem3MOtk6d6gzriBGjhQzN75YUHiBR4cEf5Jx3vL1EfxMrW82qvakUcW6QggOwWmdMC0wIaQfMB0xCzAJBgNVBAYTAlJVMQ4wDAYDVQQDEwViYW5kNAIIUD19NjiB5hIwCgYIKoUDBwEBAQIEgYAXcxpaY79SWU8/6O7lKQCRWSwJ3xkF+rpZbDqjTtWQfKo/ixS/nI2l0kiHKWv3odRCYQVeX3w4JuNAOZZH7m3tpoI32B6kkPtZQC0PtUCNfda2fVXZ1RppvxyXc7O7BEgDDhLhBPao6ec7pAtZ57RtPjywPmtlYHC2iwAeSK0Llw=='
            let c = 'MIIEsQYJKoZIhvcNAQcCoIIEojCCBJ4CAQExDjAMBggqhQMHAQECAwUAMCcGCSqGSIb3DQEHAaAaBBhUZXN0IHBsYWluIGZpbGUgY29udGVudC6gggJnMIICYzCCAc+gAwIBAgIIUD19NjiB5hIwCgYIKoUDBwEBAwMwHTELMAkGA1UEBhMCUlUxDjAMBgNVBAMTBWJhbmQ0MB4XDTIzMDYwODEyMDA0OFoXDTI0MDYwODEyMDA0OFowHTELMAkGA1UEBhMCUlUxDjAMBgNVBAMTBWJhbmQ0MIGqMCEGCCqFAwcBAQECMBUGCSqFAwcBAgECAQYIKoUDBwEBAgMDgYQABIGAmbTPBtTySKsyph82W6lEMA0kq3lLAW9E9S+MqaejTvU4fAD7INY6oC6H+XkGEH5ro4Q8zJaZkl4EtCUI9rbkmjcasKJlU30KEdxVdxvFll/FMypBKI3NXimLg/yczvgy7JcVAh4KLL/nT1DSxZh/OwNLi0vJ12j+eMtAjx9LKIKjgaYwgaMwDgYDVR0PAQH/BAQDAgLcMB0GA1UdJQQWMBQGCCsGAQUFBwMCBggrBgEFBQcDBDAdBgNVHQ4EFgQUCApCGxU7Mzk2vaDox6/F2g3eflMwQgYDVR0jBDswOYAUCApCGxU7Mzk2vaDox6/F2g3eflOhIaQfMB0xCzAJBgNVBAYTAlJVMQ4wDAYDVQQDEwViYW5kNDAPBgNVHRMECDAGAQH/AgEBMAoGCCqFAwcBAQMDA4GBAH54zQeKmLR2EOMB6wC/Qk/VfCHu2DrFtZhCyQeiQIkQlSrFpq1dM0IGvPzRohDHcHsJS60zSwYYWRzX3dt+1bCIzp4UmKwkMCWCUgFadi/etmtvvFEyAZCrjIvhlv8TBGKNrbPi7h6BYn94QlMyzEQTDpIdskrUEmGKcZPiGb8JMYIB8zCCAe8CAQEwKTAdMQswCQYDVQQGEwJSVTEOMAwGA1UEAxMFYmFuZDQCCFA9fTY4geYSMAwGCCqFAwcBAQIDBQCgggEgMBgGCSqGSIb3DQEJAzELBgkqhkiG9w0BBwEwHAYJKoZIhvcNAQkFMQ8XDTIzMDYyODEyMjU1NlowTwYJKoZIhvcNAQkEMUIEQIKsgD5ZoXHnsTqAogllR/4yiv1DgKLMwE2iK9qiGSUzWvOSKb1WlWqbOnMkH45+tLEkxs4Rxuen7//w0liNZfkwgZQGCyqGSIb3DQEJEAIvMYGEMIGBMH8wfTAKBggqhQMHAQECAwRAbnvBXc15fX8F6HxXg3fwhXptzDrZOneoM64gRo4UMze+WFB4gUeHBH+Scd7y9RH8TK1vNqr2pFHFukIIDsFpnTAtMCGkHzAdMQswCQYDVQQGEwJSVTEOMAwGA1UEAxMFYmFuZDQCCFA9fTY4geYSMAoGCCqFAwcBAQECBIGASoN4DoAiGH33astyf4LVrl6LFT0VcYA/COPx+6UZGcFeOjdMOHVu/EnVreGH7FamFdGSXAb0mfrV0OiNOYovy8tHxZ6F+xS1Q5rv8jFOqPGyM3clZAX4fA4goHEJaDRiQWBt+SdKaLCkwjU4/e/UUJnbBEiYC31EC/QxMW2hvXE='
            if(true){
            VerifyCadesBES_B64_Async(signedMessage, dataInBase64, true).then(
                function (certinfo) {
                    alert("Signature verified: " + certinfo.signer + ", " + certinfo.time);
                    //alert("Signature verified");
                },
                function (err) {
                    console.error('18', err);
                });
            }
            else{
                VerifyCadesBES_Async(signedMessage, data, false).then(
                    function (certinfo) {
                        alert("Signature verified");
                    },
                    function (err) {
                        console.error('17', err);
                    });
            }
        },
        function (err) {
            console.error('16', err);
        }
    );
}

export function run4(data1) {
    let url = 'https://crapproveapi.nitrosbase.com/api/ProjectFiles?id=2583';
    var req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function (event) {
        var blob = req.response;
        //console.debug('blob', blob);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
            console.debug('reader.result', reader.result);
        }
        reader.onerror = (err) => {
            console.debug('error:',err)
        }
    };

    req.send();
}
// games.mindskills.online/g/morelax

function SignCreate(oCertificate, dataInBase64) {
    return new Promise(function (resolve, reject) {
        cadesplugin.async_spawn(function* (args) {
            var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
            yield oSigner.propset_Certificate(oCertificate);
            yield oSigner.propset_CheckCertificate(true);

            var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
            yield oSignedData.propset_ContentEncoding(1);
            yield oSignedData.propset_Content(dataInBase64);

            var sSignedMessage = "";
            try {
                sSignedMessage = yield oSignedData.SignCades(oSigner, 1, true);
            } catch (err) {
                e = cadesplugin.getLastError(err);
                alert("Failed to create signature. Error: " + e);
                return args[1](e);
            }

            return args[0](sSignedMessage);
        }, resolve, reject);
    });
}

function Verify(sSignedMessage, dataInBase64) {
    return new Promise(function (resolve, reject) {
        cadesplugin.async_spawn(function* (args) {
            var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
            try {
                yield oSignedData.propset_ContentEncoding(1);
                yield oSignedData.propset_Content(dataInBase64);
                yield oSignedData.VerifyCades(sSignedMessage, 1, true);
            } 
            catch (err) {
                var e = cadesplugin.getLastError(err);
                alert("Failed to verify signature. Error: " + e);
                return args[1](e);
            }
            return args[0]();
        }, resolve, reject);
    });
}

async function AddProperties(oSigner, cert){
    cadesplugin.async_spawn(function*() {
        var oSigningTimeAttr = yield cadesplugin.CreateObjectAsync("CADESCOM.CPAttribute");
        yield oSigningTimeAttr.propset_Name(cadesplugin.CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME);
        var oTimeNow = new Date();
        yield oSigningTimeAttr.propset_Value(ConvertDate(oTimeNow));

        var oDocumentNameAttr = yield cadesplugin.CreateObjectAsync("CADESCOM.CPAttribute");
        yield oDocumentNameAttr.propset_Name(cadesplugin.CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME);
        let cname = yield cert.SubjectName;
        yield oDocumentNameAttr.propset_Value(cname);

        var attr = yield oSigner.AuthenticatedAttributes2;
        yield attr.Add(oSigningTimeAttr);
        yield attr.Add(oDocumentNameAttr);
    });
}

function SignCadesBES_Async(certificate, data) {
    return new Promise(function (resolve, reject) {
        cadesplugin.async_spawn(function*() {
            
            var dataToSign = "The value to sign";
            if(typeof(data) != 'undefined')
            {
                dataToSign = Base64.encode(data);
            }else {
                dataToSign = Base64.encode(dataToSign);
            }
            if(!dataToSign){
                reject("No data to sign");
                return;
            }
            console.debug('dataToSign:', dataToSign);
            
            var Signature;
            try
            {
                var errormes = "";
                try {
                    var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
                } catch (err) {
                    errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
                    throw errormes;
                }


                if (oSigner) {
                    yield oSigner.propset_Certificate(certificate);
                    yield oSigner.propset_CheckCertificate(true);
                }
                else {
                    errormes = "Failed to create CAdESCOM.CPSigner";
                    throw errormes;
                }

                AddProperties(oSigner, certificate);


                var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");

                yield oSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY); //
                yield oSignedData.propset_Content(dataToSign);

                yield oSigner.propset_Options(cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN); //

                try {
                    Signature = yield oSignedData.SignCades(oSigner, cadesplugin.CADESCOM_CADES_BES);
                }
                catch (err) {
                    errormes = "Не удалось создать подпись из-за ошибки: " + cadesplugin.getLastError(err);
                    throw errormes;
                }
                
                certsinfo.signature = {value: Signature, status: 'Подпись сформирована успешно'}
                resolve(Signature);
            }
            catch(err)
            {
                certsinfo.signature = {value: escapeHtml(err), status: 'Возникла ошибка'}
                reject(escapeHtml(err));
            }
        }); //cadesplugin.async_spawn
    });
}

function SignCadesBES_B64_Async(certificate, dataInBase64, flag) {
    return new Promise(function (resolve, reject) {
        //console.debug('SignCadesBES_B64_Async', dataInBase64, flag);
        cadesplugin.async_spawn(function*() {
            if (!certificate) {
                reject("Select certificate");
                return;
            }
            
            var Signature;
            try
            {
                var errormes = "";
                try {
                    var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
                } catch (err) {
                    errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
                    throw errormes;
                }

                if (oSigner) {
                    yield oSigner.propset_Certificate(certificate);
                    yield oSigner.propset_CheckCertificate(true);
                }
                else {
                    errormes = "Failed to create CAdESCOM.CPSigner";
                    throw errormes;
                }

                AddProperties(oSigner, certificate);


                var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                yield oSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY); //
                yield oSignedData.propset_Content(dataInBase64);

                yield oSigner.propset_Options(cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN); //

                try {
                    Signature = yield oSignedData.SignCades(oSigner, cadesplugin.CADESCOM_CADES_BES, flag);
                }
                catch (err) {
                    errormes = "Не удалось создать подпись из-за ошибки: " + cadesplugin.getLastError(err);
                    throw errormes;
                }
                
                //console.debug('  signature:', Signature);
                certsinfo.signature = {value: Signature, status: 'Подпись сформирована успешно'}
                resolve(Signature);
            }
            catch(err)
            {
                certsinfo.signature = {value: escapeHtml(err), status: 'Возникла ошибка'}
                reject(escapeHtml(err));
            }
        }); //cadesplugin.async_spawn
    });
}

const fileToBase64 = file => new Promise((resolve, reject) => {
    console.debug('fileToBase64')
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});
const file2ToBase64 = file => new Promise((resolve, reject) => {
    console.debug('fileToBase64')
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

function SignCadesBES_File_Async(certificate, file, flag) {
    return new Promise(function (resolve, reject) {
        //console.debug('SignCadesBES_Async_File', file);
        cadesplugin.async_spawn(function*(arg) {
            
            var dataToSign = yield fileToBase64(file); // fileContent - объявлен в Code.js
            //console.debug('Base64 from file:', dataToSign);
            let idx = dataToSign.indexOf(',');
            dataToSign = dataToSign.substring(idx+1);

            SignCadesBES_B64_Async(certificate, dataToSign, flag).then(resolve, reject);
                
        }); //cadesplugin.async_spawn
    });
}

function SignCadesEnhanced_Async(certListBoxId, sign_type) {
    cadesplugin.async_spawn(function*(arg) {
        var e = document.getElementById(arg[0]);
        var selectedCertID = e.selectedIndex;
        if (selectedCertID == -1) {
            alert("Select certificate");
            return;
        }
        var certificate = global_selectbox_container[selectedCertID];

        var dataToSign = document.getElementById("DataToSignTxtBox").value;
        var SignatureFieldTitle = document.getElementsByName("SignatureTitle");
        var Signature;
        try
        {
            //FillCertInfo_Async(certificate);
            var errormes = "";
            try {
                var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
            } catch (err) {
                errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
                throw errormes;
            }
            if (oSigner) {
                yield oSigner.propset_Certificate(certificate);
            }
            else {
                errormes = "Failed to create CAdESCOM.CPSigner";
                throw errormes;
            }

            var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
            var tspService = document.getElementById("TSPServiceTxtBox").value ;

            if (dataToSign) {
                // Данные на подпись ввели
                yield oSignedData.propset_Content(dataToSign);
                yield oSigner.propset_Options(1); //CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
            }
            yield oSigner.propset_TSAAddress(tspService);
            try {
                Signature = yield oSignedData.SignCades(oSigner, sign_type);
            }
            catch (err) {
                errormes = "Не удалось создать подпись из-за ошибки: " + cadesplugin.getLastError(err);
                throw errormes;
            }
            document.getElementById("SignatureTxtBox").innerHTML = escapeHtml(Signature);
            SignatureFieldTitle[0].innerHTML = "Подпись сформирована успешно:";
        }
        catch(err)
        {
            SignatureFieldTitle[0].innerHTML = "Возникла ошибка:";
            document.getElementById("SignatureTxtBox").innerHTML = escapeHtml(err);
        }
    }, certListBoxId); //cadesplugin.async_spawn
}

function FillCertInfo_Async(certificate, certsinfo, isFromContainer)
{
    var BoxId;
    var field_prefix;
    if(typeof(certBoxId) == 'undefined' || certBoxId == "CertListBox")
    {
        BoxId = 'cert_info';
        field_prefix = '';
    }else if (certBoxId == "CertListBox1") {
        BoxId = 'cert_info1';
        field_prefix = 'cert_info1';
    } else if (certBoxId == "CertListBox2") {
        BoxId = 'cert_info2';
        field_prefix = 'cert_info2';
    } else {
        BoxId = certBoxId;
        field_prefix = certBoxId;
    }
    cadesplugin.async_spawn (function*(args) {
        certsinfo.selected = {cert: certificate};
        var Adjust = new CertificateAdjuster();

        certsinfo.selected.display = '';
        certsinfo.selected.subject = "Владелец: <b>" + escapeHtml(Adjust.GetCertName(yield args[0].SubjectName)) + "<b>";
        certsinfo.selected.issuer = "Издатель: <b>" + escapeHtml(Adjust.GetIssuer(yield args[0].IssuerName)) + "<b>";

        var ValidToDate = new Date((yield args[0].ValidToDate));
        var ValidFromDate = new Date((yield args[0].ValidFromDate));

        certsinfo.selected.from = "Выдан: <b>" + escapeHtml(Adjust.GetCertDate(ValidFromDate)) + " UTC<b>";
        certsinfo.selected.till = "Действителен до: <b>" + escapeHtml(Adjust.GetCertDate(ValidToDate)) + " UTC<b>";

        var hasPrivateKey = yield args[0].HasPrivateKey();
        var Now = new Date();

        var pubKey = yield args[0].PublicKey();
        var algo = yield pubKey.Algorithm;
        var fAlgoName = yield algo.FriendlyName;
        certsinfo.selected.algorithm = "Алгоритм ключа: <b>" + escapeHtml(fAlgoName) + "<b>";
        if (hasPrivateKey) {
            var oPrivateKey = yield args[0].PrivateKey;
            var sProviderName = yield oPrivateKey.ProviderName;
            certsinfo.selected.provname = "Криптопровайдер: <b>" + escapeHtml(sProviderName) + "<b>";
            try {
                var sPrivateKeyLink = yield oPrivateKey.UniqueContainerName;
                certsinfo.selected.privateKeyLink = "Ссылка на закрытый ключ: <b>" + escapeHtml(sPrivateKeyLink) + "<b>";
            } catch (e) {
                certsinfo.selected.privateKeyLink = "Ссылка на закрытый ключ: <b>" + escapeHtml(e.message) + "<b>";
            }
        } else {
            certsinfo.selected.provname = "Криптопровайдер:<b>";
            certsinfo.selected.privateKeyLink = "Ссылка на закрытый ключ:<b>";
        }
        var certIsValid = false;
        if(Now < ValidFromDate) {
            certsinfo.selected.status = "Статус: <b class=\"error\">Срок действия не наступил</b>";
        } else if( Now > ValidToDate){
            certsinfo.selected.status = "Статус: <b class=\"error\">Срок действия истек</b>";
        } else if( !hasPrivateKey ){
            certsinfo.selected.status = "Статус: <b class=\"error\">Нет привязки к закрытому ключу</b>";
        } else {
            //если попадется сертификат с неизвестным алгоритмом
            //тут будет исключение. В таком сертификате просто пропускаем такое поле
            try {
                var Validator = yield args[0].IsValid();
                certIsValid = yield Validator.Result;
                console.warn('cert', args[0]);
            } catch(e) {
                certIsValid = false;
            }
            if(certIsValid){
                certsinfo.selected.status = "Статус: <b> Действителен<b>";
            } else {
                certsinfo.selected.status = "Статус: <b class=\"error\">Ошибка при проверке цепочки сертификатов. Возможно на компьютере не установлены сертификаты УЦ, выдавшего ваш сертификат</b>";
            }
        }

        if(args[3])
        {
            if (certIsValid) {
                certsinfo.selected.location = "Установлен в хранилище: <span><b class=\"warning\">Нет. При такой конфигурации не все приложения и порталы могут работать</b><br/><a style=\"cursor: pointer\" onclick=\"Common_InstallCertificate('"+ escapeHtml(certBoxId) +"');\">Установить</a></span>";
            } else {
                certsinfo.selected.location = "Установлен в хранилище: <b>Нет</b>";
            }
        } else {
            certsinfo.selected.location = "Установлен в хранилище: <b>Да</b>";
        }

    }, certificate, BoxId, field_prefix, isFromContainer);//cadesplugin.async_spawn
}

function Encrypt_Async() {
    cadesplugin.async_spawn (function*() {
        document.getElementById("DataEncryptedIV1").innerHTML = "";
        document.getElementById("DataEncryptedIV2").innerHTML = "";
        document.getElementById("DataEncryptedDiversData1").innerHTML = "";
        document.getElementById("DataEncryptedDiversData2").innerHTML = "";
        document.getElementById("DataEncryptedBox1").innerHTML = "";
        document.getElementById("DataEncryptedBox2").innerHTML = "";
        document.getElementById("DataEncryptedKey1").innerHTML = "";
        document.getElementById("DataEncryptedKey2").innerHTML = "";
        document.getElementById("DataDecryptedBox1").innerHTML = "";
        document.getElementById("DataDecryptedBox2").innerHTML = "";

        //Get First certificate
        var e = document.getElementById('CertListBox1');
        if (e.selectedIndex == -1) {
            alert("Select first certificate");
            return;
        }
        var selectedCertID = e[e.selectedIndex].value;
        var certificate1 = global_selectbox_container[selectedCertID];

        //Get second Certificate
        var e = document.getElementById('CertListBox2');
        if (e.selectedIndex == -1) {
            alert("Select second certificate");
            return;
        }
        var selectedCertID = e[e.selectedIndex].value;
        var certificate2 = global_selectbox_container[selectedCertID];

        var dataToEncr1 = Base64.encode(document.getElementById("DataToEncrTxtBox1").value);
        var dataToEncr2 = Base64.encode(document.getElementById("DataToEncrTxtBox2").value);

        if(dataToEncr1 === "" || dataToEncr2 === "") {
            errormes = "Empty data to encrypt";
            alert(errormes);
            throw errormes;
        }

        try
        {
            var errormes = "";

            try {
                var oSymAlgo = yield cadesplugin.CreateObjectAsync("cadescom.symmetricalgorithm");
            } catch (err) {
                errormes = "Failed to create cadescom.symmetricalgorithm: " + err;
                alert(errormes);
                throw errormes;
            }

            yield oSymAlgo.GenerateKey();

            var oSesKey1 = yield oSymAlgo.DiversifyKey();
            var oSesKey1DiversData = yield oSesKey1.DiversData;
            var oSesKey1IV = yield oSesKey1.IV;
            var EncryptedData1 = yield oSesKey1.Encrypt(dataToEncr1, 1);
            document.getElementById("DataEncryptedDiversData1").innerHTML = escapeHtml(oSesKey1DiversData);
            document.getElementById("DataEncryptedIV1").innerHTML = escapeHtml(oSesKey1IV);
            document.getElementById("DataEncryptedBox1").innerHTML = escapeHtml(EncryptedData1);

            var oSesKey2 = yield oSymAlgo.DiversifyKey();
            var oSesKey2DiversData = yield oSesKey2.DiversData;
            var oSesKey2IV = yield oSesKey2.IV;
            var EncryptedData2 = yield oSesKey2.Encrypt(dataToEncr2, 1);
            document.getElementById("DataEncryptedDiversData2").innerHTML = escapeHtml(oSesKey2DiversData);
            document.getElementById("DataEncryptedIV2").innerHTML = escapeHtml(oSesKey2IV);
            document.getElementById("DataEncryptedBox2").innerHTML = escapeHtml(EncryptedData2);

            var ExportedKey1 = yield oSymAlgo.ExportKey(certificate1);
            document.getElementById("DataEncryptedKey1").innerHTML = escapeHtml(ExportedKey1);

            var ExportedKey2 = yield oSymAlgo.ExportKey(certificate2);
            document.getElementById("DataEncryptedKey2").innerHTML = escapeHtml(ExportedKey2);

            alert("Данные зашифрованы успешно:");
        }
        catch(err)
        {
            alert("Ошибка при шифровании данных:" + err);
            throw("Ошибка при шифровании данных:" + err);
        }
    });//cadesplugin.async_spawn
}

function Decrypt_Async(certListBoxId) {
    cadesplugin.async_spawn (function*(arg) {
        document.getElementById("DataDecryptedBox1").innerHTML = "";
        document.getElementById("DataDecryptedBox2").innerHTML = "";

        var e = document.getElementById(arg[0]);
        var selectedCertID = e[e.selectedIndex].value;
        if (selectedCertID == -1) {
            alert("Select certificate");
            return;
        }

        var certificate = global_selectbox_container[selectedCertID];

        var dataToDecr1 = document.getElementById("DataEncryptedBox1").value;
        var dataToDecr2 = document.getElementById("DataEncryptedBox2").value;
        var field;
        if(certListBoxId == 'CertListBox1')
            field ="DataEncryptedKey1";
        else
            field ="DataEncryptedKey2";

        var EncryptedKey = document.getElementById(field).value;
        try
        {
            FillCertInfo_Async(certificate, 'cert_info_decr');
            var errormes = "";

            try {
                var oSymAlgo = yield cadesplugin.CreateObjectAsync("cadescom.symmetricalgorithm");
            } catch (err) {
                errormes = "Failed to create cadescom.symmetricalgorithm: " + err;
                alert(errormes);
                throw errormes;
            }

            yield oSymAlgo.ImportKey(EncryptedKey, certificate);

            var oSesKey1DiversData = document.getElementById("DataEncryptedDiversData1").value;
            var oSesKey1IV = document.getElementById("DataEncryptedIV1").value;
            yield oSymAlgo.propset_DiversData(oSesKey1DiversData);
            var oSesKey1 = yield oSymAlgo.DiversifyKey();
            yield oSesKey1.propset_IV(oSesKey1IV);
            var EncryptedData1 = yield oSesKey1.Decrypt(dataToDecr1, 1);
            document.getElementById("DataDecryptedBox1").innerHTML = escapeHtml(Base64.decode(EncryptedData1));

            var oSesKey2DiversData = document.getElementById("DataEncryptedDiversData2").value;
            var oSesKey2IV = document.getElementById("DataEncryptedIV2").value;
            yield oSymAlgo.propset_DiversData(oSesKey2DiversData);
            var oSesKey2 = yield oSymAlgo.DiversifyKey();
            yield oSesKey2.propset_IV(oSesKey2IV);
            var EncryptedData2 = yield oSesKey2.Decrypt(dataToDecr2, 1);
            document.getElementById("DataDecryptedBox2").innerHTML = escapeHtml(Base64.decode(EncryptedData2));

            alert("Данные расшифрованы успешно:");
        }
        catch(err)
        {
            alert("Ошибка при шифровании данных:" + err);
            throw("Ошибка при шифровании данных:" + err);
        }
    }, certListBoxId);//cadesplugin.async_spawn
}

function RetrieveCertificate_Async()
{
    cadesplugin.async_spawn (function*(arg) {
        try {
            var PrivateKey = yield cadesplugin.CreateObjectAsync("X509Enrollment.CX509PrivateKey");
        }
        catch (e) {
            alert('Failed to create X509Enrollment.CX509PrivateKey: ' + cadesplugin.getLastError(e));
            return;
        }

        yield PrivateKey.propset_ProviderName("Crypto-Pro GOST R 34.10-2012 Cryptographic Service Provider");
        yield PrivateKey.propset_ProviderType(80);
        yield PrivateKey.propset_KeySpec(1); //XCN_AT_KEYEXCHANGE

        try {
            var CertificateRequestPkcs10 = yield cadesplugin.CreateObjectAsync("X509Enrollment.CX509CertificateRequestPkcs10");
        }
        catch (e) {
            alert('Failed to create X509Enrollment.CX509CertificateRequestPkcs10: ' + cadesplugin.getLastError(e));
            return;
        }

        yield CertificateRequestPkcs10.InitializeFromPrivateKey(0x1, PrivateKey, "");

        try {
            var DistinguishedName = yield cadesplugin.CreateObjectAsync("X509Enrollment.CX500DistinguishedName");
        }
        catch (e) {
            alert('Failed to create X509Enrollment.CX500DistinguishedName: ' + cadesplugin.getLastError(e));
            return;
        }

        var CommonName = "Test Certificate";
        yield DistinguishedName.Encode("CN=\""+CommonName.replace(/"/g, "\"\"")+"\"");

        yield CertificateRequestPkcs10.propset_Subject(DistinguishedName);

        var KeyUsageExtension = yield cadesplugin.CreateObjectAsync("X509Enrollment.CX509ExtensionKeyUsage");
        var CERT_DATA_ENCIPHERMENT_KEY_USAGE = 0x10;
        var CERT_KEY_ENCIPHERMENT_KEY_USAGE = 0x20;
        var CERT_DIGITAL_SIGNATURE_KEY_USAGE = 0x80;
        var CERT_NON_REPUDIATION_KEY_USAGE = 0x40;

        yield KeyUsageExtension.InitializeEncode(
                    CERT_KEY_ENCIPHERMENT_KEY_USAGE |
                    CERT_DATA_ENCIPHERMENT_KEY_USAGE |
                    CERT_DIGITAL_SIGNATURE_KEY_USAGE |
                    CERT_NON_REPUDIATION_KEY_USAGE);

        yield (yield CertificateRequestPkcs10.X509Extensions).Add(KeyUsageExtension);

        try {
            var Enroll = yield cadesplugin.CreateObjectAsync("X509Enrollment.CX509Enrollment");
        }
        catch (e) {
            alert('Failed to create X509Enrollment.CX509Enrollment: ' + cadesplugin.getLastError(e));
            return;
        }
        
        var cert_req;
        try {
            yield Enroll.InitializeFromRequest(CertificateRequestPkcs10);
            cert_req = yield Enroll.CreateRequest(0x1);
        } catch (e) {
            alert('Failed to generate KeyPair or reguest: ' + cadesplugin.getLastError(e));
            return;    
        }

        var params = 'CertRequest=' + encodeURIComponent(cert_req) +
                     '&Mode=' + encodeURIComponent('newreq') +
                     '&TargetStoreFlags=' + encodeURIComponent('0') +
                     '&SaveCert=' + encodeURIComponent('no');

        var xmlhttp = getXmlHttp();
        xmlhttp.open("POST", "https://testgost2012.cryptopro.ru/certsrv/certfnsh.asp", true);
        xmlhttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xmlhttp.onreadystatechange = function() {
            if (xmlhttp.readyState == 4) {
                if(xmlhttp.status == 200) {
                    cadesplugin.async_spawn (function*(arg) {
                        var response = arg[0];
                        var cert_data = "";

                        if(!isIE())
                        {
                            var start = response.indexOf("var sPKCS7");
                            var end = response.indexOf("sPKCS7 += \"\"") + 13;
                            cert_data = response.substring(start, end);
                        }
                        else
                        {
                            var start = response.indexOf("sPKCS7 & \"") + 9;
                            var end = response.indexOf("& vbNewLine\r\n\r\n</Script>");
                            cert_data = response.substring(start, end);
                            cert_data = cert_data.replace(new RegExp(" & vbNewLine",'g'),";");
                            cert_data = cert_data.replace(new RegExp("&",'g'),"+");
                            cert_data = "var sPKCS7=" + cert_data + ";";
                        }

                        eval(cert_data);

                        try {
                            var Enroll = yield cadesplugin.CreateObjectAsync("X509Enrollment.CX509Enrollment");
                        }
                        catch (e) {
                            alert('Failed to create X509Enrollment.CX509Enrollment: ' + cadesplugin.getLastError(e));
                            return;
                        }
                        try {
                            yield Enroll.Initialize(cadesplugin.ContextUser);
                        }
                        catch (err) {
                            alert('Failed to initialize X509Enrollment: ' + cadesplugin.getLastError(err));
                            return;
                        }
                        try {
                            yield Enroll.InstallResponse(cadesplugin.CADESCOM_AllowNone, sPKCS7, cadesplugin.XCN_CRYPT_STRING_ANY, "");
                        }
                        catch (err) {
                            e = cadesplugin.getLastError(err);
                            if (e.indexOf("0x800B0109") !== -1) {
                                e = "Предварительно необходимо установить корневой сертификат тестового УЦ в Доверенные корневые сертификаты\n\n" + e;
                            }
                            alert(e);
                            return;
                        }

                        document.getElementById("boxdiv").style.display = 'none';
                        if(location.pathname.indexOf("simple")>=0) {
                            location.reload();
                        }
                        else if(location.pathname.indexOf("symalgo_sample.html")>=0){
                            FillCertList_Async('CertListBox1', 'CertListBox2');
                        }
                        else{
                            FillCertList_Async('CertListBox');
                        }
                    }, xmlhttp.responseText);//cadesplugin.async_spawn
                }
            }
        }
        xmlhttp.send(params);
    });//cadesplugin.async_spawn
}

function InstallCertificate_Async(certBoxId)
{
    if (typeof(certBoxId) === 'undefined')
        return;
    cadesplugin.async_spawn(function*() {
        var e = document.getElementById(certBoxId);
        if (e.selectedIndex === -1) {
            alert("Select certificate");
            return;
        }
        var selectedCertID = e[e.selectedIndex].value;
        var certificate = global_selectbox_container[selectedCertID];
        if (!global_isFromCont[selectedCertID]) {
            alert("Сертификат уже установлен в хранилище");
            FillCertInfo_Async(certificate, certBoxId, global_isFromCont[selectedCertID]);
            return;
        }

        var data = yield certificate.Export(cadesplugin.CADESCOM_ENCODE_BASE64);

        try {
            var Enroll = yield cadesplugin.CreateObjectAsync("X509Enrollment.CX509Enrollment");
        }
        catch (e) {
            alert('Failed to create X509Enrollment.CX509Enrollment: ' + cadesplugin.getLastError(e));
            return;
        }
        try {
            yield Enroll.Initialize(cadesplugin.ContextUser);
        }
        catch (err) {
            alert('Failed to initialize X509Enrollment: ' + cadesplugin.getLastError(err));
            return;
        }
        try {
            yield Enroll.InstallResponse(
                cadesplugin.CADESCOM_UseContainerStore |
                cadesplugin.CADESCOM_AllowNone,
                data, cadesplugin.XCN_CRYPT_STRING_BASE64_ANY, "");
        }
        catch (err) {
            e = cadesplugin.getLastError(err);
            if (e.indexOf("0x800B0109") !== -1) {
                e = "Ошибка: корневой сертификат УЦ не установлен в Доверенные корневые сертификаты\n\n" + e;
            }
            alert(e);
            return;
        }
        global_isFromCont[selectedCertID] = false;
        FillCertInfo_Async(certificate, certBoxId, global_isFromCont[selectedCertID]);
        alert("Сертификат установлен в Личные сертификаты");
    });//cadesplugin.async_spawn
}

function CheckForPlugInUEC_Async()
{
    var isUECCSPInstalled = false;

    cadesplugin.async_spawn(function *() {
        var oAbout = yield cadesplugin.CreateObjectAsync("CAdESCOM.About");

        var UECCSPVersion;
        var CurrentPluginVersion = yield oAbout.PluginVersion;
        if( typeof(CurrentPluginVersion) == "undefined")
            CurrentPluginVersion = yield oAbout.Version;

        var PluginBaseVersion = "1.5.1633";
        var arr = PluginBaseVersion.split('.');

        var isActualVersion = true;

        if((yield CurrentPluginVersion.MajorVersion) == parseInt(arr[0]))
        {
            if((yield CurrentPluginVersion.MinorVersion) == parseInt(arr[1]))
            {
                if((yield CurrentPluginVersion.BuildVersion) == parseInt(arr[2]))
                {
                    isActualVersion = true;
                }
                else if((yield CurrentPluginVersion.BuildVersion) < parseInt(arr[2]))
                {
                    isActualVersion = false;
                }
            }else if((yield CurrentPluginVersion.MinorVersion) < parseInt(arr[1]))
            {
                    isActualVersion = false;
            }
        }else if((yield CurrentPluginVersion.MajorVersion) < parseInt(arr[0]))
        {
            isActualVersion = false;
        }

        if(!isActualVersion)
        {
            setStateForPlugin(Colors.INFO, "Плагин загружен, но он не поддерживает УЭК.");
        }
        else
        {
            setStateForPlugin(Colors.SUCCESS, "Плагин загружен");

            try
            {
                var oUECard = yield cadesplugin.CreateObjectAsync("CAdESCOM.UECard");
                UECCSPVersion = yield oUECard.ProviderVersion;
                isUECCSPInstalled = true;
            }
            catch (err)
            {
                UECCSPVersion = "Нет информации";
            }

            if(!isUECCSPInstalled)
            {
                setStateForPlugin(Colors.INFO, "Плагин загружен. Не установлен УЭК CSP.");
            }
        }
        document.getElementById('PlugInVersionTxt').innerHTML = escapeHtml("Версия плагина: " + (yield CurrentPluginVersion.toString()));
        document.getElementById('CSPVersionTxt').innerHTML = escapeHtml("Версия УЭК CSP: " + (yield UECCSPVersion.toString()));
    }); //cadesplugin.async_spawn
}

function FoundCertInStore_Async(cerToFind) {
    return new Promise(function(resolve, reject){
        cadesplugin.async_spawn(function *(args) {

            if((typeof cerToFind == "undefined") || (cerToFind == null))
                args[0](false);

            var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.store");
            if (!oStore) {
                alert("store failed");
                args[0](false);
            }
            try {
                yield oStore.Open();
            }
            catch (ex) {
                alert("Certificate not found");
                args[0](false);
            }

            var Certificates = yield oStore.Certificates;
            var certCnt = yield Certificates.Count;
            if(certCnt==0)
            {
                oStore.Close();
                args[0](false);
            }

            var ThumbprintToFind = yield cerToFind.Thumbprint;

            for (var i = 1; i <= certCnt; i++) {
                var cert;
                try {
                    cert = yield Certificates.Item(i);
                }
                catch (ex) {
                    alert("Ошибка при перечислении сертификатов: " + cadesplugin.getLastError(ex));
                    args[0](false);
                }

                try {
                    var Thumbprint = yield cert.Thumbprint;
                    if(Thumbprint == ThumbprintToFind) {
                        var dateObj = new Date();
                        var ValidToDate = new Date(yield cert.ValidToDate);
                        var HasPrivateKey = yield cert.HasPrivateKey();
                        var IsValid = yield cert.IsValid();
                        IsValid = yield IsValid.Result;

                        if(dateObj<ValidToDate && HasPrivateKey && IsValid) {
                            args[0](true);
                        }
                    }
                    else {
                        continue;
                    }
                }
                catch (ex) {
                    alert("Ошибка при получении свойства Thumbprint: " + cadesplugin.getLastError(ex));
                    args[0](false);
                }
            }
            oStore.Close();

            args[0](false);

        }, resolve, reject);
    });
}

function getUECCertificate_Async() {
    return new Promise(function(resolve, reject)
        {
            showWaitMessage("Выполняется загрузка сертификата, это может занять несколько секунд...");
            cadesplugin.async_spawn(function *(args) {
                try {
                    var oCard = yield cadesplugin.CreateObjectAsync("CAdESCOM.UECard");
                    var oCertTemp = yield oCard.Certificate;

                    if(typeof oCertTemp == "undefined")
                    {
                        document.getElementById("cert_info1").style.display = '';
                        document.getElementById("certerror").innerHTML = "Сертификат не найден или отсутствует.";
                        throw "";
                    }

                    if(oCertTemp==null)
                    {
                        document.getElementById("cert_info1").style.display = '';
                        document.getElementById("certerror").innerHTML = "Сертификат не найден или отсутствует.";
                        throw "";
                    }

                    if(yield FoundCertInStore_Async(oCertTemp)) {
                        FillCertInfo_Async(oCertTemp);
                        g_oCert = oCertTemp;
                    }
                    else {
                        document.getElementById("cert_info1").style.display = '';
                        document.getElementById("certerror").innerHTML = "Сертификат не найден в хранилище MY.";
                        throw "";
                    }
                    args[0]();
                }
                catch (e) {
                    var message = cadesplugin.getLastError(e);
                    if("The action was cancelled by the user. (0x8010006E)" == message) {
                        document.getElementById("cert_info1").style.display = '';
                        document.getElementById("certerror").innerHTML = "Карта не найдена или отсутствует сертификат на карте.";
                    }
                    args[1]();
                }
            }, resolve, reject);
        });
}

function createSignature_Async() {
    return new Promise(function(resolve, reject){
        cadesplugin.async_spawn(function *(args) {
            var signedMessage = "";
            try {
                var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
                yield oSigner.propset_Certificate(g_oCert);
                var CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN = 1;
                yield oSigner.propset_Options(CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN);

                var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                yield oSignedData.propset_Content("DataToSign");

                var CADES_BES = 1;
                signedMessage = yield oSignedData.SignCades(oSigner, CADES_BES);
                args[0](signedMessage);
            }
            catch (e) {
                showErrorMessage("Ошибка: Не удалось создать подпись. Код ошибки: " + cadesplugin.getLastError(e));
                args[1]("");
            }
            args[0](signedMessage);
        }, resolve, reject);
    });
}

function verifyCert_Async() {
    if (!g_oCert) {
        removeWaitMessage();
        return;
    }
    createSignature_Async().then(
        function(signedMessage){
            document.getElementById("SignatureTxtBox").innerHTML = escapeHtml(signedMessage);
            var x = document.getElementsByName("SignatureTitle");
            x[0].innerHTML = "Подпись сформирована успешно:";
            removeWaitMessage();
        },
        function(signedMessage){
            removeWaitMessage();
        }
    );
}

async_resolve();

include_async_code().then(function(){
    include_async_code().then(function(){
        return null;
    });
    return null;
});