<template>
  <nav class="menu">
    <!-- eslint-disable -->
    <router-link
      v-for="route in getRouts"
      :key="route.name"
      v-if="route.access"
      class="menu-link"
      active-class="active"
      :to="route.to">
      <img :src="route.icon" />
      {{route.name}}
    </router-link>
  </nav>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  computed : {
    ...mapGetters({
        getRouts: 'login/getRouts'
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "~@/scss/vars.scss";
@import "~@/scss/mixins.scss";
@import "./style.scss";
</style>
