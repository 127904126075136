<template>
    <div class="page">
        <h2 class="main-title">{{title}}</h2>
        <div class="filter-block">
          <h2 class="main-title">Фильтр записей</h2>
          <v-row>
          <v-col cols="12" md="12" sm="12">
            <v-row>
              <v-col cols="5" sm="4">
                <span>Возрастная группа</span>
                <v-autocomplete
                  item-value="id"
                  outlined
                  dense
                  no-data-text="Запись не найдена"
                  :items="ageGroupsList"
                  hide-details=""
                  multiple
                  clearable
                  label=""
                  item-text="name"
                  @change="moreClinicalRecommendation(filter.ageGroupIds)"
                  v-model="filter.ageGroupIds"
                  class="input"
                  placeholder="Любой"
                >
                <template v-slot:selection="{ item, index }">
                        <span style="width: 80%; margin-top: 5px;" v-if="index === 0" >{{ item.name }}</span>

                        <span v-if="index === 1" class="grey--text text-caption">
                            +{{ filter.ageGroupIds.length - 1 }} еще выбранных(й)
                        </span>
                </template>
              </v-autocomplete>
              </v-col>
              <v-col cols="5" sm="4">
                <span>Клиническая рекомендация</span>
                <v-autocomplete
                  outlined
                  dense
                  no-data-text="Запись не найдена"
                  clearable
                  multiple
                  v-model="filter.clinRecIds"
                  :items="getKRList"
                  hide-details=""
                  @change="getDiagramm()"
                  item-value="Id"
                  item-text="name"
                  placeholder="Все"
                >
                <template v-slot:selection="{ item, index }">
                        <span style="width: 80%; margin-top: 5px;" v-if="index === 0" >{{ item.name }}</span>

                        <span v-if="index === 1" class="grey--text text-caption">
                            +{{ filter.clinRecIds.length - 1 }} еще выбранных(й)
                        </span>
                </template>
              </v-autocomplete>
              </v-col>
              <v-col cols="5" sm="4">
                <span>Код МКБ-10</span>
                <v-autocomplete
                  outlined
                  dense
                  multiple
                  clearable
                  no-data-text="Запись не найдена"
                  :items='getMkb'
                  v-model="filter.mkbIds"
                  @change="getDiagramm()"
                  hide-details=""
                  item-value="id"
                  item-text="code"
                  placeholder="Все"
                  class="input"
                >
                <template v-slot:selection="{ item, index }">
                        <span style="width: 80%; margin-top: 5px;" v-if="index === 0" >{{ item.name }}</span>

                        <span v-if="index === 1" class="grey--text text-caption">
                            +{{ filter.mkbIds.length - 1 }} еще выбранных(й)
                        </span>
                </template>
              </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <v-row>
              <v-col cols="12">
                <span>Дата создания запроса на формирование СМП</span>
              </v-col>
            </v-row>
            <v-row style="margin-top: -12px;">
              <v-col cols="5" sm="2">
                <v-autocomplete
                  outlined
                  dense
                  clearable
                  no-data-text="Запись не найдена"
                  :items="month"
                  v-model="monthSelect"
                  hide-details=""
                  item-value="id"
                  item-text="name"
                  label="Месяц"
                  @change="resetQuarter(monthSelect)"
                  class="input"
                  placeholder="Месяц"
                ></v-autocomplete>
              </v-col>
              <v-col cols="5" sm="2">
                <v-autocomplete
                  outlined
                  no-data-text="Запись не найдена"
                  dense
                  clearable
                  return-object
                  item-text="id"
                  :items="quarter"
                  @change="resetMonth(quarterSelect)"
                  v-model="quarterSelect"
                  hide-details=""
                  label="Квартал"
                  placeholder="Квартал"
                  class="input"
                ></v-autocomplete>
              </v-col>
              <v-col cols="5" sm="2">
                <v-autocomplete
                  outlined
                  dense
                  no-data-text="Запись не найдена"
                  clearable
                  :items="year"
                  v-model="yearSelect"
                  hide-details=""
                  label="Год"
                  @change="filterYear()"
                  placeholder="Год"
                  class="input"
                ></v-autocomplete>
              </v-col>
              <v-col cols="5" sm="2">
              <v-menu
                  v-model="dates.begin.menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                  <template v-slot:activator="{ attrs, on }">
                      <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          hide-details=""
                          density="comfortable"
                          :value="dates.begin.formatted"
                          @change="inputDate($event, dates.begin)"
                          readonly
                          prepend-icon="mdi-calendar"
                          placeholder="От"
                          clearable
                      />
                  </template>
                  <v-date-picker
                      :min="dates.min"
                      :max="dates.end.value !== null ? dates.end.value : dates.max"
                      v-model="dates.begin.value"
                      @change="inputDate($event, dates.begin)"
                      no-title
                      scrollable
                      locale="ru-Ru"
                  />
              </v-menu>
              </v-col>
              <v-col cols="5" sm="2">
                <v-menu
                  v-model="dates.end.menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                  <template v-slot:activator="{ attrs, on}">
                      <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          hide-details=""
                          density="comfortable"
                          :value="dates.end.formatted"
                          @change="inputDate($event, dates.end)"
                          readonly
                          prepend-icon="mdi-calendar"
                          placeholder="До"
                          clearable
                      />
                  </template>
                  <v-date-picker
                      :min="dates.begin.value !== null ? dates.begin.value : dates.min"
                      :max="dates.max"
                      v-model="dates.end.value"
                      @change="inputDate($event, dates.end)"
                      no-title
                      scrollable
                      locale="ru-Ru"
                  />
              </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="button-block" cols="12" md="12" sm="12">
            <v-btn outlined @click="reset">Сбросить</v-btn>
          </v-col>
        </v-row>
        </div>
        <div class="filter-block mt-4">
          <h2 class="main-title">Общая статистика</h2>
         <div class="smp-block mt-1 d-flex justify-space-between">
          <span class="title-count">Количество СМП на стадии "Запрос": <span class="count">{{ sumCountReq }}</span></span>
          <v-icon @click="reqFlag = !reqFlag">{{reqFlag ? 'mdi-menu-down' : 'mdi-menu-right'}}</v-icon>
         </div>
         <div v-for="(item, index) in listRequest" :key="index" v-show="reqFlag" class="smp-block">
          {{ item.name }}: <span class="count">{{ item.count }}</span>
         </div>
         <div class="smp-block d-flex justify-space-between">
          <span class="title-count">Количество СМП на стадии "Проект": <span class="count">{{ sumCountProj }}</span></span>
          <v-icon @click="projFlag = !projFlag">{{projFlag ? 'mdi-menu-down' : 'mdi-menu-right'}}</v-icon>
         </div>
         <div v-for="(item, index) in listProject" :key="index" v-show="projFlag" class="smp-block">
          {{ item.name }}: <span class="count">{{ item.count }}</span>
         </div>
         <div v-for="(item, index) in listStandart" :key="index" class="smp-block">
          <span class="title-count">Количество СМП на стадии "Стандарт"(утвержденные СМП) : <span class="count">{{ item.count }}</span></span>
         </div>
        </div>
        <div class="charts-block">
          <div class="chart">
            <apexchart ref="bar" type="bar" :height="requestChart.height" :options="chartOptions" :series="requestChart.series"></apexchart>
          </div>
          <div class="chart">
            <apexchart ref="bar1" type="bar" :height="projectChart.height" :options="chartOptions1" :series="projectChart.series"></apexchart>
          </div>
          <div class="chart">
            <apexchart ref="bar2" type="bar" :height="standardChart.height" :options="chartOptions2" :series="standardChart.series"></apexchart>
          </div>
        </div>
    </div>
 </template>

 <script>

 import {mapGetters, mapActions, mapMutations} from 'vuex'
 import apexchart from 'vue-apexcharts';
 export default {
   name: 'SMP',
   components:{
    apexchart
   },
   data () {
     return {
       title: 'Аналитика',
       filter: {
          ageGroupIds: [],
          clinRecIds:[],
          mkbIds:[],
          dateFrom: null,
          dateTo: null
        },
        sumCountReq: 0,
        sumCountProj: 0,
        listRequest: [],
        listProject: [],
        listStandart: [],
        reqFlag:false,
        projFlag:false,
        ageGroupsList: [],
        month: [
          {
            name: 'Январь',
            id: '01'
          },
          {
            name: 'Февраль',
            id: '02'
          },
          {
            name: 'Март',
            id: '03'
          },
          {
            name: 'Апрель',
            id: '04'
          },
          {
            name: 'Май',
            id: '05'
          },
          {
            name: 'Июнь',
            id: '06'
          },
          {
            name: 'Июль',
            id: '07'
          },
          {
            name: 'Август',
            id: '08'
          },
          {
            name: 'Сентябрь',
            id: '09'
          },
          {
            name: 'Октябрь',
            id: 10
          },
          {
            name: 'Ноябрь',
            id: 11
          },
          {
            name: 'Декабрь',
            id: 12
          },
        ],
        quarter: [
        {
          id: 1,
          from: '01',
          to: '03'
        },
        {
          id: 2,
          from: '04',
          to: '06'
        },
        {
          id: 3,
          from: '07',
          to: '09'
        },
        {
          id: 4,
          from: '10',
          to: '12'
        }
        ],
        year: [2024],
        monthSelect: null,
        quarterSelect: null,
        yearSelect: 2024,
        dates: {
            begin: {
                value: null,
                formatted: null,
                menu: false
            },
            end: {
                value: null,
                formatted: null,
                menu: false
            },
            min: null,
            max: null
        },
        filterKr:{
        adults: false,
        children: false,
        name: "",
        status: 0
        },
        menu:false,
        requestChart: {
          series: [{
            name: 'Количество стандартов',
            data: []
          }],
          height: 350
        },
        projectChart: {
          series: [{
            name: 'Количество стандартов',
            data: []
          }],
          height: 350
        },
        standardChart: {
          series: [{
            name: 'Количество стандартов',
            data: []
          }],
          height: 350
        },
        chartOptions: {
          chart: {
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              borderRadiusApplication: 'end',
              horizontal: true,
              barHeight: '50%',
              distributed: true,
              dataLabels: {
                position: 'bottom'
              },
            }
          },
          colors: [],
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              colors: ['#fff']
            },
            offsetX: 0,
            dropShadow: {
              enabled: true
            }
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          yaxis: {
            labels: {
              show: false
            }
          },
          title: {
              text: 'СМП на стадии "Запрос"',
              align: 'center',
              floating: true
          },
          labels: [],
        },
        chartOptions1: {
          chart: {
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              borderRadiusApplication: 'end',
              horizontal: true,
              barHeight: '50%',
              distributed: true,
              dataLabels: {
                position: 'bottom'
              },
            }
          },
          colors: [],
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              colors: ['#fff']
            },
            offsetX: 0,
            dropShadow: {
              enabled: true
            }
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          yaxis: {
            labels: {
              show: false
            }
          },
          title: {
              text: 'СМП на стадии "Проект"',
              align: 'center',
              floating: true
          },
          labels: [],
        },
        chartOptions2: {
          chart: {
            type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              borderRadiusApplication: 'end',
              horizontal: true,
              barHeight: '50%',
              distributed: true,
              dataLabels: {
                position: 'bottom'
              },
            }
          },
          colors: [],
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              colors: ['#fff']
            },
            offsetX: 0,
            dropShadow: {
              enabled: true
            }
          },
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          yaxis: {
            labels: {
              show: false
            }
          },
          title: {
              text: 'СМП на стадии "Стандарт"',
              align: 'center',
              floating: true
          },
          labels: [],
        }
     }
   },
   computed: {
     ...mapGetters({
         getStandarts: 'history/getStandarts',
         getAgeGroups: 'lookups/getAgeGroups',
         getMkb: 'lookups/getMkb',
         getKRList: 'request/getKRList',
         apiUrl: 'apiUrl'
     }),
     icon () {
        if (this.allCr) return 'mdi-close-box'
        return 'mdi-checkbox-blank-outline'
        },
   },
   methods: {
     ...mapActions({
      filterStandarts: 'history/filterStandarts',
      filterStandartsId: 'history/filterStandartsId',
      lookups: 'lookups/lookups',
      getKR: 'request/getKR',
      smpFilter: 'smp/smpFilter'
     }),
     ...mapMutations({
      setSearch: 'history/setSearch',
      setSort: 'history/setSort'
     }),
     moreClinicalRecommendation(arr) {
        if (arr) {
          for (let i = 0; i < arr.length; i++) {
           arr[i] === '82ede3fb-5653-4189-98bf-7be3d809b41b'? this.filterKr.children = true : false
           arr[i] === 'ff4e5efe-138c-4b91-b45d-9013644a8965'? this.filterKr.adults = true : false
          }
        }
        this.getKR(this.filterKr)
        this.getDiagramm()
      },
      reset(){
        this.filter = {
          ageGroupIds : [],
          clinRecIds :[],
          mkbIds :[],
          dateFrom :null,
          dateTo : null
        },
        this.monthSelect = null
        this.quarterSelect = null
        this.yearSelect = 2024
        this.dates = {
            begin: {
                value: null,
                formatted: null,
                menu: false
            },
            end: {
                value: null,
                formatted: null,
                menu: false
            },
            min: null,
            max: null
        }

        this.getDiagramm()
      },
      resetQuarter(id){
        this.filter.dateFrom = null
        this.filter.dateTo = null
        if (id) {
        let fromDate = '2024-1-01';
        let ToDate = id === '02' ? '2024-1-28' :'2024-1-30';
        const replaceChar = (str, index, char) => str.substring(0, index) + char + str.substring(index + 1);
        this.filter.dateFrom = replaceChar(fromDate, 5, id)
        this.filter.dateTo = replaceChar(ToDate, 5, id)
        }
        if(this.month){
          this.quarterSelect = null
          this.dates = {
            begin: {
                value: null,
                formatted: null,
                menu: false
            },
            end: {
                value: null,
                formatted: null,
                menu: false
            },
            min: null,
            max: null
        }
        }
        this.getDiagramm()
      },
      resetMonth(quarter){
        this.filter.dateFrom = null
        this.filter.dateTo = null
        if (quarter) {
        const replaceChar = (str, index, char) => str.substring(0, index) + char + str.substring(index + 1);
        let fromDate = '2024-1-01';
        let toDate = '2024-1-30';
        this.filter.dateFrom = replaceChar(fromDate, 5, quarter.from)
        this.filter.dateTo = replaceChar(toDate, 5, quarter.to)
        }
        if(this.quarter){
          this.monthSelect = null
          this.dates = {
            begin: {
                value: null,
                formatted: null,
                menu: false
            },
            end: {
                value: null,
                formatted: null,
                menu: false
            },
            min: null,
            max: null
        }
        }
        this.getDiagramm()
      },
      toggle () {
        this.allCr = !this.allCr
            // console.log(arrayids, array);
            // console.log();
            // if (this.filter[arrayids].length === this[array].length) {
            //     this.filter[arrayids] = []
            // } else {
            //     this.filter[arrayids] = this[array].slice()
            // }
          this.moreClinicalRecommendation()
          this.getDiagramm()
        },
      inputDate(value, date) {
        if (value === null) {
          this.dates.begin.value = ''
          this.dates.end.value = ''
          return
        }
        console.log(value, date);
            let dateSend = new Date(value)
            this.monthSelect = null
            this.quarterSelect = null
            const year = dateSend.getFullYear();
            const month = dateSend.getMonth() + 1;
            const day = dateSend.getDate();
            let dateGet = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
            date.formatted = this.formatDate(dateGet);
            date.menu = false;
            if (this.dates.begin.value && this.dates.end.value) {
              this.getDiagramm()
            }
        },
        formatDate(date) {
            if (!date) {
                return null;
            }

            const [year, month, day] = date.split('-');
            return `${day}.${month}.${year}`;
        },
    getDiagramm() {
      let filters = {
          ...this.filter,
          dateFrom: this.dates.begin.value ? this.dates.begin.value : this.filter.dateFrom,
          dateTo: this.dates.end.value ? this.dates.end.value : this.filter.dateTo,
      };

      this.$http.post(`${this.apiUrl}StandartReports/stage/request`, filters)
        .then((res) => {
          this.requestChart.series[0].data = [
            res.data.gvsPending.count,
            res.data.gvsAccepted.count,
            res.data.cekkmpPostponed.count
          ];

          this.chartOptions.labels = [
            res.data.gvsPending.name,
            res.data.gvsAccepted.name,
            res.data.cekkmpPostponed.name
          ];

          this.requestChart.height = this.getChartHeight(this.requestChart);
          this.$refs.bar.refresh();
          this.listRequest = res.data
          // console.log(Object.values(res.data));

          this.sumCountReq = 0
          Object.values(res.data).forEach(element => {
           this.sumCountReq += element.count
          });
        });

      this.$http.post(`${this.apiUrl}StandartReports/stage/project`, filters)
        .then((res) => {
          this.projectChart.series[0].data = [
            res.data.gvsApproval.count,
            res.data.cekkmpRevision.count,
            res.data.mzApproval.count,
            res.data.meo.count,
            res.data.actRegistration.count
          ];

          this.chartOptions1.labels = [
            res.data.gvsApproval.name,
            res.data.cekkmpRevision.name,
            res.data.mzApproval.name,
            res.data.meo.name,
            res.data.actRegistration.name
          ];

          this.projectChart.height = this.getChartHeight(this.projectChart);
          this.$refs.bar1.refresh();
          this.listProject = res.data

          this.sumCountProj = 0
          Object.values(res.data).forEach(element => {
           this.sumCountProj += element.count
          });
        });

      this.$http.post(`${this.apiUrl}StandartReports/stage/standard`, filters)
        .then((res) => {
          this.standardChart.series[0].data = [
            res.data.confirmed.count
          ];

          this.chartOptions2.labels = [
            res.data.confirmed.name
          ];

          this.standardChart.height = this.getChartHeight(this.standardChart);
          this.$refs.bar2.refresh();
          this.listStandart = res.data
        });
    },
    getChartHeight(chart) {
      return 88 * chart.series[0].data.length + 86;
    }
  },
  mounted() {
    this.lookups().then((res) => {
      for (let i = 0; i < res.data.ageGroups.length; i++) {
        if (res.data.ageGroups[i].name != 'Любой') {
          this.ageGroupsList.push(res.data.ageGroups[i])
        }
      }
    });
    this.smpFilter({})
    this.moreClinicalRecommendation(this.filter.ageGroupIds)
  }
}
</script>

<style lang="scss" scoped>
@import "~@/scss/vars.scss";
@import "~@/scss/mixins.scss";
@import "./style.scss";
</style>
