import axios from "axios";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    standarts: [],
    standartsFilter: [],
    filterStandarts: [],
    sortColumn: "",
    filterId: [],
  },
  getters: {
    getStandarts: (state) => state.standarts,
    standartsFilter: (state) => state.standartsFilter,
    filterStandarts: (state) => state.filterStandarts,
  },
  mutations: {
    // filterMutation(state, data) {
    //   state.filterSMP = data;
    //   state.smpState = data
    //     .sort((a, b) => {
    //       return b.number - a.number;
    //     })
    //     .map((item) => {
    //       return {
    //         data: `${item.stage.name} №${item.number} ${moment(
    //           item.createdOn
    //         ).format("DD.MM.YY")}`,
    //         name: item.name,
    //         clinicalRecommendations: item.clinicalRecommendations
    //           .map((cr) => {
    //             return cr.name;
    //           })
    //           .join(" "),
    //         mkbName: item.mkBs
    //           .map((mkb) => {
    //             return mkb.name;
    //           })
    //           .join(" "),
    //         mkbCode: item.mkBs
    //           .map((mkb) => {
    //             return mkb.code;
    //           })
    //           .join(" "),
    //         id: item.id,
    //         isOpen: item.isOpen,
    //         ageGroup: item.ageGroup ? item.ageGroup.name : "",
    //         medicalCareTypes: item.medicalCareTypes
    //           .map((med) => {
    //             return med.name;
    //           })
    //           .join(" "),
    //         status: item.status.name,
    //       };
    //     });
    //   state.smpFilter = state.smpState;
    // },
    setStandarts(state, data) {
      data = data.reverse();
      data = data.map((item) => {
        return { ...item, isOpen: false };
      });
      console.log(data);
      state.filterStandarts = data;
      state.standarts = data;
      state.standartsFilter = state.standarts.map((m) => {
        return {
          ClinicalRecommendationName: m.ClinicalRecommendationName,
          Id: m.Id,
          MkbCode: m.MkbCode,
          MkbName: m.MkbName,
          ModifiedBy: m.ModifiedBy,
          ModifiedOn: m.ModifiedOn,
          StandartCode: m.StandartCode,
          StandartName: m.StandartName,
          StandartNumber: String(m.StandartNumber),
          StatusName: m.StatusName,
          isOpen: m.isOpen,
        };
      });
    },
    setSearch(state, { column, string }) {
      let str = string;
      if (string && column != "StandartNumber") {
        str = string[0].toUpperCase() + string.slice(1).toLowerCase();
      }
      state.standarts = state.standartsFilter.filter((item) => {
        if (item[column] != null) {
          if (
            item[column].indexOf(String(str)) != -1 ||
            item[column].length - 1 === str.length
          ) {
            return item;
          }
        }
      });
    },
    setSort(state, column) {
      if (state.sortColumn === column) {
        state.standarts = state.standarts.reverse();
      } else {
        state.sortColumn = column;
        state.standarts = _.sortBy(state.standarts, [
          function(o) {
            return o[column];
          },
        ]);
      }

      state.standarts = state.standarts.slice(0, state.itemPerPage);
    },
    // setSearch(state, { column, string }) {
    //   let newString = String(string);
    //   state.filterId = [];
    //   state.standarts = state.standarts.filter((item) => {
    //     if (
    //       String(item[column])
    //         .toLowerCase()
    //         .search(newString.toLowerCase()) != -1
    //     ) {
    //       state.filterId.push(item.id);
    //       return item;
    //     }
    //   });
    // },
    setStandartsId(state, data) {
      console.log(data);
    },
  },
  actions: {
    filterStandarts({ rootState, commit }, filters) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${rootState.api}Standarts/reportforstatuses`, filters)
          .then((resp) => {
            commit("setStandarts", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    filterHistoryExcel({ rootState }, filters) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${rootState.api}Standarts/reportforstatusesexcel`, filters, {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/Docx",
            },
          })
          .then((resp) => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `history.xlsx`);
            document.body.appendChild(link);
            link.click();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    filterStandartsId({ rootState, commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${rootState.api}Standarts/statushistoryByStandartId?standartId=${id}`
          )
          .then((resp) => {
            commit("setStandartsId", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
