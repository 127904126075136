<template>
  <header v-bind:class="{ login: $store.state.login.token, 'header': true }">
    <v-container fluid v-if="!$store.state.login.token">
      <v-row align="center">
        <v-col cols="2">
          <router-link to="/"><img
            :src="require('../../assets/logo.png')"
            height="77"
          /></router-link>
        </v-col>
        <v-col cols="8" align="center">
          <h2>Система разработки и ведения стандартов медицинской помощи</h2>
        </v-col>
        <v-col cols="2">
          <Auth/>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col cols="6" class="flex-start">
          <router-link to="/">
          <img
            :src="require('../../assets/logo.png')"
            height="55"
            class="logo"
          />
          </router-link>
          <p class="header-title">Система разработки и ведения<br> стандартов медицинской помощи</p>
        </v-col>
        <v-col cols="6" class="flex-end">
          <p class="name text-right">
            Добро пожаловать,<br />
            {{getUser.fullName || getUser.login}}
            <template v-if="getRole() === 'ГВС'">
              <br />({{ getProfiles() }})
            </template>
          </p>
          <div class="bell-block">
            <img class="icon-bell" @click="bellWindow = !bellWindow" :src="require('../../assets/icon/bell.svg')"/>
            <div v-show="getCountBells"  @click="bellWindow = !bellWindow" class="unread-bell">
              <span>{{getCountBells}}</span>
            </div>
            <v-expand-transition>
              <div class="bell-window" v-show="bellWindow">
                <div class="container" :class="{'container-active' : !item.isRead}" v-for="(item, index) in getlistBells" :key="index">
                    <template v-if="item.typeId === 1">
                      <h4>{{ item.data.standardName}} №{{ item.data.standardNumber }}</h4>
                      <h4>{{item.data.statusName}}</h4>
                      <span>{{item.data.userFullName}}</span><br>
                      <span>Роль: {{item.data.userRoles}}</span><br>
                    </template>
                    <template v-else-if="item.typeId === 2">
                      <h4>Истекает срок {{ item.data.onApproval ? 'оценки' : 'рассмотрения' }}</h4>
                      <span>{{ item.data.standardName }} №{{ item.data.standardNumber }}</span><br>
                      <span>{{ item.data.standardCode }}</span><br>
                      <span>{{ item.data.statusName }}</span><br>
                      <span><a v-if="item.data.onApproval" href="https://votingstandart.minzdrav.gov.ru/" target="_blank">https://votingstandart.minzdrav.gov.ru/</a></span>
                    </template>
                    <div class="date-block">
                      <span>{{ item.createdOn | formatDate }} в {{ item.createdOn | formatTime }}</span>
                      <div class="icons-block">
                        <v-tooltip v-if="!item.isRead" top>
                          <template v-slot:activator="{ on, attrs }">
                            <img v-bind="attrs" v-on="!item.isRead ? on : true" v-if="!item.isRead" class="icon-bell-delete" @click="hideNotification(item)" :src="require('../../assets/icon/read.svg')"/>
                          </template>
                          <span>Отметить как прочитанное</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <img v-bind="attrs" v-on="on" class="icon-bell-delete" @click="deleteNotification(item.id)" :src="require('../../assets/icon/Trash.svg')"/>
                          </template>
                          <span>Удалить</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <span></span>
                </div>
                <div v-if="getlistBells.length === 0 " class="block-title mt-3">
                  <h4>Уведомления отсутствуют</h4>
                </div>
                <div class="block-title">
                  <span v-if="getlistBells.length > 0 " @click="hideNotification({}, 'all')" style="color: #003C69;">Отметить все как прочитанное</span>
                </div>
              </div>
            </v-expand-transition>
          </div>
          <button class="btn btn-help" @click="openDialog()">Помощь</button>
          <button
            @click="logout().then(res => {
              bellWindow = false
              if ($route.name != 'home') $router.push({name: 'home'})
            })"
            class="btn btn-enter"
          >
            Выход
          </button>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialogHelp"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Помощь
        </v-card-title>

        <v-card-text>
          <a :href="link" target="_blank">Руководство пользователя</a><br>
          Служба технической поддержки: <br>
          8 (495) 627-29-61 | +7 (901) 756-94-65 <br>
          <a href="mailto:support-std@minzdrav.gov.ru">support-std@minzdrav.gov.ru</a><br>
          Часы работы: 09:00-18:00 (МСК)
        </v-card-text>
      </v-card>
    </v-dialog>
  </header>
</template>

<script>
import Auth from '@/components/Auth/Auth.vue'
import {mapGetters, mapActions, mapMutations} from 'vuex'
const dateFormatter = new Intl.DateTimeFormat('ru', {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
})
const timeFormatter = new Intl.DateTimeFormat('ru', {
	hour: '2-digit',
	minute: '2-digit',
})
export default {
  components: {
    Auth
  },
  data: () => ({
    dialogHelp: false,
    link: 'https://standart.minzdrav.gov.ru/static/gvs.pdf',
    bellWindow: false
  }),
  computed : {
    ...mapGetters({
        getUser: 'login/getUser',
        getCountBells:'bell/getCountBells',
        getlistBells:'bell/getlistBells',
        apiUrl: 'apiUrl'
    }),
  },
  methods: {
    ...mapActions({
      logout: 'login/logout',
      bell: 'bell/getBells',
      countBells: 'bell/countBells',
    }),
    ...mapMutations({
			setBells: 'bell/setBells'
		}),
    getRole() {
      if (this.getUser.roles) {
        return this.getUser.roles[0].name;
      }

      return '';
    },
    getProfiles() {
      if (this.getUser.profiles) {
        return this.getUser.profiles.slice(0, 3).map(x => x.name).join(', ');
      }

      return '';
    },
    openDialog() {
      this.dialogHelp = true
      const role = this.getRole();
      this.dialogHelp = true
      if (role === 'ЦЭККМП') {
        this.link = 'https://standart.minzdrav.gov.ru/static/cekkmp.pdf'
      } else if (role === 'Сотрудник Минздрава') {
        this.link = 'https://standart.minzdrav.gov.ru/static/minzdrav.pdf'
      } else {
        this.link = 'https://standart.minzdrav.gov.ru/static/gvs.pdf'
      }
    },
    hideNotification(item, all) {
      if (this.getlistBells.some((s) => s.isRead === false)) {
        if (!all) {
          this.$http.post(`${this.apiUrl}Notifications/SetAsRead`, [item.id])
            .then(() => {
              this.bell();
              this.countBells();
            });
        } else {
          this.$http.post(`${this.apiUrl}Notifications/SetAsRead`,  this.getlistBells.map(m=>m.id))
            .then(() => {
              this.bell();
              this.countBells();
            });
        }
      }
		},
    deleteNotification(id) {
      this.$http.post(`${this.apiUrl}Notifications/Delete?id=${id}`)
        .then(() => {
          this.bell();
          this.countBells();
        })
    },
  },
  filters: {
		formatDate(date) {
			return dateFormatter.format(new Date(date || Date.now()))
		},
		formatTime(date) {
			return timeFormatter.format(new Date(date || Date.now()))
		},
	},
  created(){
    if (this.$store.state.login.token) {
      this.bell();
      this.countBells();
    }


  }
}
</script>

<style lang="scss" scoped>
@import "~@/scss/vars.scss";
@import "~@/scss/mixins.scss";
@import "./style.scss";
</style>
