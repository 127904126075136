import axios from "axios";
export default {
  namespaced: true,
  state: {
    countBells: null,
    listBells: [],
  },
  getters: {
    getCountBells: (state) => state.countBells,
    getlistBells: (state) => state.listBells,
  },
  mutations: {
    setBells(state, data) {
      state.listBells = data;
    },
    setCountBells(state, data) {
      state.countBells = data;
    },
    setNotifications(state, data) {
      console.log(state, data);
    },
  },
  actions: {
    getBells({ rootState, commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootState.api}Notifications?isRead=`)
          .then((resp) => {
            commit("setBells", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    countBells({ rootState, commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootState.api}Notifications/UnreadCount`)
          .then((resp) => {
            commit("setCountBells", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    hideNotification({ rootState, commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${rootState.api}Notifications/SetAsRead`, [id])
          .then((resp) => {
            commit("setNotifications", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
