<template>
    <v-snackbar v-model="options.show" top :color="options.color" :timeout="options.timeout">
      {{ options.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-show="options.timeout === -1" v-bind="attrs" @click="options.show = false" plain class="snackbar-btn">
          <svg class="icon-close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6 5L18.7279 17.7279" stroke="#ffffff" stroke-width="2" stroke-linecap="round"/>
            <path d="M6 18L18.7279 5.27208" stroke="#ffffff" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
export default {
    data: () => ({
      options: {
        show: false,
        text: null,
        color: null,
        timeout: -1
      }
    }),
    methods: {
      show(type, message, timeout = 5000) {
        this.options = {
          show: true,
          color: type,
          text: message,
          timeout: type === 'error' ? -1 : timeout
        };
      }
    }
}
</script>

<style scoped>
.v-snack__wrapper .snackbar-btn {
    min-width: 36px;
}
</style>
