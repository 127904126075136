<template>
  <div class="page">
    <h2 class="main-title">{{title}}</h2>
    <div class="button-box">
      <v-btn
          color="red"
          class="ma-2 white--text"
          @click="createUser()"
        >
          <v-icon
            left
            dark
          >
            mdi-plus
          </v-icon>
          Добавить
        </v-btn>
    </div>
    <div class="responsive">
      <table class="table table-search table-link">
        <thead>
          <tr>
            <th
                v-for="header in headers"
                :key="header.text"
                class="text-center"
                style="cursor: pointer;"
                v-show="header.show"
                >
                <span @click="sortArchive(header.value)">
                {{header.text}}
                <span v-if="header.sort" class="sort">
                  <v-icon
                  v-if="header.revers"
                  color="blue">
                    mdi-chevron-up
                  </v-icon>
                  <v-icon
                  v-else
                  color="blue">
                    mdi-chevron-down
                  </v-icon>
                </span>
              </span>
                <div class="search">
                  <input type="text" v-if="header.input === '' || header.input " v-model="header.input" @input="setSearch({column: header.value, string: header.input})">
                </div>
                </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in getUsers"
            :key="index"
          >
          <td v-show="headers[0].show" class="text-center">{{user.roles[0].name}}</td>
            <td class="text-center">{{user.fullName}}</td>
            <td class="text-center">
                <div class="item">{{user.login}}</div>
            </td>
            <td class="d-flex justify-center align-center">
              <v-switch
              v-model="user.signEnabled"
              :readonly="user.id === getUser.id"
              color="#B6DFF0"
              @change="enabled(user.id)"
              :label="user.signEnabled ? 'Да' : 'Нет'"
              hide-details>

              </v-switch>
            </td>
            <td class="text-center">
              <a v-if="user.signEnabled && user.hasSignatureFile" @click.prevent="downloadECP(user)" href="" >
                Открытая часть ЭЦП
              </a>
              <span v-else-if="user.signEnabled && !user.hasSignatureFile">Загрузите ЭЦП</span>
              <span v-else>&mdash;</span>
            </td>
            <td class="text-center">
              <div>
                <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="!user.signEnabled"
                            small
                            icon
                            @click="openFileDownloadDialog(user)"
                            v-bind="attrs"
                            v-on="on"
                            >
                          <img svg-inline :src="require('@/assets/icon/uploadecp.svg')" />
                        </v-btn>
                      </template>
                      <span>Загрузить ЭЦП</span>
                    </v-tooltip>

              <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="updateUser(user)"
                              >
                              <img
                              color="primary"
                              svg-inline
                              :src="require('@/assets/icon/Pencil.svg')"
                              />
                            </v-btn>
                      </template>
                      <span>Редактировать</span>
                    </v-tooltip>

              <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            small
                            icon
                            @click="disableUser(user)"
                             v-bind="attrs"
                            v-on="on"
                            >
                              <img
                              svg-inline :src="!user.isBlocked ? require('@/assets/icon/block.svg') : require('@/assets/icon/blockgreen.svg') " />
                            </v-btn>
                      </template>
                      <span>{{!user.isBlocked ? 'Заблокировать' : 'Разблокировать'}}</span>
                    </v-tooltip>

                </div>
            </td>
          </tr>
        </tbody>
      </table>
      <input @change="uploadFile($event, currentUser)" hidden ref="fileAssign" type="file" accept=".cer">
      <v-snackbar :color="snackbarColor" v-model="snackbar" top>{{ snackbar_text }}</v-snackbar>
    </div>
    <v-dialog v-model="dialog" max-width="1400px" persistent>
        <div class="d-flex justify-end">
        </div>
          <v-card>
                  <div style=" padding: 20px 0 0 30px; display: flex; ">
                    <h2 class="header" style="width: 90%;">{{titleDialog}} пользователя</h2>
                    <v-btn icon style="margin: 2px 0 0 5px" class="close" @click="close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                           <path d="M6 5L18.7279 17.7279" stroke="#1976d2" stroke-width="2" stroke-linecap="round"/>
                           <path d="M6 18L18.7279 5.27208" stroke="#1976d2" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                     </v-btn>
                  </div>

              <v-card-text class="pb-0 mt-5">
                  <v-container style="max-width: 1400px;">
                      <v-form v-model="valid" ref="form" lazy-validation>
                          <v-row class="d-flex justify-center">
                              <v-col cols="12" sm="10" md="10" class="d-flex align-center justify-end">
                                  <h3 class="title-font1 mr-3 d-flex justify-end">Уровень доступа</h3>
                                  <v-autocomplete
                                  class="title-font"
                                  placeholder="Выберите уровень доступа"
                                  :disabled="getUser.login != 'MzGvs_Admin' && listRoleGroups.length != 0"
                                  :rules="[rules.required]"
                                  hide-details
                                  @change="changeRoles(objUser.roleGroup[0])"
                                  item-text="name" item-value="id"
                                  :items="listRoleGroups"
                                  return-object
                                  dense
                                  outlined
                                  v-model="objUser.roleGroup[0]" />
                              </v-col>
                              <v-col cols="12" sm="10" md="10" class="d-flex align-center">
                                  <h3 class="title-font1 mr-3 d-flex justify-end">Роль</h3>
                                  <v-autocomplete
                                  class="title-font"
                                  @change="changeSpec()"
                                  :placeholder=" !objUser.roleGroup[0] ? 'Выберите уровень доступа для разблокировки' : 'Выберите роль'"
                                  :disabled=" getUser.login === 'CEKKMP_Admin' ? true : !objUser.roleGroup[0] ? true : false"
                                  :rules="[rules.required]"
                                  hide-details
                                  :items="listRoles"
                                  item-text="name"
                                  item-value="id"
                                  dense
                                  outlined
                                  v-model="objUser.roles[0]" />
                              </v-col>
                              <v-col v-if="showSpec" cols="12" sm="10" md="10" class="d-flex align-center">
                                  <h3 class="title-font1 mr-3 d-flex justify-end">Специальность</h3>
                                  <v-autocomplete
                                  class="title-font"
                                  :placeholder="'Выберите специальность'"
                                  :rules="[rules.required]"
                                  hide-details
                                  :items="profilesList"
                                  item-text="name"
                                  item-value="id"
                                  dense outlined
                                  v-model="objUser.profiles[0]" />
                              </v-col>
                              <v-col v-if="showDep" cols="12" sm="10" md="10" class="d-flex align-center">
                                  <h3 class="title-font1 mr-3 d-flex justify-end">Департамент</h3>
                                  <v-autocomplete
                                  class="title-font"
                                  :rules="[rules.required]"
                                  :placeholder="'Выберите департамент'"
                                  hide-details
                                  :items="departmentList"
                                  item-text="name"
                                  item-value="id"
                                  dense
                                  outlined
                                  v-model="objUser.department" />
                              </v-col>
                              <v-col cols="12" sm="10" md="10" class="d-flex align-center">
                                  <h3 class="title-font1 mr-3 d-flex justify-end">ФИО</h3>
                                  <v-text-field
                                  class="title-font"
                                  :rules="[rules.required]"
                                  :placeholder="'Введите ФИО'"
                                  hide-details
                                  dense
                                  outlined
                                  v-model="objUser.fullName" />
                              </v-col>
                              <v-col cols="12" sm="10" md="10" class="d-flex align-center">
                                  <h3 class="title-font1 mr-3 d-flex justify-end">E-mail</h3>
                                  <v-text-field
                                  class="title-font"
                                  :placeholder="'Введите E-mail'"
                                  :rules="[rules.email, rules.required]"
                                  dense
                                  hide-details="auto"
                                  outlined
                                  v-model="objUser.email" />
                              </v-col>
                              <v-col cols="12" sm="10" md="10" class="d-flex align-center">
                                  <h3 class="title-font1 mr-3 d-flex justify-end">Логин</h3>
                                  <v-text-field
                                  class="title-font"
                                  :placeholder="'Введите логин'"
                                  :rules="[rules.required]"
                                  hide-details
                                  dense
                                  outlined
                                  v-model="objUser.login" />
                              </v-col>
                              <v-col cols="12" sm="10" md="10" class="d-flex align-center">
                                  <h3 class="title-font1 mr-3 d-flex justify-end">Уполномочен подписывать документы</h3>
                                  <v-switch
                                  v-model="objUser.signEnabled"
                                  color="#B6DFF0"
                                   @change="enabled(objUser.id)"
                                  :readonly="objUser.id === getUser.id"
                                  :label="objUser.signEnabled ? 'Да' : 'Нет'"
                                  hide-details>
                                  </v-switch>
                              </v-col>
                              <v-col cols="12" sm="10" md="10" class="d-flex align-center">
                                  <h3 class="title-font1 mr-3 d-flex justify-end">Открытая часть ЭЦП</h3>
                                  <div v-if="objUser.id && objUser.signEnabled">
                                    <button  @click.prevent="openFileDownloadDialog(objUser)" class="btn-file">Добавить файл</button>
                                    <span v-if="objUser.hasSignatureFile" class="ml-3">{{files[0] ? files[0].name : ''}}</span>
                                  </div>
                                  <span v-else>Для загрузки ЭЦП пользователь должен быть сохранен и уполномочен подписывать</span>
                              </v-col>
                          </v-row>
                      </v-form>
                  </v-container>
              </v-card-text>

              <v-card-actions class="pb-5 mr-4 d-flex justify-center">
                <button class="btn"  @click="save(objUser)">Сохранить</button>
                <button  class="ma-2 btn-add" outlined  @click="close">Отменить</button>
              </v-card-actions>
          </v-card>
      </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'

export default {
  name: 'SMP',
  data () {
    return {
      sortValue: {
        column: null,
        ask: false,
      },
      snackbar: false,
      dialog: false,
      edite: false,
      files:[],
      listRoleGroups: [],
      listRoles: [],
      snackbar_text: '',
      objUser:{
        department: null,
        email: "",
        fullName: "",
        id: "",
        isBlocked: false,
        login: "",
        name: null,
        profiles: [],
        roleGroup:[],
        roles: [],
        signEnabled: false,
        hasSignatureFile: false
      },
      titleDialog: '',
      valid: '',
      profilesList:[],
      departmentList: [],
      snackbarColor: '',
      flagSpec: false,
      title: 'Назначение уполномоченных',
      filter: {},
      params: {
                filters: [],
                sortOption: {
                    fieldName: 'name',
                    sortType: 2
                },
                pageSize: 9999,
                currentPage: 0,
                useANDoperator: false,
                columns: []
            },
      currentUser: null,
      headers: [
        { text: 'Роль', value: 'roles', input: '', show: true},
        { text: 'ФИО', value: 'fullName', input: '' , show: true },
        { text: 'Логин', value: 'login' , input: '' , show: true},
        { text: 'Уполномочен подписывать документы', value: 'signEnabledStr' , input: '' , show: true},
        { text: 'Открытая часть ЭЦП', value: 'hasSignatureFile' , show: true},
        { text: 'Действия' , show: true},
      ],
      rules: {
        required: (v, text) => !!v || (text ? text : 'Обязательно для заполнения.'),
        email: v => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(v) || 'Некорректный e-mail.'
            }
      }
    }
  },
  computed : {
    ...mapGetters({
      getUsers: 'commiss/getUsers',
      getUser: 'login/getUser',
      apiUrl: 'apiUrl'
    }),
    showSpec(){
      if (this.objUser.roles[0] === '90262588-05ee-4d3d-bc16-1e171be0bd7f') {
        return true
      }
      return false
    },
    showDep(){
      if (this.objUser.roles[0] && (this.objUser.roles[0] != '90262588-05ee-4d3d-bc16-1e171be0bd7f' && this.objUser.roles[0].id != 'ea6fc0ee-564d-416e-84f8-c9349f44ad3d' && this.objUser.roles[0] != 'ea6fc0ee-564d-416e-84f8-c9349f44ad3d' && this.objUser.roles[0].id != '2918a430-a486-4956-8728-fcbbafdedec6')) {
        return true
      }
      return false
    },
  },
  methods: {
    ...mapActions({
      userFilter: 'commiss/userFilter',
      changeEnabled: 'commiss/changeEnabled',

    }),
    ...mapMutations({
      setSearch: 'commiss/setSearch',
      setSort: 'commiss/setSort'
    }),
    sortArchive(column) {
      console.log(column);
        this.headers.map(item => {
          item.sort = item.value === column
          if (item.value === this.sort) {
            item.revers = !item.revers
          } else {
            item.revers = false
          }
        })
        this.sort = column
        this.setSort(column)
      },
      close(){
        this.dialog = false
        this.edite = false
        this.$refs.form.resetValidation()
        this.objUser = {
        email: "",
        fullName: "",
        isBlocked: false,
        login: "",
        name: null,
        roleGroup:[],
        profiles: [],
        roles: [],
        signEnabled: false,
        hasSignatureFile: false
      }
      },
      createUser(){
        if (this.getUser.login != 'MzGvs_Admin') {
            this.objUser.roleGroup.push(this.listRoleGroups[0].id)
            this.objUser.roles.push(this.listRoles[0].id)
        }
        this.dialog = true
        this.titleDialog = 'Создание'
      },
      updateUser(user){
        console.log(user);
        this.edite = true
        this.$http.get(`${this.apiUrl}SignUser/${user.id}`)
			.then((res) => {
        this.listRoles.push(res.data.roles[0])
        this.listRoleGroups.push(res.data.roleGroup)
        Object.assign(this.objUser, {...res.data, roleGroup: [res.data.roleGroup], profiles: res.data.profiles.map(m => m.id), roles: res.data.roles.map(m => m.id)})
        console.log(this.objUser);
			})
         this.dialog = true
        this.titleDialog = 'Редактирование'
      },
      changeRoles(item){
        this.objUser.roles = []
        this.listRoles = []
        this.listRoles = item.roles
      },
    snackBarFunc(flag, text, color) {
        this.snackbar = flag
        this.snackbar_text = text
        this.snackbarColor = color
    },
    disableUser(user){
      let isBlocked = !user.isBlocked;
      this.$http.put(`${this.apiUrl}Users/BLock/${user.id}?block=${isBlocked}`)
			.then((res) => {
          console.log(res);
          user.isBlocked = !user.isBlocked;
          this.snackBarFunc(true, user.isBlocked ? 'Пользователь заблокирован!' : 'Пользователь разблокирован!' , 'warning')
			})
    },
    save(obj){
      console.log(obj);
      if (!this.$refs.form.validate()) {
        this.snackBarFunc(true, 'Заполните обязательные поля', 'error')
      }
      else {
        if (!this.edite) {
          this.$http.post(`${this.apiUrl}SignUser`, {...obj, roleGroupId:obj.roleGroup[0].id, departmentId:obj.department ? obj.department.id : null, profiles: obj.profiles.map(m => m)})
            .then((res) => {
              if (res.data.success) {
                this.close()
                this.snackBarFunc(true, 'Пользователь успешно сохранен!', 'success')
                this.userFilter(this.filter)
              } else {
                this.snackBarFunc(true, res.data.userMessage, 'error');
              }
            });
        }
        else {
          this.$http.put(`${this.apiUrl}SignUser/${obj.id}`, {...obj, roleGroupId: obj.roleGroup[0].id, departmentId:obj.department ? obj.department.id : null, profiles: obj.profiles.map(m => m.id) })
            .then((res) => {
              if (res.data.success) {
                this.close()
                this.snackBarFunc(true, 'Пользователь успешно отредактирован!', 'success')
                this.userFilter(this.filter)
              } else {
                this.snackBarFunc(true, res.data.userMessage, 'error');
              }
            });
        }
      }
    },
    uploadFile(event, user) {
      console.log(event, user);

            let file = event.target.files[0];
            this.files = event.target.files
            if(!file) {
                this.snackBarFunc(true, 'Произошла ошибка при загрузке файла, обратитесь к Администратору!', 'warning')
                return;
            }

            let fData = new FormData();

            fData.append('file', file);

            this.$http.post(`${this.apiUrl}SignUser/${user.id}/AddSignature`, fData)
                .then((res) => {
                    if(res.data.success) {
                        user.hasSignatureFile = true;
                        this.snackBarFunc(true, 'Подпись успешно загружена!', 'success')
                        this.$refs.fileAssign.value = null;
                        return;
                    }
                    this.snackBarFunc(true, 'Произошла ошибка при загрузке подписи, обратитесь к Администратору!', 'error')
                })
                .catch((error) => {
                    if(error) {
                        user.signEnabled = !user.signEnabled;
                        this.snackBarFunc(true, 'Произошла ошибка при загрузке подписи, обратитесь к Администратору!', 'error')
                    }
                })


            // console.log(file, user);
        },
    openFileDownloadDialog(user) {
      console.log(user);
            this.currentUser = user;
            this.$refs.fileAssign.click()
        },
        downloadECP(user) {
          this.$http.get(`${this.apiUrl}SignUser/${user.id}/DownloadSignature`, { responseType: 'blob' })
			.then((res) => {
                let blob = new Blob([res.data], {type: "application/octet-stream"}),
                    fileURL = URL.createObjectURL(blob),
                    link = document.createElement("a");

                link.download = 'Открытая часть ЭЦП.cer';
                link.href = fileURL;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.snackBarFunc(true, 'Файл успешно загружен!', 'success')
			})
			.catch(() => {
                this.snackBarFunc(true, 'Ошибка при выгрузке файла, обратитесь к Администратору!', 'error')
                this.$notify({ group: 'foo', duration: 5000, type: 'error', title: 'Ошибка', text: 'Ошибка при выгрузке файла, обратитесь к Администратору!' })
            })
        },
    enabled(id){
      if (id) {
        this.changeEnabled(id)
      }
    }
  },
  mounted() {
    if (this.getUser.login === 'MzGvs_Admin') {
      this.headers[0].show = true
    }
  this.userFilter(this.filter)
  .then((res) => {
    console.log(res);
  })
  this.$http.get(`${this.apiUrl}Lookups/RoleGroups`)
			.then((res) => {
        this.listRoleGroups = res.data
        if (this.getUser.login != 'MzGvs_Admin') {
          for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < res.data[i].roles.length; j++) {
            this.listRoles.push(res.data[i].roles[j])
          }
        }
        }
        console.log(this.listRoleGroups,this.listRoles);

			})
      this.$http.post(`${this.apiUrl}Profile/GetAll`, this.params)
			.then((res) => {
        this.profilesList = res.data.data
			})
      this.$http.get(`${this.apiUrl}Lookups/Departments`)
			.then((res) => {
        console.log(res.data);
        this.departmentList = res.data
			})

  }
}
</script>

<style lang="scss" scoped>
  @import "~@/scss/vars.scss";
  @import "~@/scss/mixins.scss";
  @import "./style.scss";
</style>
