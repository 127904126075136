<template>
  <div class="products">
    <h3 class="sub-title">Примечания</h3>
    <v-skeleton-loader
     v-if="!load"      
      class="mx-auto"
      type="table-tbody"
    ></v-skeleton-loader>
    <table class="table" v-else>
      <thead>
        <tr>
          <th
            v-for="th in header"
            :key="th"
            class="text-center">
            {{th}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(tr, index) in getNotesTypeTwo"
          :key="index"
        >
          <td v-for="(td, i) in tr" :key="i"  class="text-center" >
            <div class="nothint">
              {{ td }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'Notes',
    data () {
      return {
        load: true,
        header: [
          '№',
          'Примечание',
        ],
      }
    },

    computed : {
      ...mapGetters({
          getNotesTypeTwo: 'smp/getNotesTypeTwo',
      }),
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/scss/vars.scss";
  @import "~@/scss/mixins.scss";
</style>
