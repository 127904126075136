<template>
  <div class="page">
    <h2 class="main-title">№ {{getTitle}}</h2>
    <SubMenu/>
    <div class="header">
      <h3 class="sub-title">
        Документы
      </h3>
      <div class="button-box">
        <v-btn
          v-if="($store.state.login.canModifySmp && ($store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment || $store.state.login.administrator || $store.state.login.gbs || $store.state.login.cekkmp))"
          color="red"
          class="ma-2 white--text"
          @click="openDialog()"
        >
        <v-icon
          left
          dark
        >
        mdi-plus
        </v-icon>
        Добавить
        </v-btn>
        <v-btn
          color="white"
          class="ma-2 red--text"
          @click.prevent="downLoadAll()"
        >
        <v-icon
          left
          dark
        >
          mdi-cloud-upload
        </v-icon>
          Скачать все
        </v-btn>
      </div>
    </div>
    <div>
      <v-skeleton-loader
         v-if="!load"
          class="mx-auto"
          type="table-tbody"
        ></v-skeleton-loader>
        <table v-else class="table table-white">
          <thead>
            <tr>
              <th
                v-for="th in header"
                :key="th"
                class="text-center">
                {{th}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="td in getDocuments"
              :key="td.id"
            >
              <td class="text-center">{{ td.createdOn | moment('DD.MM.YYYY') }}</td>
              <td class="text-center">{{ td.creatorFullName }}</td>
              <td class="text-center">
                <Certificate v-if="td.signature" :file=td />
                {{ td.name }}
              </td>
              <td class="text-center">{{ td.fileTypeName }}</td>
              <td class="text-center">
                  <v-icon color="blue" size="16px" @click.prevent="downloadItem(td.id, td.name)" >
                    mdi-file-download-outline
                  </v-icon>
                  <v-icon
                    v-if="$store.state.login.canModifySmp"
                    size="16px"
                    color="red"
                    @click.prevent="removeFile = td.id; dialog = true">
                    mdi-trash-can-outline
                  </v-icon>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
    <v-dialog
      v-model="dialog"
      width="500"
      >

      <v-card>
        <v-card-title class="text-h5">
          Вы действительно хотите удалить файл?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            class="ma-2 red--text"
            @click="dialog = false"
          >
            Нет
          </v-btn>
          <v-btn
            color="red"
            class="ma-2 white--text"
            @click.prevent="remove(removeFile)"
          >
            Да
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDoc"
      width="500"
      >

      <v-card>
        <v-card-title class="text-h5">
          Добавить файл
        </v-card-title>
        <v-card-text>
          <v-row >
            <v-col cols="4" sm="12" md="12">
              <v-select
                item-value="id"
                item-text="name"
                :items="getListType"
                :rules="[rules.required]"
                v-model="typeDoc"
                dense
                hide-details=""
                outlined
                class="input"
                placeholder="Тип документа"
              >
              </v-select>
            </v-col>
            <v-col cols="4" sm="12" md="5">
              <v-btn
                color="red"
                class="white--text select-file"
              >
                <label for="file">
                  <v-icon
                    left
                    dark
                  >
                    mdi-plus
                  </v-icon>
                  Выбрать файл
                </label>
                <input type="file" id="file" ref="file" class="file" @change="fileSelected()">
              </v-btn>
            </v-col>
            <v-col cols="4" sm="12" md="7" class="filename">
              {{ fileName }}
            </v-col>
        </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            class="ma-2 red--text"
            @click="close()"
          >
            Отменить
          </v-btn>
          <v-btn
            color="red"
            class="ma-2 white--text"
            @click="handleFile()"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CertificatesDialog
      ref="certificatesDialog"
      :signature="$store.state.login.user.signature"
      @selected="signDocument()"
      @signed="(files) => documentSigned(files)"
      @message="(x) => $refs.snackbar.show(x.type, x.text, x.timeout)"
    />
    <Pagination v-if="getDocuments.length > 20"/>
    <TimeoutSnackbar ref="snackbar" />
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination/Pagination.vue'
  import SubMenu from '@/components/SubMenu/SubMenu.vue'
  import CertificatesDialog from '@/components/Certificate/Dialog.vue';
  import Certificate from '@/components/Certificate/Icon.vue';
  import TimeoutSnackbar from '@/components/TimeoutSnackbar/TimeoutSnackbar.vue';
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'Documents',
    components: {
      Pagination,
      SubMenu,
      CertificatesDialog,
      Certificate,
      TimeoutSnackbar
    },
    data () {
      return {
        dialog: false,
        dialogDoc: false,
        typeDoc: '',
        fileName: null,
        removeFile: null,
        header: [
          'Дата загрузка',
          'Автор документа. ФИО',
          'Наименование документа',
          'Тип',
          'Действия',
        ],
        load: false,
        Images: null,
        rules: {
          required: (v, text) => !!v || (text ? text : 'Обязательно для заполнения.'),
          requiredMultiple: (v) => v.length > 0 || 'Обязательно для заполнения.',
        }
      }
    },
    computed: {
      ...mapGetters({
        getDocuments: 'documents/getDocuments',
        getTitle: 'smp/getTitle',
        getListType: 'documents/getListType',
        getcanBeSentForMeo: 'smp/getcanBeSentForMeo'
      }),
    },
    methods: {
      ...mapActions({
        documents: 'documents/documents',
        standart: 'smp/standart',
        getDocument: 'documents/getDocument',
        createDocument: 'documents/createDocument',
        removeDocuments: 'documents/removeDocuments',
        getType: 'documents/getType'
      }),
      downloadItem(id, name) {
        this.getDocument({ id: id }).then(res => {
          this.forceFileDownload(res, name)
        }).catch(console.error)
      },
      forceFileDownload(res, name){
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.setAttribute('download', name); //or any other extension
        document.body.appendChild(link);
        link.click();
      },
      downLoadAll() {
        this.getDocuments.map(item => {
          this.downloadItem(item.id, item.name)
        })
      },
      openDialog() {
        this.dialogDoc = true;
      },
      fileSelected() {
        if (this.$refs.file.files.length > 0) {
          this.fileName = this.$refs.file.files[0].name;
        }
      },
      close() {
        this.getType()
        this.dialogDoc = false;
        this.$refs.file.value = null;
        this.typeDoc = '';
      },
      handleFile() {
        let type = this.getListType.find(x => x.id === this.typeDoc);

        if (!type) {
          this.$refs.snackbar.show('error', 'Не выбран тип файла');
          return;
        }

        if (this.$refs.file.files.length === 0) {
          this.$refs.snackbar.show('error', 'Не выбран файл');
          return;
        }

        if (!this.getcanBeSentForMeo && type.id.toUpperCase() === '7A8893F7-4FD5-4D24-8ED9-AEF6107EBCDC') {
          this.$refs.snackbar.show('error', 'Документ МЭО не может быть прикреплен');
          return;
        }

        if (type.signRequired) {
          this.openCertsDialog();
        } else {
          this.uploadFile();
        }
      },
      uploadFile(signature) {
        this.createDocument({
          StandartId: this.$route.params.id,
          FileTypeId: this.typeDoc,
          File: this.$refs.file.files[0],
          Signature: signature
        }).then(response => {
          if (response.data.success) {
            this.documents(this.$route.params.id);
            this.close();
          } else {
            this.$refs.snackbar.show('error', response.data.userMessage);
          }
        })
      },
      openCertsDialog() {
        this.$refs.certificatesDialog.open();
      },
      signDocument() {
        let files = [{
          object: this.$refs.file.files[0],
          signature: null
        }];

        this.$refs.certificatesDialog.sign(files);
      },
      documentSigned(files) {
        this.uploadFile(files[0].signature);
      },
      remove(id) {
        this.removeDocuments(id).then(() => {
          this.documents(this.$route.params.id)
          this.dialog = false
        })
      }
    },
    mounted() {
      this.standart(this.$route.params.id).then(() => this.load = true)
      this.documents(this.$route.params.id)
      this.getType()
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/scss/vars.scss";
  @import "~@/scss/mixins.scss";
  @import "./style.scss";
</style>
