export function parseFio(signer) {
    return parse(signer, /CN=(.*?)(?=$|,|;)/);
}

export function parseCompany(signer) {
    return parse(signer, /O="?(.*?)"?(?=$|,|;)/).replaceAll('""', '"');
}

function parse(signer, pattern) {
    let matches = signer.match(pattern);
    if (matches && matches.length > 1) return matches[1];
    return '';
}
