<template>
  <v-container>
    <v-row justify="end">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <button class="btn btn-help" @click="dialogHelp = true">Помощь</button>
          <button
            v-bind="attrs"
            v-on="on"
            class="btn btn-enter"
          >
            Вход
          </button>
        </template>
        <v-card class="openform">
          <div class="close" @click="dialog = false"></div>
          <div class="avtorization" v-if="restoreOpen === false">
            <h2 class="title">Авторизация</h2>
            <label class="label" for="name">
              Логин
            </label>
            <div class="input-box">
              <input
              id="name"
              class="input"
               v-model="name"
              required/>
             </div>
             <label class="label" for="password">
               Пароль
             </label>
             <p
             :class="{message: true, error: authStatus == 'error' }"
             v-if="authStatus == 'error'">Неправильный логин или пароль</p>
             <p
             :class="{message: true, error: authStatus == 'blocked' }"
             v-if="authStatus == 'blocked'">Вы заблокированны</p>
             <div class="input-box">
               <input
               id="password"
               class="input"
               :type="passwordFieldType"
                v-model="password"
               required/>
               <button type="password" class="input-btn" @click="switchVisibility">
               </button>
             </div>
            <div class="button-box">
              <span @click="restoreOpen = true" class="restore-link">Забыли пароль?</span>
              <button
                class="btn-login"
                @click="logined()"
              >
                Вход
              </button>

            </div>
          </div>
          <div class="restore-token" v-if="restoreOpen === true">
            <h2 class="title">Восстановление пароля</h2>
            <label class="label" for="email">
              Укажите E-mail
            </label>
            <div class="input-box">
              <input
              id="email"
              class="input"
              type="email"
               v-model="restorPassword.email"
              required/>
            </div>
            <div class="button-box">
              <span @click="restoreOpen = false" class="restore-link">назад</span>
              <button
                class="btn-login"
                @click="restorTokenButton()"
              >
                Отправить код
              </button>
            </div>
            <p :class="{message: true, error: mailError }" v-if="getRestoreMessage">{{getRestoreMessage}}</p>
          </div>
          <div class="restore-token" v-if="restoreOpen == null">
            <h2 class="title">Восстановление пароля</h2>
            <label class="label" for="token">
              Код подтверждения
            </label>
            <div class="input-box">
              <input
              id="token"
              class="input"
              type="text"
               v-model="restorPassword.token"
              required/>
            </div>
            <label class="label" for="new-password">
              Укажите новый пароль
            </label>
            <div class="input-box">
              <input
              id="new-password"
              class="input"
              :type="passwordFieldType"
               v-model="newPassword"
              required/>
            </div>
            <label class="label" for="confirm-password">
              Подтвердите пароль
            </label>
            <div class="input-box">
              <input
              id="confirm-password"
              class="input"
              :type="passwordFieldType"
               v-model="restorPassword.password"
              required/>
            </div>
            <div class="button-box">
              <span @click="restoreOpen = false" class="restore-link">назад</span>
              <button
                class="btn-login"
                @click="restorPasswordButton()"
              >
                Отправить
              </button>
            </div>
            <p
            :class="{message: true, error: tokenError }"
            v-if="getPasswordMessage || noConfirmPassword">{{getPasswordMessage || noConfirmPassword}}</p>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog
          v-model="dialogHelp"
          max-width="400"
        >
          <v-card>
            <v-card-title class="text-h5">
              Помощь
            </v-card-title>

            <v-card-text>
              Служба технической поддержки: <br>
              8 (495) 627-29-61 | +7 (901) 756-94-65 <br>
              <a href="mailto:support-std@minzdrav.gov.ru">support-std@minzdrav.gov.ru</a><br>
              Часы работы: 09:00-18:00 (МСК)
            </v-card-text>
          </v-card>
        </v-dialog>
  </v-container>
</template>
<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    data: () => ({
      dialog: false,
      dialogHelp: false,
      newPassword: '',
      restorPassword: {
        token: '',
        password: '',
        email: ''
      },
      tokenError: false,
      noConfirmPassword: null,
      restoreOpen: false,
      mailError: false,
      name: '',
      password: '',
      passwordFieldType: 'password'
    }),
    computed : {
      ...mapGetters({
        getRestoreMessage: 'login/getRestoreMessage',
        getPasswordMessage: 'login/getPasswordMessage',
        authStatus: 'login/authStatus'
      }),
    },
    methods: {
      ...mapActions({
        smpFilter: 'smp/smpFilter',
        login: 'login/login',
        user: 'login/user',
        restoreToken: 'login/restoreToken',
        restorePasword: 'login/restorePasword',
        bell: 'bell/getBells',
        countBells: 'bell/countBells',
      }),
      switchVisibility () {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      },
      logined() {
        this.login({'login': this.name, 'password': this.password}).then((res) => {
          this.dialog = false
          console.log(res);
          this.user().then((res) => {
            if (res.data.login === "MzGvs_Admin" || res.data.login === "CEKKMP_Admin" ) {
              this.$router.push('/commiss1')
            }
          })
          this.smpFilter({})
          this.bell()
          this.countBells()
        })
      },
      restorTokenButton() {
        this.restoreToken(this.restorPassword.email).then(() => {
          this.restoreOpen = null
        }).catch(err => {
          console.log(err)
          this.mailError = true
				})
      },
      restorPasswordButton() {
        if (this.restorPassword.password === this.newPassword) {
          this.mailError = false
          this.restorePasword(this.restorPassword).then(() => {
            this.tokenError = false
            this.noConfirmPassword = null
            this.restoreOpen = false
          }).catch(err => {
            console.log(err)
            this.tokenError = true
          })
        } else {
          this.tokenError = true
          this.noConfirmPassword = "Пароли не совпадают"
        }
      },
    },
    created(){

    }
  }
</script>
<style lang="scss" scoped>
@import "~@/scss/vars.scss";
@import "~@/scss/mixins.scss";
@import "./style.scss";
</style>
