<template>
  <div class="page">
    <h2 class="main-title">{{title}}</h2>
    <div class="header">
      <nav class="filter">
        <span
          v-for="(link, index) in links"
          :key="link.index"
          @click="filter(link.value, index)"
          v-bind:class="{link: true, active: link.active}">
          {{link.name}}
        </span>
      </nav>
      <div class="button-box">
        <v-btn
          color="white"
          class="red--text"
          @click="downloadArchive()"
          v-bind:loading="load"
        >
        <v-icon
          left
          dark
        >
          mdi-download
        </v-icon>
          Скачать все
        </v-btn>
      </div>
    </div>
    <div class="responsive">
      <v-skeleton-loader
        v-if="load"
        class="mx-auto"
        type="table-tbody"
      ></v-skeleton-loader>
      <table class="table table-search table-link" v-else>
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              class="text-center">
              <span @click="sortArchive(header.value)">
                {{header.text}}
                <span v-if="header.sort" class="sort">
                  <v-icon
                  v-if="header.revers"
                  color="blue">
                    mdi-chevron-up
                  </v-icon>
                  <v-icon
                  v-else
                  color="blue">
                    mdi-chevron-down
                  </v-icon>
                </span>
              </span>
              <div class="search" v-if="header.search">
                <input type="text" v-model="header.input" @input="setSearch({column: header.value, string: header.input})">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(table, index) in getArchive"
            :key="index"
          >
            <td class="text-center">{{table.standartType}}</td>
            <td class="text-center">{{table.chainId}}</td>
            <td class="text-center">{{table.code}}</td>
            <td class="text-center">{{table.name}}</td>
            <td class="text-center">{{table.mkbCode}}</td>
            <td class="text-center">{{table.status}}</td>
            <td class="text-center">{{table.orderDate == "01.01.0001 0:00:00" ? '-' : table.orderDate}}</td>
            <td class="text-center">{{table.orderNumber}}</td>
            <td class="text-center">{{table.ageGroup}}</td>
            <td class="text-center">
              <div class="icons" @click="actionSaveArchive({id:table.id, name: table.name})">
                <v-icon
                color="blue">
                  mdi-download
                </v-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination">
      <ul v-if="getArchive.length >= 50">
        <li
          v-for="(item, index) in getPages"
          :key="index"
          @click="setPageArchive(index + 1)">
          <span>{{index + 1}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex'

  export default {
    name: 'Archive',
    data () {
      return {
        load: false,
        title: 'Архив',
        links: [
          {name: 'Все', value: '', active: true},
          {name: 'СМП', value: 'Стандарт', active: false},
          {name: 'Отчет', value: 'Отчет', active: false},
        ],
        sort: 'ChainID',
        headers: [
          {text: 'Тип документов', value: 'standartType', input: '', search: true, sort: false, revers: false },
          {text: 'ChainID', value: 'chainId', input: '', search: true, sort: false, revers: false },
          {text: 'Код', value: 'code', input: '', search: true, sort: false, revers: false },
          {text: 'Наименование файла', value: 'name', input: '', search: true, sort: false, revers: false },
          {text: 'Код МКБ-10', value: 'mkbCode', input: '', search: true, sort: false, revers: false },
          {text: 'Статус', value: 'status', input: '', search: true, sort: false, revers: false },
          {text: 'Дата приказа', value: 'orderDate', input: '' , search: true, sort: false, revers: false},
          {text: 'Номер приказа', value: 'orderNumber', input: '', search: true, sort: false, revers: false },
          {text: 'Возрастная категория', value: 'ageGroup', input: '', search: true, sort: false, revers: false },
          {text: 'Скачать', search: false},
        ],
      }
    },
    computed : {
      ...mapGetters({
          getArchive: 'archive/getArchive',
          getPages: 'archive/getPages',
          getFilterId: 'archive/getFilterId'
      }),
    },
    methods: {
      ...mapActions({
        actionArchive: 'archive/actionArchive',
        actionSaveArchive: 'archive/actionSaveArchive',
        actionSaveArchiveById: 'archive/actionSaveArchiveById',
      }),
      ...mapMutations({
        setPageArchive: 'archive/setPageArchive',
        setSearch: 'archive/setSearch',
        setSort: 'archive/setSort'
      }),
      filter(value, index) {
        this.links.map(item => item.active = false)
        this.links[index].active = true
        this.setSearch({column: 'standartType', string: value})
      },
      downloadArchive() {
        this.load = true
        this.actionSaveArchiveById(this.getFilterId)
            .then(() => {
                this.load = false
            })
      },
      sortArchive(column) {
        this.headers.map(item => {
          item.sort = item.value === column
          if (item.value === this.sort) {
            item.revers = !item.revers
          } else {
            item.revers = false
          }
        })
        this.sort = column
        this.setSort(column)
      }
    },
    created() {
        this.load = true;

        this.actionArchive()
            .then(() => this.load = false)
    }
  }
</script>
<style lang="scss" scoped>
  @import "~@/scss/vars.scss";
  @import "~@/scss/mixins.scss";
  @import "./style.scss";
</style>
