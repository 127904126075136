<template>
    <div>
    </div>
</template>
<script>
//import * as lights from './js/lights.js'
import * as crypto from './js/cadesplugin_api.js'
import * as code from './js/Code.js'
//import * as start from './js/start.js'
//import * as load from './js/load_extension.js'
//import * as async_code from './js/async_code.js'

export default {
    data() {
        return {
            message: `Hello ${crypto.cadesplugin.JSModuleVersion}!`,
            certsinfo: code.certsinfo
        }
    },
    methods:{
        checkReady(){
            // проверка, что все плагины загрузились и мы готовы подписывать
            return true;
        },
        getCerts(){
            // возвращает список доступных сертификатов
            return this.certsinfo.certs;
        },
        chooseCert(cert){
            this.certsinfo.selected.cert = cert.cert;
        },
        sign(data){
            data = 'abcde';
            code.Common_SignCadesBES(this.certsinfo.selected.cert, data).then(function(sertificate){
                // success
                alert('success: ' + sertificate);
            }, function (err){
                // fail
                alert('fail: ' + err);
            });
        },
        verify(signature, data){
            signature = 'MIIEygYJKoZIhvcNAQcCoIIEuzCCBLcCAQExDjAMBggqhQMHAQECAwUAMBQGCSqGSIb3DQEHAaAHBAVhYmNkZaCCAmcwggJjMIIBz6ADAgECAghQPX02OIHmEjAKBggqhQMHAQEDAzAdMQswCQYDVQQGEwJSVTEOMAwGA1UEAxMFYmFuZDQwHhcNMjMwNjA4MTIwMDQ4WhcNMjQwNjA4MTIwMDQ4WjAdMQswCQYDVQQGEwJSVTEOMAwGA1UEAxMFYmFuZDQwgaowIQYIKoUDBwEBAQIwFQYJKoUDBwECAQIBBggqhQMHAQECAwOBhAAEgYCZtM8G1PJIqzKmHzZbqUQwDSSreUsBb0T1L4ypp6NO9Th8APsg1jqgLof5eQYQfmujhDzMlpmSXgS0JQj2tuSaNxqwomVTfQoR3FV3G8WWX8UzKkEojc1eKYuD/JzO+DLslxUCHgosv+dPUNLFmH87A0uLS8nXaP54y0CPH0sogqOBpjCBozAOBgNVHQ8BAf8EBAMCAtwwHQYDVR0lBBYwFAYIKwYBBQUHAwIGCCsGAQUFBwMEMB0GA1UdDgQWBBQICkIbFTszOTa9oOjHr8XaDd5+UzBCBgNVHSMEOzA5gBQICkIbFTszOTa9oOjHr8XaDd5+U6EhpB8wHTELMAkGA1UEBhMCUlUxDjAMBgNVBAMTBWJhbmQ0MA8GA1UdEwQIMAYBAf8CAQEwCgYIKoUDBwEBAwMDgYEAfnjNB4qYtHYQ4wHrAL9CT9V8Ie7YOsW1mELJB6JAiRCVKsWmrV0zQga8/NGiEMdwewlLrTNLBhhZHNfd237VsIjOnhSYrCQwJYJSAVp2L962a2+8UTIBkKuMi+GW/xMEYo2ts+LuHoFif3hCUzLMRBMOkh2yStQSYYpxk+IZvwkxggIfMIICGwIBATApMB0xCzAJBgNVBAYTAlJVMQ4wDAYDVQQDEwViYW5kNAIIUD19NjiB5hIwDAYIKoUDBwEBAgMFAKCCAUwwGAYJKoZIhvcNAQkDMQsGCSqGSIb3DQEHATAcBgkqhkiG9w0BCQUxDxcNMjMwNjIxMTQxMzE3WjAqBgorBgEEAYI3WAIBMRwEGkQAbwBjAHUAbQBlAG4AdAAgAE4AYQBtAGUAME8GCSqGSIb3DQEJBDFCBEDIZ6p/OUb/EkfOk39JAjhx5ADdWOZhXchiWXwBi7nJUgBiC3BWJL0PhTUhV01qYnId56QzcZtAO2FzrXEPILIZMIGUBgsqhkiG9w0BCRACLzGBhDCBgTB/MH0wCgYIKoUDBwEBAgMEQG57wV3NeX1/Beh8V4N38IV6bcw62Tp3qDOuIEaOFDM3vlhQeIFHhwR/knHe8vUR/Eytbzaq9qRRxbpCCA7BaZ0wLTAhpB8wHTELMAkGA1UEBhMCUlUxDjAMBgNVBAMTBWJhbmQ0AghQPX02OIHmEjAKBggqhQMHAQEBAgSBgD4X5o7uI3q4XfONHmn6h0/e6x1h9ZeL2hq+dlHY9JqCCN5FlLHaWO86n9SawRZvTYJeBGkD9/YBU5kcm6GmO9fk/ekfsSDDPhmuNkcRiwfq4Cn5WQoRerPr2ul5IM7mhnWqHytK2DWmFpPdcDPaTxUIXzHhOJpCfI5mjrYwbxnU';
            data = 'abcde';
            code.Common_VerifyCadesBES(signature, data).then(function(){
                // success
            }, function (err){
                // fail
            });
        },
        run2(){
            this.certsinfo.selected.cert = this.certsinfo.certs[0].cert;
            //code.run();
            code.run2(this.certsinfo.selected.cert);
        },
        run3(){
            this.certsinfo.selected.cert = this.certsinfo.certs[0].cert;
            //code.run();
            code.run3(this.certsinfo.selected.cert);
        },
        run4(r){
            code.run4(r);
        },
        sign_async(data){
            return code.Common_SignCadesBES(this.certsinfo.selected.cert, data);
        },
        sign_file_async(file){
            return code.Common_SignCadesBES_File(this.certsinfo.selected.cert, file, true);
        },
        get_signature_info_async(signature){
            //console.debug('get_signature_info_async', signature);
            return code.GetSignatureInfo_Async(signature, true);
        },
        verify2(signature, data){
            signature = 'MIIEygYJKoZIhvcNAQcCoIIEuzCCBLcCAQExDjAMBggqhQMHAQECAwUAMBQGCSqGSIb3DQEHAaAHBAVhYmNkZaCCAmcwggJjMIIBz6ADAgECAghQPX02OIHmEjAKBggqhQMHAQEDAzAdMQswCQYDVQQGEwJSVTEOMAwGA1UEAxMFYmFuZDQwHhcNMjMwNjA4MTIwMDQ4WhcNMjQwNjA4MTIwMDQ4WjAdMQswCQYDVQQGEwJSVTEOMAwGA1UEAxMFYmFuZDQwgaowIQYIKoUDBwEBAQIwFQYJKoUDBwECAQIBBggqhQMHAQECAwOBhAAEgYCZtM8G1PJIqzKmHzZbqUQwDSSreUsBb0T1L4ypp6NO9Th8APsg1jqgLof5eQYQfmujhDzMlpmSXgS0JQj2tuSaNxqwomVTfQoR3FV3G8WWX8UzKkEojc1eKYuD/JzO+DLslxUCHgosv+dPUNLFmH87A0uLS8nXaP54y0CPH0sogqOBpjCBozAOBgNVHQ8BAf8EBAMCAtwwHQYDVR0lBBYwFAYIKwYBBQUHAwIGCCsGAQUFBwMEMB0GA1UdDgQWBBQICkIbFTszOTa9oOjHr8XaDd5+UzBCBgNVHSMEOzA5gBQICkIbFTszOTa9oOjHr8XaDd5+U6EhpB8wHTELMAkGA1UEBhMCUlUxDjAMBgNVBAMTBWJhbmQ0MA8GA1UdEwQIMAYBAf8CAQEwCgYIKoUDBwEBAwMDgYEAfnjNB4qYtHYQ4wHrAL9CT9V8Ie7YOsW1mELJB6JAiRCVKsWmrV0zQga8/NGiEMdwewlLrTNLBhhZHNfd237VsIjOnhSYrCQwJYJSAVp2L962a2+8UTIBkKuMi+GW/xMEYo2ts+LuHoFif3hCUzLMRBMOkh2yStQSYYpxk+IZvwkxggIfMIICGwIBATApMB0xCzAJBgNVBAYTAlJVMQ4wDAYDVQQDEwViYW5kNAIIUD19NjiB5hIwDAYIKoUDBwEBAgMFAKCCAUwwGAYJKoZIhvcNAQkDMQsGCSqGSIb3DQEHATAcBgkqhkiG9w0BCQUxDxcNMjMwNjIxMTQxMzE3WjAqBgorBgEEAYI3WAIBMRwEGkQAbwBjAHUAbQBlAG4AdAAgAE4AYQBtAGUAME8GCSqGSIb3DQEJBDFCBEDIZ6p/OUb/EkfOk39JAjhx5ADdWOZhXchiWXwBi7nJUgBiC3BWJL0PhTUhV01qYnId56QzcZtAO2FzrXEPILIZMIGUBgsqhkiG9w0BCRACLzGBhDCBgTB/MH0wCgYIKoUDBwEBAgMEQG57wV3NeX1/Beh8V4N38IV6bcw62Tp3qDOuIEaOFDM3vlhQeIFHhwR/knHe8vUR/Eytbzaq9qRRxbpCCA7BaZ0wLTAhpB8wHTELMAkGA1UEBhMCUlUxDjAMBgNVBAMTBWJhbmQ0AghQPX02OIHmEjAKBggqhQMHAQEBAgSBgD4X5o7uI3q4XfONHmn6h0/e6x1h9ZeL2hq+dlHY9JqCCN5FlLHaWO86n9SawRZvTYJeBGkD9/YBU5kcm6GmO9fk/ekfsSDDPhmuNkcRiwfq4Cn5WQoRerPr2ul5IM7mhnWqHytK2DWmFpPdcDPaTxUIXzHhOJpCfI5mjrYwbxnU';
            data = 'abcde';
            code.Common_VerifyCadesBES(signature, data).then(function(){
                // success
            }, function (err){
                // fail
            });
        },
        verify_file_async(signature, file){
            let sign = signature;
            return code.Common_VerifyCadesBES_File(sign, file, true);
        },
        verify_async(signature, data){
            return code.Common_VerifyCadesBES(signature, data, true);
        },
        verify_base64_async(signature, dataInBase64){
            return code.Common_VerifyCadesBES_Base64(signature, dataInBase64, true);
        }
    },
    mounted() {
        this.$root.$refs.A = this;
    }
}
</script>