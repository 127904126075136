import Vue from "vue";
import Vuex from "vuex";

import login from "./login";
import smp from "./smp";
import lookups from "./lookups";
import documents from "./documents";
import users from "./users";
import archive from "./archive";
import reports from "./reports";
import request from "./request";
import catalog from "./catalog";
import history from "./history";
import bell from "./bell";
import commiss from "./commis";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    api: "https://apistandart.minzdrav.gov.ru/",
    apiKr: "https://apicr.minzdrav.gov.ru/",
  },
  getters: {
    apiUrl: (state) => state.api,
    apiKr: (state) => state.api,
  },
  modules: {
    login: login,
    lookups: lookups,
    smp: smp,
    documents: documents,
    request: request,
    users: users,
    archive: archive,
    reports: reports,
    catalog: catalog,
    history: history,
    bell: bell,
    commiss: commiss,
  },
});
