<template>
  <div class="pasport">
    <h3 class="sub-title d-flex justify-space-between" v-if="!isEdit">
      <div>Паспортные данные</div>
      <div v-if="$store.state.smp.revisionDate && ($store.state.smp.statusEleven || $store.state.smp.statusFive)">Срок доработки: {{ $store.state.smp.revisionDate | moment('DD.MM.YYYY') }}</div>
    </h3>
    <h3 class="sub-title" v-else>Режим редактирования <span>Выберите параметр, для которого необходимо добавить сноску</span></h3>
    <v-skeleton-loader
     v-if="!load"
      class="mx-auto"
      type="table-tbody"
    ></v-skeleton-loader>
    <div class="table" v-else v-bind:class="{edit: isEdit}" :key="passportKey">
      <div class="table-row" v-for="pasport in getPasport" :key="pasport.label">
        <div class="label table-cell">{{pasport.label}}</div>
        <div class="value table-cell" @click="openNote({
            section: 'passportData',
            columnName: pasport.key,
            note: pasport.value.note ? pasport.value.note.name : ''
          })">
          <span>{{pasport.value ? pasport.value.value : ''}}</span>
          <div class="hint" v-if="pasport.value && pasport.value.note">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <!-- <v-icon
                  left
                  dark
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  x-small
                >
                  mdi-asterisk
                </v-icon> -->
                <span
                  v-bind="attrs"
                  v-on="on">[{{pasport.value.note.num}}]</span>
              </template>
              <span>[{{pasport.value.note.num}}] {{pasport.value.note.name}}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="redactor" v-if="!isEdit && $store.state.login.canModifySmp">
      <div class="button-box"
        v-if="
        (!($store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment  == true || $store.state.login.cekkmp)
        && ($store.state.smp.statusTwo || $store.state.smp.statusOne)) || !$store.state.login.guest">
        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: 'CB2D66F2-8F38-4047-ADA0-9F8CDE03391E',
            stageId: '0DD880C6-ACAA-4F97-9AA2-1E0AD441A5C9'
          }, 'Вы уверены, что хотите принять запрос на формирование проекта СМП?')"
          v-if="
          ($store.state.login.gbs || $store.state.login.administrator) && $store.state.smp.statusOne"
        >
          Принять запрос
        </v-btn>

        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="deleteDialog = true"
          v-if="($store.state.login.administrator || $store.state.login.cekkmp) && isDelete"
        >
          Удалить проект
        </v-btn>

        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: '1D403C69-4C12-4AD9-BAAA-3EA61F2EA684',
            stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
          }, 'Вы уверены?')"
          v-if="
          $store.state.login.administrator && ($store.state.smp.statusTree || $store.state.smp.statusFive) && getcanBeSentForMeo === true"
        >
          Отправить на согласование
        </v-btn>

        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openDocumentPopup('E75768B0-D4F7-44D8-8714-CE30E32AB36E')"
          v-if="
          ($store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment  == true || $store.state.login.administrator || $store.state.login.employee) && $store.state.smp.statusSix"
        >
          Отправить предложения по доработке
        </v-btn>

        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: '7928F146-8B99-4396-A503-01A50027E8AF',
            stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
          }, 'Вы уверены?')"
           v-if="$store.state.login.employee && $store.state.smp.statusNine"
        >
          Отправить
        </v-btn>

        <v-tooltip
          top
          v-if="($store.state.login.cekkmp || $store.state.login.administrator) && ($store.state.smp.statusTwelve || $store.state.smp.statusSixteen)"
          :disabled="getDocuments.some((f) => f.fileTypeId.toUpperCase() === '7A8893F7-4FD5-4D24-8ED9-AEF6107EBCDC')">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" class="d-inline-block">
              <v-btn
                v-bind="attrs"
                color="red"
                class="ma-2 white--text"
                :disabled="getDocuments.every((f) => f.fileTypeId.toUpperCase() !== '7A8893F7-4FD5-4D24-8ED9-AEF6107EBCDC')"
                @click="openPopup({
                  standartId: $route.params.id,
                  statusId: $store.state.smp.statusTwelve ? 'DD2F4743-310B-460A-89B9-298504425567' : '6EB8EC1D-3F7A-4EE4-8747-51A0FC3C54CA',
                  stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
                }, 'Вы уверены?')"
              >
                Отправить
              </v-btn>
            </div>
          </template>
          <span>
            Для отправки СМП загрузите документ "МЭО" в одноименную вкладку
          </span>
        </v-tooltip>

        <v-tooltip
          top
          v-if="$store.state.login.cekkmp && ($store.state.smp.statusTwo || $store.state.smp.statusThirteen || $store.state.smp.statusFourteen || $store.state.smp.statusSeventeen)"
          :disabled="!$store.state.smp.statusTwo || getDocuments.some((f) => f.fileTypeId.toUpperCase() === 'DE26DF44-8937-461B-A732-16954B7E739A')">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" class="d-inline-block">
              <v-btn
                v-bind="attrs"
                color="red"
                class="ma-2 white--text"
                :disabled="($store.state.smp.statusTwo || $store.state.smp.statusFourteen) && getDocuments.every((f) => f.fileTypeId.toUpperCase() !== 'DE26DF44-8937-461B-A732-16954B7E739A')"
                @click="openPopup({
                  standartId: $route.params.id,
                  statusId: 'FB6DE7D6-A278-4D69-91DD-102899B25AC2',
                  stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
                }, 'Вы уверены?')"
              >
                Отправить ГВС
              </v-btn>
            </div>
          </template>
          <span>
            Для отправки СМП загрузите документ "Проект СМП" в одноименную вкладку
          </span>
        </v-tooltip>

        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: '50DA9807-A277-49FF-94F3-4FF88DFE959E',
            stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
          }, 'Вы уверены?')"
          v-if="
          ($store.state.login.cekkmp || $store.state.login.administrator) && ($store.state.smp.statusEight)"
        >
          Предложения рассмотрены
        </v-btn>

        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: $store.state.login.gbs && ($store.state.smp.statusTree || $store.state.smp.statusFive) ? '1D403C69-4C12-4AD9-BAAA-3EA61F2EA684' : '7EF17CC8-295F-45A0-9BA9-03D9A6DCC331',
            stageId: '7E04AEB0-48C7-4880-838F-3D6DDD0724F2'
          }, 'Вы уверены, что хотите отправить на согласование проект стандарта?')"
          v-if="
          ($store.state.login.user.id === $store.state.smp.user || $store.state.login.mainDepartment == true || $store.state.login.gbs) &&
          ($store.state.login.gbs && ($store.state.smp.statusTree || $store.state.smp.statusFive)) || ($store.state.login.employee && ($store.state.smp.statusSix || $store.state.smp.statusTen))"
        >
         {{ $store.state.login.employee ? 'Утвердить проект' : 'Отправить на согласование' }}
        </v-btn>
        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: '2C970AF2-74D6-44CF-A546-72AFB38C37A2',
            stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
          }, 'Проект СМП необходимо доработать до:')"
          v-if="
          ($store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment == true || $store.state.login.administrator || $store.state.login.employee) && $store.state.smp.statusNine"
        >
          Отправить на доработку
        </v-btn>

        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openDocumentPopup('C4FC2C0E-7EC1-4004-92BF-2B04CA5FA564')"
          v-if="$store.state.login.gbs && ($store.state.smp.statusTree || $store.state.smp.statusFive)"
        >
          Отправить на доработку
        </v-btn>

        <v-btn
          color="red"
          class="ma-2 white--text"
          @click="openPopup({
            standartId: $route.params.id,
            statusId: 'A41BA4D1-7673-45D9-8BE7-45F5FBC53252',
            stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
          }, 'Вы уверены?')"
          v-if="
          ($store.state.login.cekkmp || $store.state.login.administrator) && $store.state.smp.statusEleven"
        >
          Проект доработан
        </v-btn>

        <v-btn
          color="white"
          class="ma-2 red--text"
          @click="setEditor()"
          v-if="
          ($store.state.login.cekkmp
          || $store.state.login.administrator)
            && ($store.state.smp.statusThirteen || $store.state.smp.statusTwo || $store.state.smp.statusEleven
            || $store.state.smp.statusFourteen || $store.state.smp.statusSeventeen )"
        >
        <v-icon
          left
          dark
        >
          mdi-pencil-outline
        </v-icon>
          Редактировать
        </v-btn>

        <v-btn
          color="white"
          class="ma-2 red--text"
          v-if="
          ($store.state.login.administrator || $store.state.login.cekkmp)
          && ($store.state.smp.statusThirteen || $store.state.smp.statusTwo|| $store.state.smp.statusEleven || $store.state.smp.statusFourteen || $store.state.smp.statusSeventeen)"
        >
          <label for="file">
            <v-icon
              left
              dark
            >
              mdi-file-code-outline
            </v-icon>
            Загрузить JSON
          </label>
        </v-btn>

        <input type="file" id="file" @change="saveJson" ref="file" class="file d-none" accept="application/JSON">
        <v-btn
          color="white"
          class="ma-2 red--text"
          v-if="
          ($store.state.login.administrator
            || $store.state.login.cekkmp)
          && ($store.state.smp.statusCanClone || $store.state.smp.statusSeventeen)"
          @click="openCloneStdWindow"
        >
          <label>
            <v-icon
              left
              dark
            >
              mdi-content-copy
            </v-icon>
            Создать проект стандарта
          </label>
        </v-btn>
        <input type="file" id="file2" @change="saveJsonAsCopy" ref="file2" class="file d-none" accept="application/JSON">
        <v-btn
        v-if="!$store.state.smp.statusZero && !$store.state.smp.statusOne && !$store.state.smp.statusTwo && !$store.state.smp.statusThirteen &&  !$store.state.smp.statusFourteen"
          color="white"
          class="ma-2 red--text"
          @click="generateFile({ id: $route.params.id, file: 'GenerateWord', sufix: '.Docx' })"
        >
        <v-icon
          left
          dark
        >
          mdi-microsoft-word
        </v-icon>
          Сформировать Word
        </v-btn>

        <!-- <v-btn
          color="white"
          class="ma-2 red--text"
          @click="generateFile({ id: $route.params.id, file: 'GenerateExcel', sufix: '.xls' })"
        >
          <v-icon
            left
            dark
          >
            mdi-microsoft-excel
          </v-icon>
          Сформировать Excel
        </v-btn> -->
      </div>
    </div>
    <div class="redactor-open" v-else-if="$store.state.login.canModifySmp">
      <div class="bitton-box">
        <v-btn
          color="white"
          class="ma-2 red--text"
          @click="setEditor()"
          v-if="
          $store.state.login.cekkmp
          || $store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment
          || $store.state.login.administrator"
        >
        <v-icon
          left
          dark
        >
          mdi-pencil-outline
        </v-icon>
          Сохранить
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="clonestddialog"
      max-width="558"
      class="dialog"
      >
      <v-card>
        <v-card-title class="text-h6" v-bind:class="{red: error}">
          {{dialogText}}
        </v-card-title>
        <v-spacer></v-spacer>

        <v-card-actions class='justify-space-between' v-if="ask">
          <v-btn
            color="red darken-1"
            text
            @click="clonestddialog = false"
          >
            Отменить
          </v-btn>

          <v-btn
            color="green darken-1"
            text
          >
            <label for="file2">
              <v-icon
                left
                dark
              >
                mdi-file-code-outline
              </v-icon>
              Создать
            </label>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="558"
      class="dialog"
      >
      <v-card>
        <v-card-title class="text-h6" v-bind:class="{ red: error }">
          Вы действительно хотите удалить проект?
        </v-card-title>
        <v-spacer></v-spacer>

        <v-card-actions class='justify-space-between'>
          <v-btn
            color="red darken-1"
            text
            @click="deleteDialog = false"
          >
            Отменить
          </v-btn>

          <v-btn
            color="green darken-1"
            @click="deletePopup($route.params.id)"
            text
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      max-width="400"
      class="dialog"
      >
      <v-card>
        <v-card-title class="text-h6" v-bind:class="{red: error}">
          {{dialogText}}
        </v-card-title>
        <v-spacer></v-spacer>

        <v-card-text v-if="query.statusId === '2C970AF2-74D6-44CF-A546-72AFB38C37A2'">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="revisionDate"
                label="Дата"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :min="currentDate"
              v-model="revisionDate"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>

        <v-card-actions class='justify-space-between' v-if="ask">
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            Отменить
          </v-btn>
          <v-btn
          v-if="(($store.state.login.gbs && ($store.state.smp.statusTree || $store.state.smp.statusFive)) || ($store.state.login.employee && $store.state.smp.statusSix)) && query.stageId === '783A93EA-AFF3-4D8F-91AC-5A690D4F4961' "
          color="green darken-1"
          text
        >
          <label for="file3">
            Загрузить
          </label>
        </v-btn>
        <input type="file" id="file3" @change="uploadFile" ref="file3" class="file">
          <v-btn
           v-if="query.statusId === 'CB2D66F2-8F38-4047-ADA0-9F8CDE03391E'"
            color="green darken-1"
            text
            @click="statusUpdate(query, false)"
          >
            Принять
          </v-btn>
          <v-btn
            v-else-if="query.statusId === '2C970AF2-74D6-44CF-A546-72AFB38C37A2'"
            color="green darken-1"
            text
            @click="saveData()"
          >
            Отправить
          </v-btn>
          <v-btn
            v-else-if="query.statusId === '7EF17CC8-295F-45A0-9BA9-03D9A6DCC331'"
            color="green darken-1"
            text
            @click="openOrderPopup()"
          >
            Утвердить
          </v-btn>
          <v-btn
            v-else-if="query.statusId === '1D403C69-4C12-4AD9-BAAA-3EA61F2EA684'"
            color="green darken-1"
            text
            @click="openCertsDialog()"
          >
            Отправить
          </v-btn>
          <v-btn
            v-else
            color="green darken-1"
            text
            @click="statusUpdate(query, true)"
          >
            Отправить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addNoteDialog"
      max-width="558"
      class="addnote"
      >
      <v-card>
        <v-card-title class="text-h6">
        </v-card-title>
        <v-spacer></v-spacer>
        <v-textarea
          solo
          name="input-7-4"
          label="Введите заметку"
        ></v-textarea>

        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="addNoteDialog = false"
          >
            Выбрать заметку из справочника
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="addNewNote(queryNote)"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogNote"
      max-width="558"
     >
      <v-card>

        <v-card-title class="text-h6">
          <div>Добавить заметку</div>

          <v-autocomplete
            v-if="searchNote"
            :items="getNotes2"
            label="Поиск по заметкам"
            v-model="queryNote.name"
          ></v-autocomplete>
        </v-card-title>

        <v-textarea
          name="textarea"
          class="textarea"
          outlined
          v-model="queryNote.name"
          label="Введите заметку"
        ></v-textarea>
        <v-card-actions>
          <v-btn
            color="primary darken-1"
            text
            @click="searchNote = !searchNote"
          >
            Выбрать заметку из справочника
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="addNewNote()"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="orderDialog.show" max-width="400" class="dialog">
      <v-card>
        <v-card-title class="text-h6" v-bind:class="{ red: error }">
          {{ dialogText }}
        </v-card-title>

        <v-spacer></v-spacer>

        <v-card-text>
          <v-text-field
            v-model="orderDialog.data.number"
            label="Номер"
          ></v-text-field>

          <v-menu
            v-model="orderDialog.datepicker.expand"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="orderDialog.data.date"
                label="Дата"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :min="currentDate"
              v-model="orderDialog.data.date"
              @input="orderDialog.datepicker.expand = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>

        <v-card-actions class='justify-space-between'>
          <v-btn
            color="red darken-1"
            text
            @click="closeOrderPopup()"
          >
            Отменить
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :loading="orderDialog.loading"
            @click="processConfirming()"
          >
            Утвердить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CertificatesDialog
      ref="certificatesDialog"
      :signature="$store.state.login.user.signature"
      @selected="signDocuments()"
      @signed="(files) => documentsSigned(files)"
      @message="(x) => $refs.snackbar.show(x.type, x.text, x.timeout)"
    />
    <TimeoutSnackbar ref="snackbar" />
  </div>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex';
  import CertificatesDialog from '@/components/Certificate/Dialog.vue';
  import TimeoutSnackbar from '@/components/TimeoutSnackbar/TimeoutSnackbar.vue';

  export default {
    name: 'Pasport',
    components: {
      CertificatesDialog,
      TimeoutSnackbar
    },
    data () {
      return {
        query: {
          statusId: null,
          stageId:null
        },
        deleteDialog: false,
        load: false,
        passportKey: 0,
        menu2: false,
        queryNote: {
          standartId: '',
          name: '',
          typeCode: 1,
          section: 'passportData',
          linkName: "",
          columnName: '',
          filterName: "",
          filterPage: '',
          filterFreq: "",
          filterCountSKD: "",
          filterUnit: "",
          filterSDD: "",
          filterATCCode: ""
        },
        searchNote: false,
        ask: false,
        currentDate: new Date().toISOString().split("T")[0],
        revisionDate: null,
        dialog: false,
        dialogNote: false,
        dialogText: 'Json успешно загружен',
        error: false,
        addNoteDialog: false,
        clonestddialog: false,
        orderDialog: {
          show: false,
          loading: false,
          datepicker: {
            expand: false
          },
          data: {
            number: null,
            date: null
          }
        }
      }
    },
    computed : {
      ...mapGetters({
          getPasport: 'smp/getPasport',
          getDocuments: 'documents/getDocuments',
          isDelete: 'smp/isDelete',
          isEdit: 'smp/isEdit',
          getId: 'smp/getId',
          getNotes2: 'lookups/getNotes2',
          getcanBeSentForMeo: 'smp/getcanBeSentForMeo'
      }),
    },
    methods: {
      ...mapMutations({
          setEditor: 'smp/setEditor'
      }),
      ...mapActions({
          deleteStandart: 'smp/deleteStandart',
          sendToReDev: 'smp/sendToReDev',
          standartConfirm: 'smp/standartConfirm',
          upLoadJson: 'smp/upLoadJson',
          standartUpdate: 'smp/standartUpdate',
          addSignature: 'documents/addSignature',
          createDocument: 'documents/createDocument',
          getDocument: 'documents/getDocument',
          documents: 'documents/documents',
          standart: 'smp/standart',
          generateFile: 'smp/generateFile',
          addNote: 'smp/addNote',
          notes: 'lookups/notes'
      }),
      deletePopup(id) {
        this.deleteDialog = false;

        this.deleteStandart(id)
          .then(response => {
            if (response.data.success) {
              this.$refs.snackbar.show('success', 'Проект удален');

              setTimeout(() => {
                  this.$router.replace('/')
              }, 1000);
            } else {
              this.$refs.snackbar.show('error', response.data.userMessage);
            }
          })
          .catch(() => {
            this.$refs.snackbar.show('error', 'Ошибка при удалении, обратитесь к администратору');
          });
      },
      openPopup(data, text) {
        console.log(data);
        this.ask = true
        this.dialog = true
        this.dialogText = text
        this.query = data
      },
      openDocumentPopup(statusId) {
        this.openPopup({
            standartId: this.$route.params.id,
            statusId: statusId,
            stageId: '783A93EA-AFF3-4D8F-91AC-5A690D4F4961'
          }, 'Перед отправкой проекта СМП на доработку рекомендуется загрузить документ с замечаниями в подраздел `Документы` текущей карточки СМП. Вы уверены что хотите отправить проект на доработку?')
      },
      openOrderPopup() {
        this.dialog = false;
        this.dialogText = 'Введите данные приказа Минздрава России';
        this.orderDialog.show = true;
        this.orderDialog.loading = false;
        this.orderDialog.data = {
          number: null,
          date: null
        };
      },
      closeOrderPopup() {
        this.orderDialog.show = false;
        this.orderDialog.loading = false;
        this.error = false;
      },
      uploadFile() {
        this.createDocument({
          StandartId: this.$route.params.id,
          File: this.$refs.file3.files[0],
          FileTypeId: 'BD9C09DE-6AF8-420B-A34E-A521D8DC4005'
        }).then(() => {
          this.documents(this.$route.params.id)
          console.log(this.query);
          this.dialogText = 'Файл успешно загружен'
        })
      },
      statusUpdate(data, checkUploadJSON){
        if (checkUploadJSON && !this.jsonUploaded()) {
          console.log(checkUploadJSON && !this.jsonUploaded());
          this.dialog = false;
          alert('Для начала загрузите JSON проект!');
          return;
        }

        this.standartUpdate(data).then(res => {
          this.ask = false
          this.dialog = true
          if (res.status == 200) {
            this.dialogText = 'Успешно'
            this.standart(this.$route.params.id)
            this.error = false
          } else {
            this.dialogText = `Ошибка: ${res.status}`
            this.error = true
          }
          setTimeout(() => {
            this.dialog = false
          }, 2000)
        }).catch(err => {
          this.dialogText = `${err}`
          this.error = true
          this.ask = false

          setTimeout(() => {
            this.dialog = false
          }, 2000)
        });
      },
      jsonUploaded() {
        for (let i = 0; i < this.getPasport.length; i++) {
          console.log(this.getPasport[i].value);
            if (!this.getPasport[i].value.value) {
                return false;
            }
        }

        return true;
      },
      openCertsDialog() {
        this.dialog = false;
        this.$refs.certificatesDialog.open();
      },
      signDocuments() {
        if (this.query.statusId === '1D403C69-4C12-4AD9-BAAA-3EA61F2EA684') {
          let documents = this.getDocuments.filter(x => x.fileTypeId.toUpperCase() === 'DE26DF44-8937-461B-A732-16954B7E739A');

          if (documents.length === 0) {
            this.$refs.snackbar.show('error', 'Нет файлов проекта');
            return;
          }

          let context = this;
          let files = [];

          Promise.all(documents.map(document => new Promise(function(resolve, reject) {
            context.getDocument({ id: document.id })
              .then(response => {
                files.push({
                  id: document.id,
                  object: new File([response.data], document.name),
                  signature: null
                });

                resolve();
              })
              .catch(error => reject(error));
          })))
          .then(() => {
            this.$refs.certificatesDialog.sign(files);
          })
          .catch(() => {
            this.$refs.snackbar.show('error', 'Ошибка при скачивании документа');
          });
        }
      },
      documentsSigned(files) {
        let context = this;

        Promise.all(files.map(file => new Promise(function(resolve, reject) {
          context.addSignature({ id: file.id, signature: file.signature })
            .then(response => {
              resolve(response);
            })
            .catch(() => {
              reject({ message: 'Ошибка при добавлении подписи к документу' });
            });
        })))
        .then((responses) => {
          let failure = responses.find(x => !x.data.success);

          if (!failure) {
            this.statusUpdate(this.query, true);
          } else {
            throw new Error(failure.data.userMessage);
          }
        })
        .catch(error => {
          this.$refs.snackbar.show('error', error.message);
        });
      },
      processConfirming() {
        if (!this.orderDialog.data.number || /^\s*$/.test(this.orderDialog.data.number)) {
          this.error = true;
          this.dialogText = 'Введите номер приказа';
          return;
        }

        if (!this.orderDialog.data.date) {
          this.error = true;
          this.dialogText = 'Введите дату приказа';
          return;
        }

        if (!this.jsonUploaded()) {
          this.closeOrderPopup();
          alert('Для начала загрузите JSON проект!');
          return;
        }

        this.orderDialog.loading = true;

        let data = {
          id: this.$route.params.id,
          orderNumber: this.orderDialog.data.number,
          orderDate: this.orderDialog.data.date
        };

        this.standartConfirm(data).then(res => {
          this.closeOrderPopup();
          this.dialog = true;
          this.ask = false;

          if (res.data.success) {
            this.error = false;
            this.dialogText = 'Успешно';
            this.standart(this.$route.params.id);
          } else {
            this.error = true;
            this.dialogText = `Ошибка: ${res.data.userMessage}`;
          }

          setTimeout(() => {
            this.dialog = false;
          }, 2000)
        }).catch(err => {
          this.closeOrderPopup();
          this.dialog = true;
          this.ask = false;
          this.error = true;
          this.dialogText = `${err}`;

          setTimeout(() => {
            this.dialog = false;
          }, 2000)
        });
      },
      saveJson() {
        console.log('yes');
        this.upLoadJson({StandartId: this.$route.params.id, File: this.$refs.file.files[0]}).then(res => {
          this.dialog = true;
          this.ask = false;
          if (res.status == 200) {
            if (res.data.success) {
              this.dialogText = 'Json успешно загружен'
              this.standart(this.$route.params.id)
              this.error = false
            } else {
              this.dialogText = res.data.userMessage;
              this.error = true;
            }
          } else {
            this.dialogText = 'Ошибка при загрузке'
            this.error = true
          }

          let delay = this.error ? 5000 : 2000;

          setTimeout(() => {
            this.dialog = false
          }, delay)
        })
      },
      openCloneStdWindow(){
        this.clonestddialog = true
        this.error = false
        this.ask = true
        this.dialogText = 'Кнопка "Создать проект стандарта" предназначена для загрузки еще одного проекта стандарта в данный запрос. \
          Вы уверены, что хотите загрузить еще один проект стандарта?'
      },
      saveData() {
        this.sendToReDev({ standartId: this.$route.params.id, date: this.revisionDate }).then(res => {
          this.ask = false
          if (res.status == 200) {
            this.dialogText = 'Успешно'
            this.standart(this.$route.params.id)
            this.error = false
          } else {
            this.dialogText = `Ошибка ${res.status}`
            this.error = true
          }
          setTimeout(() => {
            this.dialog = false
          }, 2000)
        }).catch(err => {
          this.dialogText = `${err}`
          this.error = true
          this.ask = false

          setTimeout(() => {
            this.dialog = false
          }, 2000)
        });
      },
      saveJsonAsCopy(){
        //clonestddialog
        this.upLoadJson({StandartId: this.$route.params.id, MakeCopy: true, File: this.$refs.file2.files[0]}).then(res => {
          this.clonestddialog = true
          this.ask = false
          if (res.status == 200) {
            if (res.data.success) {
              this.dialogText = 'Json успешно загружен'
              this.standart(res.data.result.passportData.id)
              this.$router.push("/smp/pasport/"+res.data.result.passportData.id)
              this.error = false
            } else {
              this.dialogText = res.data.userMessage;
              this.error = true;
            }
          } else {
            this.dialogText = 'Ошибка при загрузке'
            this.error = true
          }

          let delay = this.error ? 5000 : 2000;

          setTimeout(() => {
            this.clonestddialog = false
          }, delay)
        })
      },
      addNewNote() {
        this.dialogNote = false
        this.addNote(this.queryNote).then(()=> {
          this.standart(this.$route.params.id)
          this.passportKey++
        })
      },
      openNote(data) {
        if (this.isEdit) {
          this.dialogNote = true
          this.queryNote.standartId = this.getId
          this.queryNote.name = data.note || ''
          this.queryNote.typeCode = 1
          this.queryNote.columnName = data.columnName
        } else {
          return false
        }
      },
    },
    mounted() {
      this.notes().then(() => this.load = true)
      this.documents(this.$route.params.id)
      this.standart(this.$route.params.id).then(() => this.load = true)
    }
  }
</script>

<style lang="scss" scoped>
@import "~@/scss/vars.scss";
@import "~@/scss/mixins.scss";
@import "./style.scss";

</style>
