<template>
  <div class="page">
    <h2 class="main-title">{{disabled ? title : `№ ${getTitle}`}}</h2>
    <SubMenu v-if="!disabled"/>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <div class="request">
        <v-row justify="end">
          <v-col cols="4" sm="12" md="4">
            <label>Главному внештатному специалисту</label>
            <v-autocomplete
              item-value="id"
              item-text="name"
              :items="getProfiles"
              :rules="rules"
              v-model="query.recipient.profileId"
              @change="getListUsers(query.recipient.profileId)"
              class="input"
              required
              color="black"
              :readonly="!disabled"
            />

            <label>Минздрава России</label>
            <v-autocomplete
                item-value="id"
                item-text="fullName"
                return-object
                :readonly="!query.recipient.profileId || !disabled"
                :items="listUsers"
                placeholder="ФИО"
                :rules="rules"
                v-model="query.recipient.chiefSpecialist.fullName"
                required
                class="custom-placeholer-color"
            />
            <!-- <v-text-field
              placeholder="ФИО"
              :rules="rules"
              v-model="query.recipient.chiefSpecialist.fullName"
              class="input"
              required
            ></v-text-field> -->

            <v-text-field
              placeholder="Адрес"
              :rules="rules"
              v-model="query.recipient.address"
              required
              color="black"
              :readonly="!disabled"
              class="custom-placeholer-color"
            ></v-text-field>
          </v-col>
        </v-row>
        <h4 class="text-center">Уважаемый(ая), {{ query.recipient.chiefSpecialist.fullName ? query.recipient.chiefSpecialist.fullName.fullName : '' }}</h4>
        <p>Департамент (наименование Департамента) в соответствии с порядком разработки стандартов медицинской помощи, утвержденным приказ Минздрава России от 22.02.2022 № 103н, и положением о главном внештатном специалисте Министерства здравоохранения Российской Федерации, утвержденным приказом Минздрава России от 25.10.2012 № 444, просит совместно с ФГБУ «ЦЭККМП» Минздрава России обеспечить в срок не позднее
        <span class="date">
          <v-menu
            ref="menu"
            transition="scale-transition"
            offset-y
            min-width="auto"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="query.latestDevelopmentDate"
                placeholder="Дата"
                prepend-icon="mdi-calendar"
                class="input"
                :readonly="!disabled"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              :first-day-of-week="1"
              :readonly="!disabled"
            ></v-date-picker>
          </v-menu>
        </span>
        подготовку предложений
        в проекты стандартов медицинской помощи на основе клинических рекомендаций, прошедших процедуру одобрения научно-практическим советом, утвержденных медицинскими профессиональными некоммерческими организациями
        и размещенных в Рубрикаторе клинических рекомендаций Минздрава России, согласно приложению, а также обеспечить их размещение в информационной системе ведения и разработки стандартов медицинской помощи.
        </p>
        <p><span class="text-decoration-underline">Приложение:</span> на 1 л. в 1 экз.</p>
        <v-row>
          <v-col cols="6" sm="12" md="6">
            <v-autocomplete
              item-value="id"
              item-text="name"
              :rules="rules"
              class="input"
              :items="getDepartmentsl"
              placeholder="Департамент Санитарно-Курортного дела"
              v-model="query.departmentId"
              required
              :readonly="!disabled"
              @change="changeDeportament()"
            />
            <p>Директор департамента</p>
            <p><b>{{getDirectorFullName}}</b></p>
          </v-col>
        </v-row>

        <p class="text-end">Приложение к документу</p>
        <p>Перечень клинических рекомендаций, размещенных в Рубрикаторе клинических рекомендаций Минздрава России, для подготовки предложений в проекты стандартов медицинской помощи.</p>
        <v-row>
            <v-col cols="6" md="8" sm="12">
                <v-row
                    align="center"
                    v-for="(clinicalRecommendation, index) in newClinicalRecommendations"
                    :key="index"
                >
                    <v-col cols="1" sm="1">{{index + 1}}.</v-col>

                    <v-col cols="5" sm="5">
                        <v-autocomplete
                            item-value="id"
                            item-text="name"
                            class="input"
                            :items="clinicalRecommendation.listAgeGroups"
                            v-model="clinicalRecommendation.ageGroups[0]"
                            placeholder="Возрастная категория"
                            @change="moreClinicalRecommendation(clinicalRecommendation, clinicalRecommendation.ageGroups[0])"
                            :readonly="!disabled"
                        />
                    </v-col>
                    <v-col cols="5" sm="5">
                        <v-autocomplete
                            item-value="Id"
                            item-text="name"
                            :items="clinicalRecommendation.listClinrec"
                            placeholder="Клиническая рекомендация"
                            return-object
                            class="input"
                            v-model="clinicalRecommendation.clinRec"
                            :readonly="!disabled"
                        >
                      <template v-slot:selection="{ item }">
                       <span class="span-selection">
                        {{ item.name }}
                       </span>
                      </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="1" sm="1" v-if="buttons">
                        <v-btn class="mx-2" color="success" v-if="newClinicalRecommendations.length == index + 1" @click="addClinicalRecommendation()">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>

                        <v-btn class="mx-2" color="error" v-if="newClinicalRecommendations.length > 1" @click="removeClinicalRecommendation(index)">
                            <v-icon dark>mdi-minus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <div class="button-box"
          v-if="$store.state.login.canModifySmp &&
          (!(($store.state.login.user.id == $store.state.smp.user || $store.state.login.mainDepartment || $store.state.login.cekkmp)
          && ($store.state.smp.statusTwo || $store.state.smp.statusOne)) || !$store.state.login.guest)">
          <v-btn
            color="red"
            :disabled="!valid"
            class="ma-2 white--text"
            v-if="
            ($store.state.login.employee || $store.state.login.administrator) && !this.$route.params.id"
            @click="openPopup()"
          >
          Сформировать запрос
          </v-btn>
          <v-btn
            color="red"
            :disabled="!valid"
            class="ma-2 white--text"
            v-if="
            ($store.state.login.gbs || $store.state.login.administrator) && $route.params.id && $store.state.smp.statusOne"
            @click="openPopup2()"
          >
            Принятие запроса
          </v-btn>

          <v-tooltip
            top
            v-if="$store.state.login.employee && $route.params.id && $store.state.smp.statusZero"
            :disabled="getDocuments.some((f) => f.fileTypeId.toUpperCase() === '716FDA02-FA86-4970-B073-E7F9A429C1E1')">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" class="d-inline-block">
                <v-btn
                  v-bind="attrs"
                  color="red"
                  class="ma-2 white--text"
                  :disabled="getDocuments.every((f) => f.fileTypeId.toUpperCase() !== '716FDA02-FA86-4970-B073-E7F9A429C1E1')"
                  @click="openPopup2()"
                >
                  Отправить
                </v-btn>
              </div>
            </template>
            <span>
              Для отправки СМП загрузите документ "Запрос на разработку СМП" в одноименную вкладку
            </span>
          </v-tooltip>

          <v-btn
            color="white"
            v-if="$store.state.login.employee || $store.state.login.administrator || $store.state.smp.statusZero"
            class="ma-2 red--text"
            :disabled="disabled"
            @click="createDocument({id: getRequestId.createdId || $route.params.id, name: `№${getTitle}`})"
          >
          <v-icon
            left
            dark
          >
            mdi-cloud-download-outline
          </v-icon>
            Скачать
          </v-btn>
        </div>
      </div>
    </v-form>

      <v-dialog
        v-model="dialog"
        width="500"
      >
      <v-card>
        <v-card-title class="text-h5 text-center green--text lighten-2">
          {{ask ? 'Вы уверены, что хотите сформировать запрос ?' : 'Запрос на создание стандарта медицинской помощи сформирован'}}
        </v-card-title>
        <v-card-actions v-if="ask">
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            :disabled="loadingCreateSMP"
            text
            @click="dialog = false"
          >
            Отменить
          </v-btn>

          <v-btn
            color="green darken-1"
            :loading="loadingCreateSMP"
            text
            @click="createSMP()"
          >
            Сформировать запрос
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog2"
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5 text-center green--text lighten-2">
          {{ask?'Вы уверены, что хотите отправить запрос на формирование проекта СМП?':dialogText}}
        </v-card-title>
        <v-card-actions v-if="ask">
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="dialog2 = false"
          >
            Отменить
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="accept()"
          >
            Отправить запрос
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import SubMenu from '@/components/SubMenu/SubMenu.vue'
import {mapGetters, mapActions, mapMutations} from 'vuex'

export default {
    name: 'Request',
    components: {
        SubMenu
    },
    data () {
      return {
        loadingCreateSMP: false,
        listUsers: [],
        load: false,
        buttons: true,
        title: 'Создание запроса',
        valid: true,
        disabled: true,
        ask: false,
        error: {
          deportament: false,
          profile: false,
        },
        filterKr: {
          adults: false,
          children: false,
          name: "",
          status: 0
        },
        dialog: false,
        dialog2: false,
        dialogText:'',
        rules: [
          value => !!value || 'Это обязательное поле'
        ],
        query: {
          recipient: {
            profileId: null,
            chiefSpecialist: { fullName: null },
            chiefSpecialistAppeal: '',
            address: ''
          },
          departmentId: null,
          latestDevelopmentDate: this.formatDate(new Date().toISOString().substring(0, 10)),
          clinicalRecommendations: []
        },
        date: new Date().toISOString().substring(0, 10),
        newClinicalRecommendations: []
      }
    },
    computed : {
      ...mapGetters({
        getProfiles: 'lookups/getProfiles',
        getDepartmentsl: 'lookups/getDepartmentsl',
        getAgeGroups: 'lookups/getAgeGroups',
        getDirectorFullName: 'lookups/getDirectorFullName',
        getUser: 'login/getUser',
        getRequestId: 'request/getRequestId',
        getDocument: 'request/getDocument',
        getDocuments: 'documents/getDocuments',
        getTitle: 'smp/getTitle',
        getPasport: 'smp/getPasport'
      }),
      computedDateFormatted () {
        return this.formatDate(this.date)
      }
    },
    methods: {
        ...mapActions({
            lookups: 'lookups/lookups',
            createRequest: 'request/createRequest',
            createDocument: 'request/createDocument',
            getKR: 'request/getKR',
            standart: 'smp/standart',
            standartUpdate: 'smp/standartUpdate',
            getUsers: 'smp/getUsers',
            documents: 'documents/documents'
        }),
        ...mapMutations({
            mutationDirectorFullName: 'lookups/mutationDirectorFullName',
        }),
        addClinicalRecommendation() {
          this.newClinicalRecommendations.push({
            сlinRec: null,
            ageGroups: [],
            listClinrec: [],
            listAgeGroups: this.getAgeGroups
          })
        },
        openPopup() {
          if (this.$refs.form.validate()) {
            this.dialog = true
            this.ask = true
          }
        },
        openPopup2() {
          if (this.$refs.form.validate()) {
            this.dialog2 = true
            this.ask = true
          }
        },
        accept() {
          this.standartUpdate({
            standartId: this.$route.params.id,
            statusId: this.$store.state.login.gbs ? 'CB2D66F2-8F38-4047-ADA0-9F8CDE03391E' : '50C53811-33AF-4894-A0ED-EE412BE9E166',
            stageId: '0DD880C6-ACAA-4F97-9AA2-1E0AD441A5C9'
          }).then(res => {
            this.ask = false

            if (res.status == 200) {
                this.dialogText = 'Успешно'
                this.standart(this.$route.params.id)
                this.error = false
            } else {
                this.dialogText = `Ошибка ${res.status}`
                this.error = true
            }

            setTimeout(() => {
                this.dialog2 = false
            }, 3000 )
          }).catch(err => {
            console.log(err)
          })
        },
        removeClinicalRecommendation(index) {
            this.newClinicalRecommendations.splice(index, 1);
        },
        changeDeportament() {
            this.mutationDirectorFullName(this.query.departmentId)
            this.query.recipient.chiefSpecialistAppeal = this.getDirectorFullName
        },
        createSMP() {
            this.query.latestDevelopmentDate = this.formatDate(this.query.latestDevelopmentDate);

            this.createRequest({
                recipient: {
                    profileId: this.query.recipient.profileId,
                    chiefSpecialistId: this.query.recipient.chiefSpecialist.fullName.id,
                    chiefSpecialistAppeal: this.query.recipient.chiefSpecialistAppeal,
                    address: this.query.recipient.address
                },
                departmentId: this.query.departmentId,
                latestDevelopmentDate: this.query.latestDevelopmentDate,
                clinicalRecommendations: this.query.clinicalRecommendations.map((m) => ({ clinRecId: `${m.clinRec.Id}`, name: m.clinRec.name, ageGroups: m.ageGroups }))
            })
            .then((res) => {
                this.disabled = false;
                this.ask = false;

                setTimeout(() => {
                    this.dialog = false
                    this.standart(res.data.id)
                    this.$router.push({ name: 'request', params: { id: String(res.data.id) } })
                }, 5000 )
            })
            .catch((err) => {
                if(err.response.status === 400) {
                    this.dialog = false;
                    alert('Ошибка отправки запроса, обратитесь к Администратору!')
                }
            })
        },
        moreClinicalRecommendation(row, id) {
            this.query.clinicalRecommendations = this.newClinicalRecommendations;

            if (id) {
                this.getKR({
                    ...this.filterKr,
                    adults: id === '82ede3fb-5653-4189-98bf-7be3d809b41b' ? false : true,
                    children: id === 'ff4e5efe-138c-4b91-b45d-9013644a8965' ?  false : true
                })
                .then((res) => {
                    row.listClinrec = res.data;
                    console.log(row.listClinrec);

                })
            }
        },
        formatDate (date) {
            if (!date) return null
            let [day, month, year] = date.split('-')
            return `${year}-${month}-${day}`
        },
        getListUsers(idGVS) {
            // listUsers
            this.getUsers(idGVS)
                .then((res) => {
                    this.listUsers = res.data.data;
                })
        }
    },

    watch: {
    date (val) {
        this.query.latestDevelopmentDate = this.formatDate(val)
    }
    },
    mounted() {
        this.buttons = true;

        if (this.$route.params.id) {
            this.disabled = false;

            if (this.getDocuments.length === 0) {
              this.documents(this.$route.params.id);
            }

            this.getKR({...this.filterKr,
              status: null}
            )
                .then((crlist) => {

                    this.standart(this.$route.params.id)
                        .then((res) => {

                            this.getUsers(res.data.recipient[0].profileId)
                                .then((userList) => {
                                    this.lookups()
                                        .then((lookupsRes) => {
                                            this.listUsers = userList.data.data;

                                            this.buttons = false
                                            this.load = true
                                            this.query = {
                                                recipient: {
                                                    profileId: res.data.recipient[0].profileId,
                                                    chiefSpecialist: {
                                                        fullName: res.data.recipient[0].chiefSpecialist
                                                    },
                                                    chiefSpecialistAppeal: res.data.recipient[0].chiefSpecialistAppeal,
                                                    address: res.data.recipient[0].address
                                                },
                                                departmentId: res.data.passportData.departmentId,
                                                latestDevelopmentDate: moment(res.data.passportData.latestDevelopmentDate).format('DD-MM-YYYY'),
                                                clinicalRecommendations: res.data.clinicalRecommendations.map((item) => {
                                                    let ageGroup = "ff4e5efe-138c-4b91-b45d-9013644a8965"

                                                    if (item.ageGroups[0] === 'Любой') {
                                                        ageGroup = '413664c0-8378-43ae-8d2d-488b9a884787'
                                                    } else if (item.ageGroups[0] === 'Дети') {
                                                        ageGroup = '82ede3fb-5653-4189-98bf-7be3d809b41b'
                                                    } else {
                                                        ageGroup = "ff4e5efe-138c-4b91-b45d-9013644a8965"
                                                    }
                                                    //console.log(item);

                                                    // for (let i = 0; i < crlist.data.length; i++) {

                                                    //     if(item.clinRecId === crlist.data[i].Id) {
                                                    //         clinRecObject = crlist.data[i];
                                                    //         break;
                                                    //     }
                                                    // }


                                                    return {
                                                        clinRec: {Id:item.clinRecId, name: item.clinicalRecommendationName },
                                                        name: item.clinicalRecommendationName,
                                                        ageGroups: [ageGroup],
                                                        listClinrec: crlist.data,
                                                        listAgeGroups: lookupsRes.data.ageGroups
                                                    }
                                                })
                                            }


                                            this.mutationDirectorFullName(this.query.departmentId)
                                            this.newClinicalRecommendations = this.query.clinicalRecommendations
                                            //console.log(this.newClinicalRecommendations[0].listClinrec);


                                        })
                                })
                        })
                })

                return;
        }

        this.lookups()
            .then((res) => {
                if(this.newClinicalRecommendations.length === 0) {
                    this.newClinicalRecommendations.push({
                        сlinRec: null,
                        ageGroups: [],
                        listClinrec: [],
                        listAgeGroups: res.data.ageGroups
                    })
                }
            })
    }
}
</script>

<style lang="scss" scoped>
  @import "~@/scss/vars.scss";
  @import "~@/scss/mixins.scss";
  @import "./style.scss";
  .span-selection{
    width: 65%;
  }

</style>
