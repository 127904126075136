import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Axios from "axios";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import vuetify from "./plugins/vuetify";

// name is optional
Vue.use(VueLodash, { name: "custom", lodash: lodash });
Vue.prototype.$http = Axios;

const token = localStorage.getItem("token");

if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = token;
}

Vue.use(require("vue-moment"));

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
