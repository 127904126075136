<template>
    <span>
        <v-icon size="16px" class="ma-0" :class="getIconClass()" @click.prevent="dialogMessage = true">
            mdi-certificate
        </v-icon>

        <v-dialog v-model="dialogMessage" width="600">
            <v-card>
                <v-card-title class="text-h5">Информация о подписи</v-card-title>
                <v-card-text>
                    <p v-if="message !== null">{{ message }}</p>
                    <template v-else>
                        Подписан:
                        <ul>
                            <li v-for="(item, index) in signers" v-bind:key="index">
                                {{ item.fio }}; {{ item.company }}; {{ item.datetime | moment('DD.MM.YYYY HH:mm') }}
                            </li>
                        </ul>
                    </template>
                    <div @click="downloadDocument()">
                        <v-icon color="blue" size="16px" class="download-icon">mdi-file-download-outline</v-icon>
                        <span class="link">Скачать файл с информацией о цифровой подписи</span>
                    </div>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="white"
                    class="ma-2 red--text"
                    @click="dialogMessage = false"
                >
                    Закрыть
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import * as certificateJS from './certificate.js';
import { mapActions } from 'vuex';

export default {
    name: 'Certificate',
    props: {
        file: Object
    },
    data: () => ({
        certStatus: 0,
        dialogMessage: false,
        signers: [],
        message: 'Проверка файла...',
	}),
    methods: {
        ...mapActions({
            getDocument: 'documents/getDocument'
        }),
        downloadDocument() {
            this.getDocument({ id: this.file.id, signed: true })
                .then(response => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([response.data]));

                    let fileName = decodeURI(response.headers['content-disposition'].split('filename*=')[1].split("''")[1]);
                    link.setAttribute('download', fileName);

                    document.body.appendChild(link);
                    link.click();
                })
                .catch(console.error)
        },
        verifyDocument() {
            this.getDocument({ id: this.file.id })
                .then(response => {
                    this.$root.$refs.A.verify_file_async(this.file.signatures, response.data)
                        .then(infos => {
                            this.certStatus = 1;
                            this.signers = [];

                            for (let i = 0; i < infos.length; i++) {
                                let info = infos[i];

                                let signer = {
                                    fio: certificateJS.parseFio(info.signer),
                                    company: certificateJS.parseCompany(info.signer),
                                    datetime: info.time
                                };

                                this.signers.push(signer);
                            }
                            
                            this.message = null;
                        }, error => {
                            console.debug(error);
                            this.certStatus = -1;
                            this.message = 'Файл был изменен!';
                        })
                })
                .catch(console.error);
        },
        getIconClass() {
            if (this.certStatus > 0) {
                return 'v-icon-certificate-valid';
            }

            if (this.certStatus < 0) {
                return 'v-icon-certificate-invalid';
            }

            return 'v-icon-certificate-unknown';
		}
    },
    mounted() {
        this.verifyDocument();
    }
}
</script>

<style lang="scss" scoped>
@import "~@/scss/vars.scss";
@import "~@/scss/mixins.scss";

ul {
    margin-bottom: 16px;
}

.v-icon.v-icon-certificate-valid {
  color: #4caf4f !important;
}

.v-icon.v-icon-certificate-invalid {
  color: #f44336 !important;
}

.v-icon.v-icon-certificate-unknown {
  color: #d1a209 !important;
}

.download-icon {
    margin: 0 4px 4px 0;
    cursor: pointer;
}

.link {
    @include font(#4C4F51, 16px, 16px, 500);
    border-bottom: 2px dotted #4C4F51;
    text-decoration: none;
    margin-right: 40px;
    cursor: pointer;
  
    &:hover, &.active {
      color: $base-color;
      border-bottom-color: #fff;
    }
}
</style>
