<template>
  <div class="users">
    <div class="header">
      <h3 class="sub-title">Список пользователей</h3>
      <div class="button-box">
        <v-btn
          color="red"
          class="white--text"
          @click="createNewUser"
        >
        <v-icon
          left
          dark
        >
          mdi-plus
        </v-icon>
          Добавить
        </v-btn>
      </div>
    </div>
    <div class="responsive">
      <v-skeleton-loader
       v-if="!load"
        class="mx-auto"
        type="table-tbody"
      ></v-skeleton-loader>
      <table class="table table-search" :key="tableKey" v-else>
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              class="text-center">
              {{header.text}}
              <div class="search" v-if="header.search">
                <input type="text" v-model="header.input" @input="setSearch({column:header.value, string:header.input})">
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in getUsers"
            :key="index"
          >
            <td class="text-center">
              <div class="fix" v-if="!user.update">
                {{user.fullName}}
              </div>
              <div class="update" v-else>
                <v-text-field
                  v-model="user.fullName"
                ></v-text-field>
              </div>
            </td>
            <td class="text-center">
              <div class="fix" v-if="!user.update">
                {{user.roleGroup.name}}
              </div>
              <div class="update" v-else>
                <v-select
                  :items="getRolesGroupName"
                  v-model="user.roleGroup"
                  @input="() => {
                      user.roles = []
                      setRolesCurent(user.roleGroup.id)
                    }"
                ></v-select>
              </div>
            </td>
            <td class="text-center">
              <div class="fix" v-if="!user.update">
                {{user.roles.map(item => {
                  return item.name
                }).join(' ')}}
              </div>
              <div class="update" v-else>
                <v-select
                  multiple
                  :items="getRolesName"
                  v-model="user.roles"
                  attach
                  chips
                ></v-select>
              </div>
            </td>
            <td class="text-center">
              <div class="fix" v-if="!user.update">
                {{user.email}}
              </div>
              <div class="update" v-else>
                <v-text-field
                  v-model="user.email"
                ></v-text-field>
              </div>
            </td>
            <td class="text-center">
              <div class="fix" v-if="!user.update">
                {{user.login}}
              </div>
              <div class="update" v-else>
                <v-text-field
                  v-model="user.login"
                ></v-text-field>
              </div>
            </td>
            <td class="text-center">
              <div class="fix" v-if="!user.update">
                <v-icon :color="user.isBlocked ? 'red':''" @click="blockedUser({id:user.id, block: !user.isBlocked}).then(() => user.isBlocked = !user.isBlocked)">
                  {{user.isBlocked ? 'mdi-lock' : 'mdi-lock-open'}}
                </v-icon>
                <v-icon @click="() => {
                  startUpdateUser(index)
                  setRolesCurent(user.roleGroup.id)
                  }
                ">
                  mdi-pencil-outline
                </v-icon>
              </div>
              <div class="update" v-else>
                <v-icon color="green" @click="userUpdateButton(user, index)">
                  mdi-content-save-edit
                </v-icon>
                <v-icon color="red" v-if="user.newUser" @click="removeNewUser()">
                  mdi-delete
                </v-icon>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <TimeoutSnackbar ref="snackbar" />
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import TimeoutSnackbar from '@/components/TimeoutSnackbar/TimeoutSnackbar.vue';

export default {
  name: 'Users',
  components: {
    TimeoutSnackbar
  },
  data () {
    return {
      links: [
        {name: 'Все', link: '#'},
        {name: 'Запрос', link: {name: 'request'}},
        {name: 'Проект', link: '#'},
        {name: 'Стандарт медицинской помощи', link: '/'}
      ],
      load: false,
      headers: [
        {text: 'ФИО', search: true, input: '', value: 'fullName'},
        { text: 'Уровень доступа', search: true, input: '', value: 'roleGroup'},
        { text: 'Роль', search: true, input: '', value: 'roles'},
        { text: 'E-mail', search: true, input: '', value: 'email'},
        { text: 'Логин', search: true, input: '', value: 'login'},
        { text: 'Действия', search: false, input: '', value: ''},
      ],
      tableKey: 0,
      listUsers: [],
    }
  },
  computed : {
    ...mapGetters({
        getUsers: 'users/getUsers',
        getRolesName: 'lookups/getRolesName',
        getRolesGroupName: 'lookups/getRolesGroupName',
        getCurentGroup:'lookups/getCurentGroup'
    }),
  },
  methods: {
    ...mapMutations({
      setRolesCurent: 'lookups/setRolesCurent',
      setSearch: 'users/setSearch',
      startUpdateUser: 'users/startUpdateUser',
      addUsers: 'users/addUsers',
    }),
    ...mapActions({
      allUsers: 'users/allUsers',
      updateUser: 'users/updateUser',
      blockedUser: 'users/blockedUser',
      createdUser: 'users/createdUser',
      lookups: 'lookups/lookups',
    }),
    createNewUser() {
        this.addUsers();
    },
    userUpdateButton(user, index) {
        let object = null;

        if(user.newUser) {
            object = {
                email: user.email,
                login: user.login,
                isBlocked: false,
                fullName: user.fullName,
                roleGroupId: user.roleGroup.id,
                roles: user.roles.map((item) => { return item.id })
            }
        } else {
            object = {
                id: user.id,
                user: {
                    email: user.email,
                    login: user.login,
                    fullName: user.fullName,
                    roleGroupId: user.roleGroup.id,
                    roles: user.roles.map((item) => { return item.id })
                }
            }
        }

        this[user.newUser ? 'createdUser' : 'updateUser'](object)
            .then(response => {
              if (response.data.success) {
                this.startUpdateUser(index);
              } else {
                this.$refs.snackbar.show('error', response.data.userMessage);
              }
            })
            .catch(() => {
              this.$refs.snackbar.show('error', 'Неизвестная ошибка, обратитесь к администратору');
            });
    },
    removeNewUser() {
      this.listUsers.shift()
    }
  },
  mounted() {
    this.allUsers().then(() => this.load = true)
    this.lookups()
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/scss/vars.scss";
  @import "~@/scss/mixins.scss";
  @import "./style.scss";
</style>
