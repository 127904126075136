<template>
  <footer class="footer">
    <p>(с) {{ new Date().getFullYear() }} Все права защищены (v1.001)</p>
  </footer>
</template>

<script>
  export default {
    name: 'Footer',
  }
</script>

<style lang="scss" scoped>
@import "~@/scss/vars.scss";
@import "~@/scss/mixins.scss";
.footer {
  background: $base-color;
  text-align: center;
  padding: 24px 0;
  @include font($white, 14px, 17px, 400);
  margin-top: 40px;

  p {
    margin-bottom: 0;
  }
}
</style>
