<template>
    <div class="page">
        <h2 class="main-title">{{title}}</h2>
        <div class="button-box">
        <button
            class="add"
            @click="filterHistoryExcel(filter)"
            >

            <img src="@/assets/icon/check.svg" />
            Получить отчет
        </button>
        </div>
        <div class="responsive">
        <table class="table table-search table-link">
            <thead>
            <tr>
                <th
                v-for="header in headers"
                :key="header.text"
                class="text-center"
                style="cursor: pointer;"
                >
                <span @click="sortArchive(header.value)">
                {{header.text}}
                <span v-if="header.sort" class="sort">
                  <v-icon
                  v-if="header.revers"
                  color="blue">
                    mdi-chevron-up
                  </v-icon>
                  <v-icon
                  v-else
                  color="blue">
                    mdi-chevron-down
                  </v-icon>
                </span>
              </span>
                <div class="search">
                  <input type="text" v-model="header.input" @input="setSearch({column: header.value, string: header.input})">
                </div>
                </th>
            </tr>
            </thead>
            <tbody
            v-for="(smp, index) in getStandarts"
            :key="index">
            <tr>
                <td class="text-left">
                <div class="stage">{{new Date(smp.ModifiedOn).toLocaleDateString()}}</div>
                </td>
                <td class="text-center">{{smp.ClinicalRecommendationName}}</td>
                <td class="text-center">
                    <div class="item">{{smp.MkbCode}}</div>
                </td>
                <td class="text-center"><div class="item">{{smp.StandartName}}</div></td>
                <td class="text-center"><div class="item">{{smp.StandartCode}}</div></td>
                <td class="text-center"><a @click.prevent =" getTimeLine(smp, index)">{{smp.StatusName}}</a></td>
                <td class="text-center">{{smp.StandartNumber}}</td>
            </tr>
            <tr v-if="smp.isOpen">
                <td colspan="7">
                    <time-line
                        line-color="#3E5F71"
                        contentAttr="UserGroupRole"
                        :status="smp.StatusName"
                        timeline-padding="10px"
                        min-height="60px"
                        min-width="120px"
                        timeline-background="#fff"
                        :items="smp.timeLine"
                    >
                    </time-line>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
 </template>

 <script>
 import {mapGetters, mapActions, mapMutations} from 'vuex'
 import TimeLine from '@/components/TimeLine/index.vue'

 export default {
   name: 'SMP',
   components:{
    TimeLine
   },
   data () {
     return {
       title: 'История изменений СМП',
       filter: {
          dateFrom: null,
          dateTo: null,
          mkbIds: [],
          statusIds: [],
          standartName: "",
          clinicalRecommendationName: "",
          standartCode: "",
          orderField: "",
          asc: true
        },
        sortValue: {
        column: null,
        ask: false,
      },
       flagTimeline: false,
       headers: [
         {
           text: 'Последняя дата изменения',
           value: 'ModifiedOn',
           input: ''
         },
         { text: 'Наименование КР', value: 'ClinicalRecommendationName', input: '', },
         { text: 'Код МКБ-10', value: 'MkbCode' , input: '' },
         { text: 'Наименование СМП', value: 'StandartName' , input: ''},
         { text: 'Код СМП', value: 'StandartCode', input: '' },
         { text: 'Актуальный статус', value: 'StatusName', input: ''  },
         { text: '№ запроса', value: 'StandartNumber', input: ''  }
       ],
     }
   },
   computed : {
     ...mapGetters({
         getStandarts: 'history/getStandarts',
     }),
   },
   methods: {
     ...mapActions({
      filterStandarts: 'history/filterStandarts',
      filterStandartsId: 'history/filterStandartsId',
      filterHistoryExcel: 'history/filterHistoryExcel',
      generateHistoryFiles: 'smp/generateHistoryFiles'
     }),
     ...mapMutations({
      setSearch: 'history/setSearch',
      setSort: 'history/setSort'
     }),
     filterStatus(status) {
       if(status) this.filter = {stages: [status]}
       else this.filter = {}
       this.filterStandarts(this.filter)
     },
     sortArchive(column) {
      console.log(column);
        this.headers.map(item => {
          item.sort = item.value === column
          if (item.value === this.sort) {
            item.revers = !item.revers
          } else {
            item.revers = false
          }
        })
        this.sort = column
        this.setSort(column)
      },
     getTimeLine(smp){
      if (smp.isOpen) {
        smp.timeLine = []
        smp.isOpen = false
        return
      }
      this.filterStandartsId(smp.Id)
      .then( (res) => {
        smp.timeLine = res.data
        smp.isOpen = true
      })
     }
   },
   mounted() {
     if (this.$store.state.login.token) this.filterStandarts(this.filter)
   }
 }
 </script>

 <style lang="scss" scoped>
   @import "~@/scss/vars.scss";
   @import "~@/scss/mixins.scss";
   @import "./style.scss";

   .table tbody tr{
    cursor: auto!important;
   }
   .has-color-red {
  color: red !important;
}

.has-step-green{
  background: rgb(53, 13, 173) !important;
}
.responsive{
  width: 100%;
  overflow: visible!important;
}
 </style>
