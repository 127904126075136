import axios from "axios";

export default {
  namespaced: true,
  state: {
    documents: [],
    document: [],
    listType: [],
  },
  getters: {
    getDocuments: (state) => state.documents,
    getListType: (state) => state.listType,
  },
  mutations: {
    documentsMutation(state, data) {
      state.documents = data;
    },
    getDocumentMutation(state, data) {
      state.document = data;
    },
    getTypeList(state, data) {
      state.listType = data;
    },
  },
  actions: {
    getDocument({ rootState, commit }, data) {
      data.signed = data.signed || false;
      
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootState.api}Standarts/Files/${data.id}?signed=${data.signed}`, {
            responseType: "arraybuffer",
          })
          .then((resp) => {
            commit("getDocumentMutation", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getType({ rootState, commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootState.api}Lookups/StandardFileTypes`)
          .then((resp) => {
            commit("getTypeList", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createDocument({ rootState }, data) {
      data.Signature = data.Signature || '';
      
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("File", data.File);
        formData.append("StandartId", data.StandartId);
        formData.append("FileTypeId", data.FileTypeId);
        formData.append("Signature", data.Signature);
        axios
          .post(`${rootState.api}Standarts/Files`, formData, {
            "Content-Type": "multipart/form-data",
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addSignature({ rootState }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${rootState.api}Standarts/Files/Signature`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    documents({ rootState, commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootState.api}Standarts/Files/All/${id}`)
          .then((resp) => {
            commit("documentsMutation", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    removeDocuments({ rootState }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${rootState.api}Standarts/Files?id=${id}`)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
