import axios from "axios";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    users: [],
    usersFilter: [],
    filterUsers: [],
    sortColumn: "",
    filterId: [],
  },
  getters: {
    getUsers: (state) => state.users,
    usersFilter: (state) => state.usersFilter,
    filterUsers: (state) => state.filterUsers,
  },
  mutations: {
    setUser(state, data) {
      state.filterUsers = data;
      state.users = data
        .sort((a, b) => {
          if (a.fullName < b.fullName) {
            return -1;
          }

          if (a.fullName > b.fullName) {
            return 1;
          }

          return 0;
        })
        .map((item) => {
          return {
            roles: item.roles,
            id: item.id,
            login: item.login,
            fullName: item.fullName,
            isBlocked: item.isBlocked,
            signEnabled: item.signEnabled,
            signEnabledStr: item.signEnabled ? "Да" : "Нет",
            hasSignatureFile: item.hasSignatureFile,
          };
        });
      state.usersFilter = state.users;
    },
    setSearch(state, { column, string }) {
      let str = string;
      if (string) {
        str = string[0].toUpperCase() + string.slice(1).toLowerCase();
      }
      state.users = state.usersFilter.filter((item) => {
        if (item[column] != null) {
          if (item[column].indexOf(String(str)) != -1) {
            return item;
          }
        }
      });
    },
    setSort(state, column) {
      console.log(column);
      if (state.sortColumn === column) {
        console.log(1);
        state.users = state.users.reverse();
      } else {
        console.log(2);
        state.sortColumn = column;
        state.users = _.sortBy(state.users, [
          function(o) {
            return o[column];
          },
        ]);
      }

      state.users = state.users.slice(0, state.itemPerPage);
    },
  },
  actions: {
    userFilter({ rootState, commit }, filter) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${rootState.api}SignUser/Filters`, filter)
          .then((resp) => {
            commit("setUser", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changeEnabled({ rootState }, id) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${rootState.api}SignUser/${id}/EnableSign`)
          .then((resp) => {
            console.log(resp);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
